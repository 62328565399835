import React, { useContext, useEffect, forwardRef } from 'react';
import { useCounter } from 'react-use';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { ThemeContext } from '../context/ThemeContext';
import { LangContext } from '../context/LangContext';

const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '';

const ReCaptcha = forwardRef(({ onChange, ...props }, ref) => {
  const [count, { inc }] = useCounter(1);
  const { theme } = useContext(ThemeContext);
  const { currentLanguage } = useContext(LangContext);

  useEffect(() => {
    inc();
  }, [theme, currentLanguage, inc]);

  if (!recaptchaKey) {
    return null;
  }

  return (
    <ReCAPTCHA
      ref={ref}
      style={{ display: 'inline-block' }}
      key={count}
      sitekey={recaptchaKey}
      theme={theme === 'dark' ? 'dark' : 'light'}
      hl={currentLanguage}
      onChange={onChange}
      {...props}
    />
  );
});

ReCaptcha.propTypes = {
  onChange: PropTypes.func,
};

ReCaptcha.defaultProps = {
  onChange: () => {},
};

export default ReCaptcha;
