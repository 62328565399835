import React, { useEffect, useState } from 'react';
import { Col, Row, InputGroup, Form, Button, Badge } from 'react-bootstrap';
import { useStoreStateValue } from '@scena/react-store';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import {
  $actionManager,
  $moveable,
  $selectedFlattenLayers,
  $unitType,
} from '../../stores/stores';

/* const inputMap = [
  {
    titleKey: 'X',
    rectProperty: 'left',
    moveableProperty: 'x',
    requestType: 'draggable',
  },
  {
    titleKey: 'W',
    rectProperty: 'offsetWidth',
    moveableProperty: 'offsetWidth',
    requestType: 'resizable',
  },
  {
    titleKey: 'Y',
    rectProperty: 'top',
    moveableProperty: 'y',
    requestType: 'draggable',
  },
  {
    titleKey: 'H',
    rectProperty: 'offsetHeight',
    moveableProperty: 'offsetHeight',
    requestType: 'resizable',
  },
]; */

const inputMap = [
  {
    id: 'position',
    items: [
      {
        titleKey: 'X',
        rectProperty: 'left',
        moveableProperty: 'x',
        requestType: 'draggable',
      },
      {
        titleKey: 'Y',
        rectProperty: 'top',
        moveableProperty: 'y',
        requestType: 'draggable',
      },
    ],
  },
  {
    id: 'size',
    items: [
      {
        titleKey: 'W',
        rectProperty: 'offsetWidth',
        moveableProperty: 'offsetWidth',
        requestType: 'resizable',
      },
      {
        titleKey: 'H',
        rectProperty: 'offsetHeight',
        moveableProperty: 'offsetHeight',
        requestType: 'resizable',
      },
    ],
  },
];

function TransformSection() {
  const [rect, setRect] = useState();
  const actionManager = useStoreStateValue($actionManager);
  const moveableRef = useStoreStateValue($moveable);
  const [keepRatio, setKeepRatio] = useState(true);
  const selectedLayers = useStoreStateValue($selectedFlattenLayers);
  const unitType = useStoreStateValue($unitType);

  useEffect(() => {
    const onUpdate = (e) => {
      setRect(e.rect);
    };

    actionManager.on('get.rect', onUpdate);

    return () => {
      actionManager.off('get.rect', onUpdate);
    };
  }, [rect, actionManager]);

  if (!rect) {
    return null;
  }

  if (selectedLayers.length === 0) {
    return null;
  }

  const hasLocked = selectedLayers.find((item) => item.lock);

  return (
    <Row>
      <Col xs="12">
        <Form.Label className="d-flex align-items-center">
          <FormattedMessage id="app.common.transform" />
          {rect.width && rect.height && (
            <Badge bg="soft-dark" className="ms-3 text-body">{`${Math.round(
              rect.width * rect.height
            )}${unitType}²`}</Badge>
          )}
        </Form.Label>
      </Col>
      <Row>
        {inputMap.map((section) => (
          <Col key={section.id} xs="5">
            {section.items.map(
              (
                { titleKey, rectProperty, moveableProperty, requestType },
                index
              ) => (
                <InputGroup
                  key={rectProperty}
                  size="sm"
                  className={`input-group-merge ${
                    index % 2 === 0 ? 'mb-2' : ''
                  }`}
                >
                  <InputGroup.Text className="input-group-prepend px-2">
                    {titleKey}
                  </InputGroup.Text>
                  <Form.Control
                    className="ps-5 pe-1"
                    value={rect[rectProperty] || 0}
                    // min={requestType === 'resizable' ? 2 : undefined}
                    onChange={(e) => {
                      setRect({
                        ...rect,
                        [rectProperty]: parseFloat(e.target.value),
                      });

                      debounce(() => {
                        const requestData = {
                          [moveableProperty]: parseFloat(e.target.value),
                        };
                        if (requestType === 'resizable') {
                          requestData.keepRatio =
                            selectedLayers.length === 1 ? keepRatio : true;
                        }
                        moveableRef.current.request(
                          requestType,
                          requestData,
                          true
                        );
                      }, 500)();
                    }}
                    disabled={hasLocked}
                  />
                  <InputGroup.Text className="input-group-append px-2">
                    {unitType}
                  </InputGroup.Text>
                </InputGroup>
              )
            )}
          </Col>
        ))}
        {selectedLayers.length === 1 && (
          <Col xs="2">
            <div className="position-relative d-flex h-100 align-items-center ms-n3">
              <div
                className="position-absolute translate-middle-x border border-dark border-start-0"
                style={{ width: '30%', height: '80%', top: '10%', left: '15%' }}
              />
              <Button
                size="xs"
                variant="light"
                className="btn-icon"
                onClick={() => {
                  setKeepRatio(!keepRatio);
                }}
                disabled={hasLocked}
              >
                {!keepRatio && (
                  <i
                    className="position-absolute bi-slash-lg fs-2"
                    style={{ transform: 'rotate(90deg)' }}
                  />
                )}
                <i
                  className="bi-link fs-3"
                  style={{ transform: 'rotate(90deg)' }}
                />
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </Row>
  );
}

export default TransformSection;
