import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Badge, Dropdown, Image } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import { useAxiosQuery, useConfirmModal, useFormModal } from '../../hooks';
import RequestLoading from '../RequestLoading';
import RequestResult from '../RequestResult';
import { yup } from '../../lib';
import SmartImage from '../SmartImage';
import Illustration from '../../assets/images/svg/illustrations/oc-megaphone.svg';
import IllustrationLight from '../../assets/images/svg/illustrations-light/oc-megaphone.svg';
import Constants from '../../constants';

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);

function SmartSteps({
  horizontal,
  center,
  type,
  size,
  color,
  customIcon,
  paths,
  params,
  formatter,
}) {
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { formatMessage } = useIntl();
  const [items, setItems] = useState();

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: paths.list,
    params: {
      ...params,
      ...paths.listParams,
    },
    onSuccess: (data) => {
      const formatted = data.map((item) => formatter(item));
      setItems(formatted);
    },
  });

  const onDeleteItem = async (id, title) => {
    const requestUrl = paths.delete;
    const requestParams = {
      ...params,
      ...paths.deleteParams,
      ReviewID: id,
    };

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureToDeleteTheXY"
          values={{
            x: title,
            y: lowerCase(formatMessage({ id: `app.common.note` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      setItems((prevFiles) => prevFiles.filter((item) => item.id !== id));
    }
  };

  const onCreateOrUpdateItem = async (item) => {
    const fields = [
      {
        cols: [
          {
            key: 'comment',
            apiKey: 'Comment',
            type: 'textarea',
            schema: yup.string().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'type',
            apiKey: 'Rating',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.Review.Types),
                  getOptionValue: (option) => option.apiId,
                  formatOptionLabel: typeOptionLabel,
                  isMulti: false,
                  isSearchable: false,
                  isClearable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'priority',
            apiKey: 'Priority',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.Review.Priority),
                  getOptionValue: (option) => option.apiId,
                  formatOptionLabel: priorityOptionLabel,
                  isMulti: false,
                  isSearchable: false,
                  isClearable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
        ],
      },
    ];

    const defaultValues = item
      ? {
          comment: item.Comment,
          type: Number(item.Rating),
          priority: Number(item.Priority),
        }
      : {};

    const requestParams = item
      ? {
          ReviewID: item.ReviewID,
          ...params,
          ...paths.updateParams,
        }
      : {
          ...params,
          ...paths.createParams,
        };

    const formData = await form({
      size: 'lg',
      title: item ? 'editNote' : 'newNote',
      confirmLabel: item ? 'save' : 'create',
      requestUrl: item ? paths.update : paths.create,
      requestParams,
      fields,
      fetchOnStart: false,
      defaultValues,
    });

    if (formData) {
      setItems((prevFiles) => [...prevFiles, formatter(formData.Result)]);
    }
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading &&
        !apiError &&
        items &&
        (items.length > 0 ? (
          <>
            {paths.create && (
              <div className="d-flex justify-content-end mb-5">
                <Button
                  onClick={() => {
                    onCreateOrUpdateItem();
                  }}
                >
                  <i className="bi-clipboard-plus-fill me-1" />
                  <FormattedMessage id="app.common.newNote" />
                </Button>
              </div>
            )}
            <ul
              className={`step ${horizontal ? `step-${horizontal}` : ''} ${
                center ? `step-timeline-${center}` : ''
              } ${size ? `step-icon-${size}` : ''}`}
            >
              {items.map(
                (
                  { id, title, content, color: itemColor, icon, original },
                  index
                ) => {
                  const iColor = itemColor || color;

                  return (
                    <li key={`step_${index.toString()}`} className="step-item">
                      <div className="step-content-wrapper">
                        {type === 'avatar' ? (
                          <div className="step-avatar">
                            <Image
                              src={icon}
                              className="step-avatar-img"
                              style={{
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        ) : (
                          <span
                            className={`step-icon ${
                              iColor ? `step-icon-${iColor}` : ''
                            } ${
                              !icon && !customIcon && type === 'dot'
                                ? 'step-icon-pseudo'
                                : ''
                            }`}
                          >
                            {icon ? (
                              <i className={icon} />
                            ) : (
                              <span>
                                {customIcon ? (
                                  <i className={customIcon} />
                                ) : (
                                  type === 'number' && index + 1
                                )}
                              </span>
                            )}
                          </span>
                        )}
                        <div className="step-content">
                          <div className="d-flex align-items-center mb-2">
                            <h4 className="mb-0">{title}</h4>
                            {(paths.delete || paths.update) && (
                              <Dropdown align="end" className="ms-auto">
                                <Dropdown.Toggle
                                  bsPrefix="btn"
                                  variant="ghost-secondary"
                                  size="sm"
                                  className="btn-icon rounded-circle card-dropdown-btn"
                                >
                                  <i className="bi-three-dots-vertical" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="m-0" renderOnMount>
                                  <Dropdown.Header>
                                    <FormattedMessage id="app.common.actions" />
                                  </Dropdown.Header>
                                  {paths.update && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        onCreateOrUpdateItem(original);
                                      }}
                                    >
                                      <i className="bi-pencil-square dropdown-item-icon" />
                                      <span>
                                        <FormattedMessage id="app.common.edit" />
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                  {paths.delete && (
                                    <>
                                      <Dropdown.Divider />
                                      <Dropdown.Item
                                        onClick={() => {
                                          onDeleteItem(id, title);
                                        }}
                                      >
                                        <i className="bi-trash dropdown-item-icon text-danger" />
                                        <span className="text-danger">
                                          <FormattedMessage id="app.common.delete" />
                                        </span>
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                          {typeof content === 'string' ? (
                            <p className="step-text">{content}</p>
                          ) : (
                            content
                          )}
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </>
        ) : (
          <Card className="card-dashed shadow-none card-centered">
            <Card.Body>
              <div className="mb-3">
                <SmartImage
                  className="avatar avatar-xl"
                  src={Illustration}
                  darkSrc={IllustrationLight}
                />
              </div>
              <p>
                <FormattedMessage id="app.common.noNotesFound" />
              </p>
              {paths.create && (
                <div className="d-flex flex-column align-items-center">
                  <Button
                    onClick={() => {
                      onCreateOrUpdateItem();
                    }}
                    className="mt-3"
                  >
                    <i className="bi-clipboard-plus-fill me-1" />
                    <FormattedMessage id="app.common.newNote" />
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
    </>
  );
}

const colorPropTypes = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'dark',
  'light',
  'soft-primary',
  'soft-secondary',
  'soft-success',
  'soft-danger',
  'soft-warning',
  'soft-info',
  'soft-dark',
  'soft-light',
];

SmartSteps.propTypes = {
  horizontal: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  center: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  type: PropTypes.oneOf(['dot', 'number', 'avatar']),
  color: PropTypes.oneOf(colorPropTypes),
  customIcon: PropTypes.string,
  paths: PropTypes.shape({
    list: PropTypes.string,
    listParams: PropTypes.objectOf(PropTypes.any),
    create: PropTypes.string,
    createParams: PropTypes.objectOf(PropTypes.any),
    delete: PropTypes.string,
    deleteParams: PropTypes.objectOf(PropTypes.any),
    update: PropTypes.string,
    updateParams: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  formatter: PropTypes.func,
};

SmartSteps.defaultProps = {
  horizontal: null,
  center: null,
  size: null,
  type: 'dot',
  color: 'soft-dark',
  customIcon: null,
  params: {},
  formatter: (data) => data,
};

export default SmartSteps;
