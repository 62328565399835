import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartJs } from '../../lib';
import { ThemeContext } from '../../context/ThemeContext';
import { LangContext } from '../../context/LangContext';
import Utils from '../../utils';

const pluginRefs = { datalabels: ChartDataLabels };

function LineChart({
  data,
  options,
  wrapperClassName,
  chartClassName,
  height,
  plugins,
}) {
  const { theme } = useContext(ThemeContext);
  const { currentLanguage } = useContext(LangContext);

  const getPlugins = () => {
    const plgs = [];
    if (Object.keys(plugins).length > 0) {
      Object.keys(plugins).forEach((key) => {
        if (plugins[key]) {
          plgs.push(pluginRefs[key]);
        }
      });
    }

    return plgs;
  };

  return (
    <div className={wrapperClassName} style={{ height }}>
      <ChartJs.Charts.Line
        className={chartClassName}
        data={data}
        options={Utils.Chart.getLineChartDefaultOptions(
          options,
          theme,
          plugins
        )}
        plugins={getPlugins()}
        lang={currentLanguage}
      />
    </div>
  );
}

LineChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.objectOf(PropTypes.any),
  wrapperClassName: PropTypes.string,
  chartClassName: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plugins: PropTypes.shape({ datalabels: PropTypes.bool }),
};

LineChart.defaultProps = {
  options: {},
  wrapperClassName: 'chartjs-custom w-100',
  chartClassName: 'analytics-line-large',
  height: '20rem',
  plugins: { datalabels: false },
};

export default LineChart;
