import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Gallery as GalleryComponent,
  RequestLoading,
  RequestResult,
} from '../../../components';
import { useAxiosQuery } from '../../../hooks';

const imagePath = 'https://hotel.calidigi.com/dataset/images/';

function Gallery() {
  const { id } = useParams();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/booking_hotel/select',
    params: { id },
    select: (data) =>
      data.images.map(({ src }) => ({ src: `${imagePath}${src}` })),
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <GalleryComponent sources={apiData} />
      )}
    </>
  );
}

export default Gallery;
