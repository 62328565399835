import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Routes as AppRoutes } from './routeMapping';
import Constants from '../constants';

function PrivateRouteRequireRole({ roles, children }) {
  const location = useLocation();
  const { role } = useContext(AuthContext);

  return !roles.includes(role) ? (
    <Navigate
      to={AppRoutes.public.UNAUTHORIZED.path}
      state={{ from: location }}
      replace
    />
  ) : (
    children
  );
}

PrivateRouteRequireRole.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  children: PropTypes.node.isRequired,
};

PrivateRouteRequireRole.defaultProps = {
  roles: Object.values(Constants.User.Types).map(({ id }) => id),
};

export default PrivateRouteRequireRole;
