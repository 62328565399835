import React from 'react';
import { useParams } from 'react-router-dom';
import { SmartFileManager } from '../../../components';

const testBaseUrl = 'https://zeno.calidigi.com';
const testToken =
  'b3a5bd6bfb49317b16e9410d7de4bd753b4195a3496a3575e2da9ed4bdb5516568719d1d4ae788c1616429dcbec4df524a7d0773724bfb85ba3aaa955a0aa14370f8ec81122d14dfe423547ac98e0513bdf06cb0fadd68d6eb6d8217d1f63fa104f62ae34b7cefaee8fb7413196d2025a633af13841abb33892d9df19c';

function Files() {
  const { id } = useParams();

  console.log('id', id);

  return (
    <SmartFileManager
      paths={{
        folders: {
          list: `${testBaseUrl}/directories/list`,
          create: `${testBaseUrl}/directories/insert`,
          delete: `${testBaseUrl}/directories/delete`,
        },
        files: {
          list: `${testBaseUrl}/files/list`,
          create: `${testBaseUrl}/files/insert`,
          delete: `${testBaseUrl}/files/delete`,
          preview: `${testBaseUrl}/files/42F3881F-297A-4F8F-EC11-58E257BA50FB`,
          download: `${testBaseUrl}/file/download?guid=42F3881F-297A-4F8F-EC11-58E257BA50FB&filename=`,
        },
      }}
      params={{
        token: testToken,
        // RefTable: 'detail',
        // RefID: id,
      }}
    />
  );
}

export default Files;
