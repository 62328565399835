import React, { useContext, useRef } from 'react';
import moment from 'moment';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';
import { AuthContext } from '../../../../context/AuthContext';
import Constants from '../../../../constants';

function SettingsBasicInfo() {
  const formRef = useRef();
  const { user, update } = useContext(AuthContext);

  const fields = [
    {
      cols: [
        {
          key: 'email',
          options: { controller: { props: { disabled: true } } },
        },
      ],
    },
    {
      cols: [
        {
          key: 'firstname',
          apiKey: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'lastname',
          apiKey: 'surname',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'phone',
          apiKey: 'phone',
          type: 'phone',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'dateOfBirth',
          apiKey: 'dob',
          type: 'flatpickr',
          options: {
            controller: {
              formatter: (value) =>
                moment(value).format(Constants.DateFormats.API),
            },
          },
          schema: yup.string().nullable(),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    const payload = {
      user: data.Result,
    };
    update(payload);
    const {
      name: firstname,
      surname: lastname,
      email,
      phone,
      dob: dateOfBirth,
    } = payload.user;
    formRef.current?.reset({ firstname, lastname, email, phone, dateOfBirth });
  };

  return (
    <div>
      <SmartForm
        ref={formRef}
        isHorizontal
        fields={fields}
        submitButtonText="saveChanges"
        requestUrl="/users/update"
        onRequestSuccess={onRequestSuccess}
        requestParams={{ user_type: 0, id: user.id }}
        defaultValues={
          user
            ? {
                firstname: user.name,
                lastname: user.surname,
                email: user.email,
                phone: user.phone,
                dateOfBirth: user.dob,
              }
            : {}
        }
      />
    </div>
  );
}

export default SettingsBasicInfo;
