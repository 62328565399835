import { readPSDFile } from './files/psd';

export async function readFiles(e, offsetPosition) {
  const files = e.dataTransfer?.files;

  if (!files?.length) {
    return {};
  }
  const item = files.item(0);
  const { type } = item;

  if (type === 'image/svg+xml') {
    // SVG
  } else if (type === 'image/vnd.adobe.photoshop') {
    // PSD
    return readPSDFile(item, offsetPosition);
  }

  // console.log(item);

  return {};
}
