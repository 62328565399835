export const Sites = {
  facebook: {
    id: 'facebook',
    title: 'Facebook',
    icon: 'bi-facebook',
    color: '#3b5998',
    url: 'https://www.facebook.com',
  },
  instagram: {
    id: 'instagram',
    title: 'Instagram',
    icon: 'bi-instagram',
    color: '#833AB4',
    url: 'https://www.instagram.com',
  },
  linkedin: {
    id: 'linkedin',
    title: 'LinkedIn',
    icon: 'bi-linkedin',
    color: '#0e76a8',
    url: 'https://www.linkedin.com',
  },
  twitter: {
    id: 'twitter',
    title: 'Twitter',
    icon: 'bi-twitter',
    color: '#1DA1F2',
    url: 'https://www.twitter.com',
  },
  youtube: {
    id: 'youtube',
    title: 'YouTube',
    icon: 'bi-youtube',
    color: '#FF0000',
    url: 'https://www.youtube.com',
  },
};
