import { useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

const useFormModal = ({
  title,
  message,
  cancelLabel,
  confirmLabel,
  confirmVariant,
} = {}) => {
  const context = useContext(ModalContext);

  const form = async (opts) => {
    const props = {
      type: 'form',
      title: opts?.title || title,
      message: opts?.message || message,
      cancelLabel: opts?.cancelLabel || cancelLabel,
      confirmLabel: opts?.confirmLabel || confirmLabel,
      confirmVariant: opts?.confirmVariant || confirmVariant,
      requestUrl: opts?.requestUrl,
      requestType: opts?.requestType || 'post',
      requestParams: opts?.requestParams,
      fetchOnStart: opts?.fetchOnStart || false,
      fields: opts?.fields || [],
      defaultValues: opts?.defaultValues,
      size: opts?.size,
    };

    return new Promise((resolve) => {
      context?.show({
        ...props,
        resolver: resolve,
      });
    });
  };

  return { form };
};

export default useFormModal;
