import React from 'react';
import PropTypes from 'prop-types';

function Public({ children }) {
  return (
    <main id="content" role="main" className="main">
      {children}
    </main>
  );
}

Public.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Public;
