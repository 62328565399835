import React, { useState, useContext } from 'react';
import { Card, Row, Col, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  DateRangePicker,
  RequestLoading,
  RequestResult,
} from '../../../components';
import { LineChart } from '../../../components/Charts';
import { useAxiosQuery } from '../../../hooks';
import Utils from '../../../utils';
import Constants from '../../../constants';
import { ThemeContext } from '../../../context/ThemeContext';
import ChartFilters from './partials/ChartFilters';

const dummyJSON = {
  Status: 'success',
  status: true,
  Title: 'Success',
  Message: 'op_success',
  Result: {
    labels: [
      '2021-11',
      '2022-01',
      '2022-04',
      '2022-05',
      '2022-06',
      '2022-07',
      '2022-08',
      '2022-09',
      '2022-10',
    ],
    buildingType: [
      'ResidentialIncome',
      'Condo',
      'Land',
      'Lease',
      'Single Family',
      'Apartment',
      'Office',
      'Residential Income',
      'Storefront Retail/Office',
      null,
      'Auto Repair',
      'Bank',
      'Car Wash',
      'Commercial',
      'Flex',
      'Freestanding',
      'Health Care',
      'Hospitality',
      'Loft/Creative Space',
      'Medical',
      'Movie/Radio/TV Studio',
      'Multi-Family (2-4 Unit)',
      'Multi-Family (5+ Unit)',
      'MultiFamilyHouse',
      'Office (Condo)',
      'Office Live/Work Unit',
      'Residential',
      'Restaurant',
      'Showroom',
      'Single Family Residential',
      'Storefront',
      'Telecom Hotel/Data Hosting',
      'Vacant Land',
      'Warehouse',
      'Agricultural',
      'Assisted Living',
      'Auto Dealership',
      'Bar',
      'Bed and Breakfast',
      'Drug Store',
      'Fast Food',
      'Health Club',
      'Industrial',
      'Industrial Live/Work Unit',
      'Light Manufacturing',
      'ManufacturedHome',
      'Parking',
      'Religious Facility',
      'Retail',
      'SelfStorage',
      'Service Station',
      'Specialty',
      'Trailer/Camper Park',
      'Veterinarian/Kennel',
    ],
    data: [
      {
        Total_price: '1088888',
        Count: 1,
        Period: '2021-11',
        buildingType: 'ResidentialIncome',
      },
      {
        Total_price: '800000',
        Count: 1,
        Period: '2022-01',
        buildingType: 'Condo',
      },
      {
        Total_price: '900000',
        Count: 2,
        Period: '2022-01',
        buildingType: 'Land',
      },
      {
        Total_price: '799000',
        Count: 1,
        Period: '2022-04',
        buildingType: 'Condo',
      },
      {
        Total_price: '675000',
        Count: 1,
        Period: '2022-04',
        buildingType: 'Land',
      },
      {
        Total_price: '2100',
        Count: 1,
        Period: '2022-04',
        buildingType: 'Lease',
      },
      {
        Total_price: '988000',
        Count: 1,
        Period: '2022-04',
        buildingType: 'Single Family',
      },
      {
        Total_price: '6587900',
        Count: 4,
        Period: '2022-05',
        buildingType: 'Condo',
      },
      {
        Total_price: '1299000',
        Count: 3,
        Period: '2022-05',
        buildingType: 'Land',
      },
      {
        Total_price: '9800',
        Count: 1,
        Period: '2022-05',
        buildingType: 'Lease',
      },
    ],
    count: 920,
  },
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    /* x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: Constants.DateFormats.APP.Moment.Common,
        },
      },
    }, */
    /* y: {
      min: 0,
    }, */
  },
};

const dateFrom = moment().subtract(89, 'days');
const dateTo = moment();

const initialDates = [
  dateFrom.format(Constants.DateFormats.API),
  dateTo.format(Constants.DateFormats.API),
];

const periodTypes = [
  { label: 'daily', value: 'd' },
  { label: 'monthly', value: 'm' },
  { label: 'yearly', value: 'y' },
];

function Chart() {
  const { theme } = useContext(ThemeContext);
  const { id } = useParams();

  const [date, setDate] = useState(initialDates);
  const [periodType, setPeriodType] = useState(periodTypes[0].value);
  const [filters, setFilters] = useState({});

  const [apiData, setApiData] = useState();

  const testBaseUrl = 'https://zeno.calidigi.com';
  const testToken =
    'b3a5bd6bfb49317b16e9410d7de4bd753b4195a3496a3575e2da9ed4bdb5516568719d1d4ae788c1616429dcbec4df524a7d0773724bfb85ba3aaa955a0aa14370f8ec81122d14dfe423547ac98e0513bdf06cb0fadd68d6eb6d8217d1f63fa104f62ae34b7cefaee8fb7413196d2025a633af13841abb33892d9df19c';

  const { isLaoding: apiLoading, error: apiError } = useAxiosQuery({
    url: `${testBaseUrl}/homes/dashboard`,
    // preventFetch: true,
    params: {
      token: testToken,
      id,
      date,
      listing_period: periodType,
      ...filters,
    },
    onSuccess: (data) => {
      console.log(data);
      if (data?.images) {
        console.log(data.images);
        setApiData([]);
      }
    },
  });

  const getDataset = (data) => {
    // console.log(data);

    const datasets = [];
    let index = 0;
    data.buildingType.forEach((item) => {
      const f = data.data.filter((nItem) => nItem.buildingType === item);
      if (f.length > 0) {
        const set = {
          data: [],
          label: item,
          ...Utils.Chart.getLineChartOptions(index, theme, {
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            segment: {
              borderDash: (ctx) =>
                ctx.p0.skip || ctx.p1.skip ? [4, 6] : undefined,
            },
            spanGaps: true,
          }),
        };

        data.labels.forEach((nItem) => {
          const founded = f.find((mItem) => mItem.Period === nItem);
          set.data.push(founded?.Total_price || 0);
        });

        datasets.push(set);
        index += 1;
      }
    });

    return {
      labels: data.labels,
      datasets,
    };
  };

  const handleChangeDate = (d) => {
    const nDate = [
      moment(d[0]).format(Constants.DateFormats.API),
      moment(d[1]).format(Constants.DateFormats.API),
    ];
    setDate(nDate);
    // apiFetch({ date: nDate, listing_period: periodType, ...filters });
  };

  const handleChangePeriodType = (type) => {
    setPeriodType(type);
    // apiFetch({ listing_period: type, date, ...filters });
  };

  const handleChangeChartFilters = (nFilters) => {
    setFilters(nFilters);
    // apiFetch({ ...nFilters, listing_period: periodType, date });
  };

  const checkPeriodAvailability = (type) => {
    const dayCount = Utils.Date.getDaysBetweenDates(date[0], date[1]).length;
    if (type === 'y' && dayCount <= 365) {
      return true;
    }
    if (type === 'm' && dayCount <= 30) {
      return true;
    }

    return false;
  };

  return (
    <Card>
      <Card.Header>
        <Row className="justify-content-between align-items-center flex-grow-1">
          <Col md className="mb-3">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.chart" />
            </Card.Title>
          </Col>
          <Col xs="auto">
            <Row className="align-items-sm-center">
              <Col sm="auto" className="mb-2">
                <DateRangePicker
                  type="button"
                  initialSettings={{
                    initialDates: {
                      startDate: initialDates[0],
                      endDate: initialDates[1],
                    },
                  }}
                  value={date}
                  onChange={handleChangeDate}
                  disabled={apiLoading}
                />
              </Col>
              <Col sm="auto" className="mb-2">
                <Nav
                  variant="segment"
                  activeKey={periodType}
                  onSelect={handleChangePeriodType}
                >
                  {periodTypes.map((item) => (
                    <Nav.Item key={item.value}>
                      <Nav.Link
                        eventKey={item.value}
                        disabled={
                          apiLoading || checkPeriodAvailability(item.value)
                        }
                      >
                        <FormattedMessage id={`app.common.${item.label}`} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <ChartFilters
                onChange={handleChangeChartFilters}
                disabled={apiLoading}
              />
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <LineChart data={getDataset(dummyJSON.Result)} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default Chart;
