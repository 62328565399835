export const isUndefined = (value) => value === undefined;

export const isNull = (value) => value === null;

export const isBoolean = (value) => typeof value === 'boolean';

export const isObject = (value) => value === Object(value);

export const isArray = (value) => Array.isArray(value);

export const isDate = (value) => value instanceof Date;

export const isFunction = (arg) => typeof arg === 'function';

export const isString = (value) => typeof value === 'string';

export const isNumber = (value) => typeof value === 'number';

export const isBlob = (value) =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

export const isFile = (value) =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

export const getFlattenObject = (ob) => {
  const toReturn = {};

  Object.keys(ob).forEach((key) => {
    if (typeof ob[key] === 'object' && ob[key] !== null) {
      const flatObject = getFlattenObject(ob[key]);
      Object.keys(flatObject).forEach((nKey) => {
        toReturn[`${key}.${nKey}`] = flatObject[nKey];
      });
    } else {
      toReturn[key] = ob[key];
    }
  });

  return toReturn;
};

export const toFormdata = (obj, config, formData, pre) => {
  const cfg = config || {};

  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;

  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds)
    ? false
    : cfg.nullsAsUndefineds;

  cfg.booleansAsIntegers = isUndefined(cfg.booleansAsIntegers)
    ? false
    : cfg.booleansAsIntegers;

  cfg.allowEmptyArrays = isUndefined(cfg.allowEmptyArrays)
    ? false
    : cfg.allowEmptyArrays;

  const fd = formData || new FormData();

  if (isUndefined(obj)) {
    return fd;
  }
  if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, '');
    }
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, obj ? 1 : 0);
    } else {
      fd.append(pre, obj);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`;

        toFormdata(value, cfg, fd, key);
      });
    } else if (cfg.allowEmptyArrays) {
      fd.append(`${pre}[]`, '');
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      let nProp = prop;
      const value = obj[nProp];

      if (isArray(value)) {
        while (
          nProp.length > 2 &&
          nProp.lastIndexOf('[]') === nProp.length - 2
        ) {
          nProp = nProp.substring(0, nProp.length - 2);
        }
      }

      const key = pre ? `${pre}[${nProp}]` : nProp;

      toFormdata(value, cfg, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};
