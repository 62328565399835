import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Editor from '../dashboard/FloorPlanEditor/Editor';
import { useAxiosQuery } from '../../hooks';
import { RequestLoading, RequestResult } from '../../components';

function FloorPlan() {
  const { guid } = useParams();
  const [apiData, setApiData] = useState();
  const [jsonParseError, setJsonParseError] = useState();

  const { isLaoding: apiLoading, error: apiError } = useAxiosQuery({
    url: `/map/get`,
    preventFetch: !guid,
    // requestType: 'get',
    params: {
      guid,
    },
    onSuccess: async (data) => {
      const { output, ...rest } = data;

      let outputObject;
      if (output) {
        try {
          outputObject = JSON.parse(output);
        } catch (e) {
          setJsonParseError(e);
        }
      }
      setApiData({
        output: outputObject,
        ...rest,
      });
    },
  });

  return (
    <>
      {(apiLoading || apiError || jsonParseError) && (
        <div className="content container">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError || jsonParseError} />
        </div>
      )}
      {!apiLoading && !apiError && !jsonParseError && apiData && (
        <div className="position-relative">
          <Editor
            width={Number(apiData.width)}
            height={Number(apiData.height)}
            background={apiData.image}
            data={apiData}
            isPreview
          />
        </div>
      )}
    </>
  );
}

export default FloorPlan;
