import useBlockerPrompt from './useBlockerPrompt';

const useBlockerConfirm = (when) => {
  const blocker = useBlockerPrompt(when);

  const resetConfirmation = () => {
    if (blocker.state === 'blocked') blocker.reset();
  };

  const onConfirm = () => {
    if (blocker.state === 'blocked') setTimeout(blocker.proceed, 0);
  };

  return {
    isActive: blocker.state === 'blocked',
    onConfirm,
    resetConfirmation,
  };
};

export default useBlockerConfirm;
