import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

function FormPasswordInput({
  disabled,
  placeholder,
  isInvalid,
  isValid,
  size,
  id,
  register,
}) {
  const [visibility, setVisibility] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <InputGroup
      className={`input-group-merge ${isInvalid ? 'is-invalid' : ''} ${
        isValid ? 'is-valid' : ''
      } ${focus ? 'focus' : ''}`}
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
    >
      <Form.Control
        disabled={disabled}
        isInvalid={isInvalid}
        isValid={isValid}
        type={visibility ? 'text' : 'password'}
        size={size}
        id={id}
        placeholder={placeholder}
        {...register(id)}
      />
      <InputGroup.Text
        as="a"
        role="button"
        className="input-group-append"
        onClick={() => {
          setVisibility(!visibility);
        }}
      >
        <i className={visibility ? 'bi bi-eye-slash' : 'bi bi-eye'} />
      </InputGroup.Text>
    </InputGroup>
  );
}

FormPasswordInput.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  id: PropTypes.string.isRequired,
  register: PropTypes.any.isRequired,
};

FormPasswordInput.defaultProps = {
  disabled: false,
  placeholder: '',
  isInvalid: null,
  isValid: null,
  size: 'lg',
};

export default FormPasswordInput;
