import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_ICON_STYLE, LAYER_TYPES } from '../consts';
import layerIcons from '../layerIcons';

const Icon = forwardRef(({ id, style, ...props }, ref) => {
  const iconRef = useRef();

  useImperativeHandle(ref, () => iconRef.current, []);

  const flatten = layerIcons.reduce((prev, v) => [...prev, ...v.items], []);
  const foundedIcon = flatten.find((item) => item.id === id);

  if (!foundedIcon) {
    return null;
  }

  const IconComponent = foundedIcon.Component;

  return (
    <IconComponent
      data-type={LAYER_TYPES.icon}
      ref={iconRef}
      style={style}
      {...props}
    />
  );
});

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

Icon.defaultProps = {
  style: DEFAULT_ICON_STYLE,
};

Icon.displayName = 'Icon';

export default Icon;
