import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  RequestLoading,
  RequestResult,
  SmartImage,
} from '../../../../components';
import { useAxiosQuery } from '../../../../hooks';
import AddressImage from '../../../../assets/images/svg/illustrations/oc-address.svg';
import AddressImageLight from '../../../../assets/images/svg/illustrations-light/oc-address.svg';

function Address() {
  return (
    <Col sm="6" className="mb-5 mb-sm-7">
      asd
    </Col>
  );
}

function NewAddress({ onClick }) {
  return (
    <Col sm="6" className="mb-5 mb-sm-7">
      <Card
        onClick={onClick}
        className="card-dashed card-centered"
        style={{ cursor: 'pointer' }}
      >
        <Card.Body className="card-dashed-body py-8">
          <SmartImage
            className="avatar avatar-lg avatar-4x3 mb-2"
            src={AddressImage}
            darkSrc={AddressImageLight}
          />
          <span>
            <i className="bi-plus me-1" />
            <FormattedMessage id="app.common.addNewAddress" />
          </span>
        </Card.Body>
      </Card>
    </Col>
  );
}

NewAddress.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function BillingAddresses() {
  const {
    data: apiData,
    isLaoding: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/users/listAddress',
    // preventFetch: true,
    params: {
      type: 16,
    },
    /* onSuccess: (data) => {
      console.log(data);
      if (data?.images) {
        console.log(data.images);
        setApiData([]);
      }
    }, */
  });

  const addNewAddress = () => {
    console.log('addNewAddress');
  };

  return (
    <>
      <RequestLoading loading={apiLoading} margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData && (
        <Row>
          <Address />
          <NewAddress onClick={addNewAddress} />
        </Row>
      )}
    </>
  );
}

export default BillingAddresses;
