import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Pagination as RBPagination } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

function Pagination({
  currentPage,
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  alwaysShown,
}) {
  const pagesCount = Math.ceil(totalRows / rowsPerPage);
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (number) => {
    if (currentPage === number) return;
    pageChangeHandler(number);
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  /* const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      pageChangeHandler(pagesCount);
    }
  }; */

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <RBPagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </RBPagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <RBPagination.Ellipsis key={pageNumber} disabled />;
    }

    return null;
  });

  // useEffect(setLastPageAsCurrent, [pageChangeHandler, currentPage, pagesCount]);

  return isPaginationShown ? (
    <Nav>
      <RBPagination className="datatable-custom-pagination">
        <RBPagination.Prev
          onClick={onPreviousPageClick}
          disabled={isCurrentPageFirst}
        >
          <FormattedMessage id="app.common.prev" />
        </RBPagination.Prev>

        {pageNumbers}

        {/* pagesArr.map((num, index) => (
          <RBPagination.Item
            key={index.toString()}
            onClick={() => onPageSelect(index + 1)}
            active={index + 1 === currentPage}
          >
            {index + 1}
          </RBPagination.Item>
        )) */}

        <RBPagination.Next
          onClick={onNextPageClick}
          disabled={isCurrentPageLast}
        >
          <FormattedMessage id="app.common.next" />
        </RBPagination.Next>
      </RBPagination>
    </Nav>
  ) : null;
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageChangeHandler: PropTypes.func,
  totalRows: PropTypes.number,
  rowsPerPage: PropTypes.number,
  alwaysShown: PropTypes.bool,
};

Pagination.defaultProps = {
  currentPage: 1,
  pageChangeHandler: () => {},
  totalRows: 0,
  rowsPerPage: 10,
  alwaysShown: false,
};

export default Pagination;
