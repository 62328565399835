export const Status = {
  8: { id: 8, labelKey: 'active', color: 'success' },
  9: { id: 9, labelKey: 'inactive', color: 'danger' },
  10: { id: 10, labelKey: 'waitingConfirmation', color: 'info' },
  11: { id: 11, labelKey: 'waitingVerification', color: 'primary' },
};

export const Types = {
  15: { id: 15, labelKey: 'administrator', color: 'primary' },
  16: { id: 16, labelKey: 'user', color: 'info' },
  17: { id: 17, labelKey: 'executive', color: 'success' },
  18: { id: 18, labelKey: 'salesRepresentative', color: 'warning' },
};
