/* eslint-disable no-underscore-dangle */

import EventEmitter from '@scena/event-emitter';
import { isString } from '@daybrush/utils';
import Debugger from '../utils/Debugger';

export default class ActionManager extends EventEmitter {
  _eventMap = {};

  _registered = {};

  // on(eventName, listener);

  // on(events);

  on(eventName, listener) {
    if (isString(eventName)) {
      this._registered[eventName] = true;
    }
    return super.on(eventName, listener);
  }

  requestAct(name, param = {}) {
    const eventMap = this._eventMap;

    cancelAnimationFrame(eventMap[name] || 0);

    eventMap[name] = requestAnimationFrame(() => {
      this.act(name, param);
    });
  }

  act(actionName, param = {}) {
    let isStopLog = false;

    const result = this.emit(actionName, {
      ...param,
      stopLog() {
        isStopLog = true;
      },
    });

    if (!isStopLog && this._registered[actionName]) {
      Debugger.groupLog('action', `Act: ${actionName}`);
    }
    return result;
  }
}
