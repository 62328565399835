import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { yup } from '../../lib';
import { SmartForm } from '../../components';
import { Routes as AppRoutes } from '../../router/routeMapping';

const fields = [
  {
    cols: [
      {
        key: 'email',
        schema: yup.string().email().required(),
      },
    ],
  },
];

function ForgotPassword() {
  const { formatMessage } = useIntl();
  const formRef = useRef();

  const onRequestSuccess = () => {
    formRef.current?.setRequestSuccess(
      formatMessage({ id: 'app.auth.forgotPassword.successMessage' })
    );
  };

  return (
    <div
      className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
      style={{ maxWidth: '25rem' }}
    >
      <div className="text-center">
        <div className="mb-5">
          <h1 className="display-5">
            <FormattedMessage id="app.common.forgotPassword" />
          </h1>
          <p>
            <FormattedMessage id="app.auth.forgotPassword.info" />
          </p>
        </div>
      </div>

      <SmartForm
        ref={formRef}
        fields={fields}
        requestUrl="/user/forgot_password"
        onRequestSuccess={onRequestSuccess}
      />
      <div className="text-center mt-2">
        <Button as={Link} variant="link" to={AppRoutes.auth.LOGIN.path}>
          <i className="bi-chevron-left me-1" />
          <FormattedMessage id="app.common.backToSignIn" />
        </Button>
      </div>
    </div>
  );
}

export default ForgotPassword;
