import React from 'react';
import PropTypes from 'prop-types';

function Steps({ items, horizontal, center, type, size, color, customIcon }) {
  return (
    <ul
      className={`step ${horizontal ? `step-${horizontal}` : ''} ${
        center ? `step-timeline-${center}` : ''
      } ${size ? `step-icon-${size}` : ''}`}
    >
      {items.map(({ title, content, color: itemColor, icon }, index) => {
        const iColor = itemColor || color;

        return (
          <li key={`step_${index.toString()}`} className="step-item">
            <div className="step-content-wrapper">
              <span
                className={`step-icon ${iColor ? `step-icon-${iColor}` : ''} ${
                  !icon && !customIcon && type === 'dot'
                    ? 'step-icon-pseudo'
                    : ''
                }`}
              >
                {icon ? (
                  <i className={icon} />
                ) : (
                  <span>
                    {customIcon ? (
                      <i className={customIcon} />
                    ) : (
                      type === 'number' && index + 1
                    )}
                  </span>
                )}
              </span>
              <div className="step-content">
                <h4>{title}</h4>
                {typeof content === 'string' ? (
                  <p className="step-text">{content}</p>
                ) : (
                  content
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

const colorPropTypes = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'dark',
  'light',
  'soft-primary',
  'soft-secondary',
  'soft-success',
  'soft-danger',
  'soft-warning',
  'soft-info',
  'soft-dark',
  'soft-light',
];

Steps.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      color: PropTypes.oneOf(colorPropTypes),
      icon: PropTypes.string,
    })
  ),
  horizontal: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  center: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  type: PropTypes.oneOf(['dot', 'number', 'avatar']),
  color: PropTypes.oneOf(colorPropTypes),
  customIcon: PropTypes.string,
};

Steps.defaultProps = {
  items: [],
  horizontal: null,
  center: null,
  size: null,
  type: 'dot',
  color: 'soft-dark',
  customIcon: null,
};

export default Steps;
