import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useEffectOnce } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { yup } from '../../lib';
import { AuthContext } from '../../context/AuthContext';
import { SmartForm, SmartImage } from '../../components';
import { Routes as AppRoutes } from '../../router/routeMapping';
import Constants from '../../constants';

function SignUp() {
  const source = axios.CancelToken.source();

  const { login } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onRequestSuccess = (data) => {
    const payload = {
      user: data.Result,
      token: data.Result.token,
      role:
        Constants.User.Types[data.Result.user_type]?.id ||
        Constants.User.Types[16].id,
    };
    login(payload);
  };

  const getTermsLink = (val) => (
    <Button
      onClick={() => {
        setShowModal(true);
      }}
      variant="link"
      className="ms-1 p-0 fw-normal"
    >
      {val}
    </Button>
  );

  const renderTerms = (section, val) => {
    let Comp = section;
    let className = '';

    switch (section) {
      case 'title':
        Comp = 'h3';
        return <Comp className={className}>{val}</Comp>;

      case 'li':
        className = 'mb-2';
        return <Comp className={className}>{val}</Comp>;

      case 'a':
        return (
          <Comp className={className} href={val} target="_blank">
            {val}
          </Comp>
        );

      default:
        Comp = section;
        className = '';
        return <Comp className={className}>{val}</Comp>;
    }
  };

  const fields = [
    {
      cols: [
        {
          key: 'firstname',
          schema: yup.string().required(),
        },
        {
          key: 'lastname',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'email',
          schema: yup.string().email().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'password',
          type: 'password',
          schema: yup.string().required().min(6),
        },
      ],
    },
    {
      cols: [
        {
          key: 'passwordConfirmation',
          type: 'password',
          local: true,
          schema: yup
            .string()
            .required()
            .min(6)
            .oneOf([yup.ref('password')], {
              key: 'app.common.passwordConfirmationNotMatchError',
            }),
        },
      ],
    },
    {
      cols: [
        {
          key: 'terms',
          type: 'checkbox',
          local: true,
          label: (
            <FormattedMessage
              id="app.common.acceptTerms"
              values={{
                a: getTermsLink,
              }}
            />
          ),
          schema: yup.boolean().oneOf([true], {
            key: 'app.common.acceptTermsError',
          }),
        },
      ],
    },
  ];

  useEffectOnce(() => () => {
    source.cancel('Component got unmounted');
  });

  return (
    <>
      <div
        className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
        style={{ maxWidth: '25rem' }}
      >
        <div className="text-center">
          <div className="mb-5">
            <h1 className="display-5">
              <FormattedMessage id="app.common.createYourAccount" />
            </h1>
            <p>
              <FormattedMessage id="app.common.alreadyHaveAccount" />
              <Link to={AppRoutes.auth.LOGIN.path} className="link ms-1">
                <FormattedMessage id="app.common.signInHere" />
              </Link>
            </p>
          </div>

          {Object.keys(Constants.AuthVendors).length > 0 && (
            <>
              <div className="d-grid gap-2 mb-4">
                {Object.values(Constants.AuthVendors).map((vendor) => (
                  <Button key={vendor.id} variant="white" size="lg">
                    <span className="d-flex justify-content-center align-items-center">
                      <SmartImage
                        src={vendor.icon.default}
                        darkSrc={vendor.icon.dark || null}
                        className="avatar avatar-xss me-2"
                      />
                      <FormattedMessage
                        id={`app.common.signUpWith${capitalize(vendor.id)}`}
                      />
                    </span>
                  </Button>
                ))}
              </div>

              <span className="divider-center text-muted text-uppercase mb-4">
                <FormattedMessage id="app.common.or" />
              </span>
            </>
          )}
        </div>
        <SmartForm
          fields={fields}
          onRequestSuccess={onRequestSuccess}
          submitButtonText="createAnAccount"
          withCaptcha
        />
      </div>
      <Modal show={showModal} onHide={closeModal} size="xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="app.common.termsAndConditions" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="terms"
            values={{
              section: (msg) => renderTerms('section', msg),
              title: (msg) => renderTerms('title', msg),
              ul: (msg) => renderTerms('ul', msg),
              ol: (msg) => renderTerms('ol', msg),
              li: (msg) => renderTerms('li', msg),
              strong: (msg) => renderTerms('strong', msg),
              a: (msg) => renderTerms('a', msg),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="white">
            <FormattedMessage id="app.common.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignUp;
