import React, { useCallback } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { SmartTable } from '../../../components';
import Constants from '../../../constants';

function Table() {
  const { id } = useParams();

  const parseDate = (date) => new Date(date);

  const columns = [
    {
      Header: <FormattedMessage id="app.common.referringPage" />,
      accessor: 'ReferringPageTitle',
      wrap: true,
      minWidth: 300,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {value && <div className="h5 mb-1">{value}</div>}
            {original.ReferringPageURL && (
              <a
                href={original.ReferringPageURL}
                target="_blank"
                rel="nofollow noreferrer"
                className="link-success small mb-1 mt-1"
              >
                {original.ReferringPageURL}
              </a>
            )}
            <div>
              {original.Language && (
                <Badge bg="secondary" className="text-uppercase mt-1 me-1">
                  {original.Language}
                </Badge>
              )}
              {original.Platform && (
                <Badge bg="secondary" className="mt-1 text-uppercase">
                  {original.Platform}
                </Badge>
              )}
            </div>
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.domainRating" />,
      accessor: 'DomainRating',
    },
    {
      Header: <FormattedMessage id="app.common.domainTraffic" />,
      accessor: 'DomainTraffic',
    },
    {
      Header: <FormattedMessage id="app.common.referringDomains" />,
      accessor: 'ReferringDomains',
    },
    {
      Header: <FormattedMessage id="app.common.linkedDomains" />,
      accessor: 'LinkedDomains',
    },
    {
      Header: <FormattedMessage id="app.common.externalLinks" />,
      accessor: 'ExternalLinks',
    },
    {
      Header: <FormattedMessage id="app.common.pageTraffic" />,
      accessor: 'PageTraffic',
    },
    {
      Header: <FormattedMessage id="app.common.keywords" />,
      accessor: 'Keywords',
    },
    {
      Header: <FormattedMessage id="app.common.anchorAndTargetUrl" />,
      accessor: 'Anchor',
      wrap: true,
      minWidth: 200,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            <p className="mb-1">
              {original.LeftContext && <span>{original.LeftContext}</span>}
              {value && <span className="text-primary mx-1">{value}</span>}
              {original.RightContext && <span>{original.RightContext}</span>}
            </p>
            {original.TargetURL && (
              <a
                href={original.TargetURL}
                target="_blank"
                rel="nofollow noreferrer"
                className="link-success small mb-1 mt-1"
              >
                {original.TargetURL}
              </a>
            )}
          </>
        ),
        []
      ),
    },
    {
      Header: (
        <>
          <FormattedMessage id="app.common.firstSeen" />
          <br />
          <FormattedMessage id="app.common.lastSeen" />
        </>
      ),
      accessor: 'FirstSeen',
      wrap: true,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="text-start">
            {value && (
              <div className="text-nowrap">
                {moment(parseDate(value)).format(
                  Constants.DateFormats.APP.Moment.Common
                )}
              </div>
            )}
            {original.LastSeen && (
              <div className="text-nowrap">
                {moment(parseDate(original.LastSeen)).format(
                  Constants.DateFormats.APP.Moment.Common
                )}
              </div>
            )}
          </div>
        ),
        []
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.genericDetailSubFirst" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/backlinks/list"
              requestParams={{ PropertyID: id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Table;
