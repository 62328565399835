import React from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DashboardNavbar } from '../components/Navbars';
import { DashboardFooter } from '../components/Footers';
import { DashboardProvider } from '../context/DashboardContext';
import { useDocumentTitle } from '../hooks';
import {
  Routes as AppRoutes,
  protectedAreaBasePath,
} from '../router/routeMapping';
// import { FirebaseNotification } from '../components';

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';

const forcePath = AppRoutes.protected.ACCOUNT_SETUP.path;

function Dashboard({ children }) {
  useDocumentTitle();

  const getLink = (val) => (
    <Link to={forcePath} className="alert-link">
      {val}
    </Link>
  );

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <DashboardProvider
        requestUrl="/requirements/list"
        requiredParam="missing"
        parametersParam="params"
        // forcePath={forcePath}
        forcePathMessage={
          <FormattedMessage
            id="app.common.forcePathInfoMessage"
            values={{
              a: getLink,
            }}
          />
        }
        returnPath={protectedAreaBasePath}
        allowedPaths={[
          AppRoutes.protected.ACCOUNT.path,
          ...Object.values(AppRoutes.protected.ACCOUNT.children).map(
            ({ path }) => path
          ),
        ]}
      >
        <div className="footer-offset">
          <DashboardNavbar />
          <main id="content" role="main" className="main">
            {children}
            <DashboardFooter />
          </main>
        </div>
      </DashboardProvider>
    </GoogleOAuthProvider>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dashboard;
