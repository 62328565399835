import axios from 'axios';
import qs from 'qs';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const nConfig = config;
    nConfig.headers = {
      // Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return nConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded' */

axiosApiInstance.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.data.Status === 'error' &&
      response.data.Message === 'authentication_error'
    ) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
      window.location.href = '/';
    }
    return response;
  },
  (error) => {
    // whatever you want to do with the error
    throw error;
  }
);

export { axios, qs, axiosApiInstance };
