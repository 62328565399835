import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { Public } from '../layouts';
import { Routes as AppRoutes } from './routeMapping';

function PublicRoute() {
  const location = useLocation();

  return (
    <Public>
      {location.pathname === '/' ? (
        <Navigate to={AppRoutes.auth.LOGIN.path} replace />
      ) : (
        <Outlet />
      )}
    </Public>
  );
}

export default PublicRoute;
