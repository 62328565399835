import React from 'react';
import { Badge } from 'react-bootstrap';

export const DimensionViewable = {
  name: 'dimensionViewable',
  props: {
    dimensionViewable: Boolean,
  },
  events: {},
  render(moveable) {
    const { zoom, unitType } = moveable.props;
    const rect = moveable.getRect();

    let nZoom = zoom;
    if (nZoom > 0.2) {
      nZoom = 0.2;
    }

    return (
      <Badge
        key="dimension-viewer"
        className="position-absolute"
        style={{
          left: `${rect.width / 2}px`,
          top: `${rect.height}px`,
          willChange: 'transform',
          transformOrigin: 'center top',
          transform: `translate(-50%, ${10 * nZoom}px) scale(${nZoom})`,
          userSelect: 'none',
        }}
      >
        {`${Math.round(rect.offsetWidth)} x ${Math.round(rect.offsetHeight)} (${
          Math.round(rect.offsetWidth) * Math.round(rect.offsetHeight)
        }${unitType}²)`}
      </Badge>
    );
  },
};
