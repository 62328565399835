import React from 'react';
import { useParams } from 'react-router-dom';
import { RequestLoading, RequestResult, Steps } from '../../../components';
import { useAxiosQuery } from '../../../hooks';

function Timeline() {
  const { id } = useParams();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/booking_hotel/select',
    params: { id },
    select: (data) =>
      data.detail.map(({ title, value, mainTitle, titleParent }) => ({
        title,
        content:
          value.indexOf('miles') > -1 ? (
            <>
              <b className="text-danger">Distance:</b>
              <br />
              {value}
            </>
          ) : (
            value
          ),
        color: mainTitle ? 'primary' : null,
        icon: titleParent ? 'bi-box' : null,
      })),
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Steps items={apiData} />
      )}
    </>
  );
}

export default Timeline;
