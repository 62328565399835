import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import placeholderImg from '../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = placeholderImg;
};

function FormImageInput({
  disabled,
  onChange,
  isInvalid,
  isValid,
  size,
  variant,
  defaultValue,
  readOnly,
  className,
  children,
  objectFit,
}) {
  const inputRef = useRef();
  const [preview, setPreview] = useState(defaultValue);

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
      onChange([...e.target.files]);
    } else {
      onChange(undefined);
      setPreview(defaultValue);
    }
  };

  useEffectOnce(() => {
    if (defaultValue) {
      onChange(undefined);
    }
  });

  return (
    <div
      className={`avatar ${
        variant === 'circle' ? 'avatar-circle' : 'rounded-2'
      } avatar-${size} avatar-uploader ${isInvalid ? 'is-invalid' : ''} ${
        isValid ? 'is-valid' : ''
      } ${className || 'form-control d-block p-0 m-0 bg-transparent'}`}
    >
      <label htmlFor="uploader" className="h-100">
        <Image
          src={preview || placeholderImg}
          className="avatar-img"
          onError={onImageError}
          style={{ objectFit: preview ? objectFit : 'cover' }}
        />

        <input
          ref={inputRef}
          type="file"
          className="avatar-uploader-input"
          accept="image/jpg, image/jpeg, image/png"
          onChange={handleFileChange}
          id="uploader"
          disabled={disabled || readOnly}
          readOnly={readOnly}
        />

        {!disabled && !readOnly && (
          <span
            className={`avatar-uploader-trigger ${
              variant === 'circle' ? '' : 'pe-1 pb-1'
            }`}
          >
            <i
              className={`bi-pencil-fill avatar-uploader-icon shadow-sm ${
                variant === 'circle' ? '' : 'rounded-1'
              }`}
            />
          </span>
        )}
      </label>
      {!disabled && !readOnly && (
        <div>
          {preview && (
            <span
              className={`clear avatar-uploader-trigger top-0 bottom-100 ${
                variant === 'circle' ? '' : 'pe-1 pt-1'
              }`}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => {
                onChange(null);
                setPreview();
              }}
            >
              <i
                className={`bi-x avatar-uploader-icon shadow-sm bg-danger ${
                  variant === 'circle' ? '' : 'rounded-1'
                }`}
              />
            </span>
          )}
        </div>
      )}
      {children}
    </div>
  );
}

FormImageInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
  variant: PropTypes.oneOf(['rounded', 'circle']),
  defaultValue: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  objectFit: PropTypes.oneOf([
    'contain',
    'cover',
    'fill',
    'none',
    'scale-down',
  ]),
};

FormImageInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  isInvalid: null,
  isValid: null,
  size: 'xxl',
  variant: 'rounded',
  defaultValue: null,
  readOnly: false,
  className: '',
  children: null,
  objectFit: 'contain',
};

export default FormImageInput;
