/* eslint-disable no-underscore-dangle, consistent-return */

import KeyController from 'keycon';
import Debugger from '../utils/Debugger';
import { inputChecker } from '../utils/utils';

export default class KeyManager {
  keycon = new KeyController();

  keylist = [];

  isEnable = true;

  constructor(_root, _actionManager) {
    this._root = _root;
    this._actionManager = _actionManager;
  }

  enable() {
    this.isEnable = true;
  }

  disable() {
    this.isEnable = false;
  }

  toggleState(keys, state, callback) {
    const root = this._root;

    this.keydown(
      keys,
      (e) => {
        callback(e);
        root.set(state, true);
      },
      `key toggle down`
    );

    this.keyup(
      keys,
      (e) => {
        callback(e);
        root.set(state, false);
      },
      `key toggle up`
    );
  }

  actionDown(keys, actionName) {
    this.keycon.keydown(
      keys,
      this.addCallback(
        'keydown',
        keys,
        (e) => {
          this._actionManager.act(actionName, {
            inputEvent: e.inputEvent,
          });
        },
        `action down: ${actionName}`
      )
    );
  }

  actionUp(keys, actionName) {
    this.keycon.keyup(
      keys,
      this.addCallback(
        'keyup',
        keys,
        (e) => {
          this._actionManager.act(actionName, {
            inputEvent: e,
          });
        },
        `action up: ${actionName}`
      )
    );
  }

  keydown(keys, callback, description) {
    this.keycon.keydown(
      keys,
      this.addCallback('keydown', keys, callback, description)
    );
  }

  keyup(keys, callback, description) {
    this.keycon.keyup(
      keys,
      this.addCallback('keyup', keys, callback, description)
    );
  }

  get altKey() {
    return this.keycon.altKey;
  }

  get shiftKey() {
    return this.keycon.shiftKey;
  }

  get metaKey() {
    return this.keycon.metaKey;
  }

  get ctrlKey() {
    return this.keycon.ctrlKey;
  }

  destroy() {
    this.keycon.destroy();
  }

  addCallback(type, keys, callback, description) {
    if (description) {
      this.keylist.push([keys, description]);
    }
    return (e) => {
      if (!this.isEnable || !inputChecker(e)) {
        return false;
      }
      const result = callback(e);

      if (result !== false && description) {
        Debugger.groupLog('key', `${type}: ${keys.join(' + ')}`, description);
      }
    };
  }
}
