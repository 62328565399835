/**
 * @description Define Date Formats
 */

// based on moment
export const API = 'YYYY-MM-DD';
export const APIWithTime = 'YYYY-MM-DD HH:mm';

export const APP = {
  Moment: { Common: 'MMM D, YYYY', CommonWithTime: 'MMM D, YYYY HH:mm' },
  Flatpickr: { Common: 'M j, Y' },
};
