import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { ThemeContext } from '../../../context/ThemeContext';

const themeOptions = {
  auto: {
    id: 'auto',
    icon: 'bi-moon-stars',
  },
  default: {
    id: 'default',
    icon: 'bi-brightness-high',
  },
  dark: {
    id: 'dark',
    icon: 'bi-moon',
  },
};

function DarkmodeSwitcherPositionEnd({ dropup, variant, ...props }) {
  const { themeState, switcher } = useContext(ThemeContext);

  return (
    <Dropdown
      align="end"
      drop={dropup ? 'up' : 'down'}
      onSelect={switcher}
      {...props}
    >
      <Dropdown.Toggle
        variant={variant}
        className="rounded-circle"
        bsPrefix="btn-icon"
      >
        {themeState && <i className={themeOptions[themeState].icon} />}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as="div"
        className="navbar-dropdown-menu navbar-dropdown-menu-borderless"
        style={{
          width: '16rem',
        }}
        renderOnMount
      >
        {Object.keys(themeOptions).map((key) => (
          <Dropdown.Item
            key={themeOptions[key].id}
            eventKey={themeOptions[key].id}
            active={themeState === themeOptions[key].id}
          >
            <i className={`${themeOptions[key].icon} me-2`} />
            <span className="text-truncate">
              <FormattedMessage id={`app.theme.${themeOptions[key].id}`} />
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DarkmodeSwitcherPositionEnd.propTypes = {
  dropup: PropTypes.bool,
  variant: PropTypes.string,
};

DarkmodeSwitcherPositionEnd.defaultProps = {
  dropup: true,
  variant: 'ghost-light',
};

export default DarkmodeSwitcherPositionEnd;
