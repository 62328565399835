import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import Editor from './Editor';
import { useAxiosQuery, useAxiosMutation, useToast } from '../../../hooks';
import { RequestLoading, RequestResult } from '../../../components';
import { AuthContext } from '../../../context/AuthContext';
import Constants from '../../../constants';

function FloorPlanEditor() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [apiData, setApiData] = useState();
  const { showToast } = useToast();
  const { formatMessage } = useIntl();
  const [jsonParseError, setJsonParseError] = useState();

  const { isLaoding: apiLoading, error: apiError } = useAxiosQuery({
    url: '/maps/select',
    preventFetch: !id,
    params: {
      id,
    },
    onSuccess: async (data) => {
      const { output, ...rest } = data;

      let outputObject;
      if (output) {
        try {
          outputObject = JSON.parse(output);
        } catch (e) {
          setJsonParseError(e);
        }
      }
      setApiData({
        output: outputObject,
        ...rest,
      });
    },
  });

  const { isLoading: apiUpdateLoading, mutate: apiUpdateFetch } =
    useAxiosMutation({
      url: '/maps/update',
      params: { id },
    });

  const { isLoading: apiUnlockLoading, mutate: apiLockFetch } =
    useAxiosMutation({
      url: '/maps/unlock',
      params: { id },
    });

  const onUnlock = () => {
    apiLockFetch({
      onSuccess: () => {
        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasUpdatededSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.floorPlan' })),
              }}
            />
          ),
        });
      },
      onError: (error) => {
        showToast({
          type: 'danger',
          autohide: true,
          title: <FormattedMessage id="app.common.error" />,
          message: (
            <>
              <FormattedMessage id="app.common.somethingWentWrong" />
              <RequestResult
                type="error"
                message={error}
                className="mt-2 p-2"
              />
            </>
          ),
        });
      },
    });
  };

  const onSave = (dataToSave) => {
    const req = { output: JSON.stringify(dataToSave) };

    apiUpdateFetch(req, {
      onSuccess: () => {
        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasUpdatededSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.floorPlan' })),
              }}
            />
          ),
        });
      },
      onError: (error) => {
        showToast({
          type: 'danger',
          autohide: true,
          title: <FormattedMessage id="app.common.error" />,
          message: (
            <>
              <FormattedMessage id="app.common.somethingWentWrong" />
              <RequestResult
                type="error"
                message={error}
                className="mt-2 p-2"
              />
            </>
          ),
        });
      },
    });
  };

  return (
    <>
      {(apiLoading || apiError || jsonParseError) && (
        <div className="content container">
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError || jsonParseError} />
        </div>
      )}
      {!apiLoading && !apiError && !jsonParseError && apiData && (
        <div className="position-relative">
          {apiUpdateLoading && (
            <div>
              <div className="position-absolute w-100 h-100 bg-dark opacity-75 zi-99" />
              <div className="position-absolute w-100 h-100 d-flex justify-content-center zi-999">
                <div className="w-50 text-center mt-5">
                  <Spinner animation="border" variant="white" />
                </div>
              </div>
            </div>
          )}
          {apiUnlockLoading && (
            <div>
              <div className="position-absolute w-100 h-100 bg-dark opacity-75 zi-99" />
              <div className="position-absolute w-100 h-100 d-flex justify-content-center zi-999">
                <div className="w-50 text-center mt-5">
                  <Spinner animation="border" variant="white" />
                </div>
              </div>
            </div>
          )}
          <Editor
            width={Number(apiData.width)}
            height={Number(apiData.height)}
            background={apiData.image}
            data={apiData}
            onSave={onSave}
            onUnlock={onUnlock}
            isRestricted={user.user_type === Constants.User.Types[17].id}
          />
        </div>
      )}
    </>
  );
}

export default FloorPlanEditor;
