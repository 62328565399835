import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { camelCase } from 'lodash';

function RequestResult({ message, type, ...props }) {
  const formatErrorString = (e) => {
    if (e.indexOf('errors.') < 0) {
      return e;
    }
    const arr = e.split('.');

    return `${arr[0]}.${arr[1]}.${
      arr[1] === 'api' ? arr[2] : camelCase(arr[2])
    }`;
  };

  const getIcon = () => {
    let icon = '';
    switch (type) {
      case 'error':
      case 'danger':
        icon = 'bi-exclamation-triangle-fill';
        break;

      case 'success':
        icon = 'bi-check-circle';
        break;

      default:
        icon = 'bi-exclamation-triangle-fill';
        break;
    }

    return icon;
  };

  return message ? (
    <Alert variant={type === 'error' ? 'danger' : type} {...props}>
      {type === 'error' ? (
        <div className="d-flex">
          <div className="flex-shrink-0">
            <i className={getIcon()} />
          </div>
          <div className="flex-grow-1 ms-2">
            <FormattedMessage id="app.common.error" />:
            <span className="ms-1">
              <FormattedMessage
                id={formatErrorString(message.message || message)}
                defaultMessage={message.message || message}
              />
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <div className="flex-shrink-0">
            <i className={getIcon()} />
          </div>
          <div className="flex-grow-1 ms-2">
            <FormattedMessage id={message} defaultMessage={message} />
          </div>
        </div>
      )}
    </Alert>
  ) : null;
}

RequestResult.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ message: PropTypes.string }),
  ]),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'danger',
    'warning',
    'info',
    'success',
    'light',
    'dark',
  ]),
};

RequestResult.defaultProps = {
  message: null,
  type: 'success',
};

export default RequestResult;
