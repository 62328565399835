import React from 'react';
import PropTypes from 'prop-types';
import { findAll } from 'highlight-words-core';

export const getHighlightedText = (text, searchText) => {
  if (!searchText) {
    return text;
  }

  const chunks = findAll({
    searchWords: Array.isArray(searchText) ? searchText : [searchText],
    textToHighlight: text,
    caseSensitive: false,
    autoEscape: true,
  });

  const highlightedText = chunks
    .map((chunk) => {
      const { end, highlight, start } = chunk;
      const t = text.substr(start, end - start);
      if (highlight) {
        return `<mark class="bg-primary text-white rounded px-2">${t}</mark>`;
      }
      return t;
    })
    .join('');

  return highlightedText;
};

function HighlightedText({ text, searchText }) {
  return (
    /* eslint-disable react/no-danger */
    <div
      dangerouslySetInnerHTML={{ __html: getHighlightedText(text, searchText) }}
    />
    /* eslint-enable react/no-danger */
  );
}

HighlightedText.propTypes = {
  text: PropTypes.string,
  searchText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
HighlightedText.defaultProps = {
  text: '',
  searchText: null,
};

export default HighlightedText;
