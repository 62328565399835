import React from 'react';
import { PageHeader, SmartCalendar } from '../../../components';

function Calendar() {
  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="calendar"
        breadcrumbData={{
          current: 'calendar',
        }}
      />
      <SmartCalendar
        paths={{
          list: '/calendar/list',
          delete: '/calendar/delete',
          update: '/calendar/update',
        }}
        withRadiusFilter={false}
        // withCategoriesFilter={{ requestUrl: '/categories/list' }}
      />
    </div>
  );
}

export default Calendar;
