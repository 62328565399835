import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../Logo';
import { protectedAreaBasePath } from '../../../router/routeMapping';

function NavbarSingleLogoLight() {
  return (
    <Navbar.Brand as={Link} to={protectedAreaBasePath}>
      <Logo variant="light" className="navbar-brand-logo" />
    </Navbar.Brand>
  );
}

export default NavbarSingleLogoLight;
