import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Form,
  ListGroup,
  Badge,
  Button,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import SimpleBar from 'simplebar-react';
import { useStoreStateSetValue, useStoreStateValue } from '@scena/react-store';
import { isNumber } from 'lodash';
import {
  $layerManager,
  $editor,
  $unitType,
  $searchResults,
  $searchResultsIndex,
} from '../stores/stores';
import { DEFAULT_BOOTH_COLORS, LAYER_TYPES } from '../consts';
import { useAxiosQuery } from '../../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../../components';
import PlaceHolderImg from '../../../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const socialMap = [
  { icon: 'bi-linkedin', apiKey: 'Linkedin' },
  { icon: 'bi-facebook', apiKey: 'Facebook' },
  { icon: 'bi-twitter', apiKey: 'Twitter' },
  { icon: 'bi-instagram', apiKey: 'Instagram' },
  { icon: 'bi-youtube', apiKey: 'Youtube' },
];

function SelectedItemView({ data }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/exhibitor/detail',
    preventFetch: !data.exhibitor.value,
    params: {
      id: data.exhibitor.value,
    },
  });

  const foundedItems = [];
  data.layers.forEach((item) => {
    item.fpeData?.exhibitors?.forEach((nItem) => {
      if (nItem.value === data.exhibitor.value) {
        foundedItems.push(item);
      }
    });
  });

  return (
    <div>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData && (
        <div className="d-flex flex-column px-3 mt-1">
          <h5>{apiData.FirmTitle}</h5>
          <div className="mt-3">
            {foundedItems.map((item) => (
              <Badge
                key={item.id}
                bg=""
                className="me-1 mb-1"
                style={{
                  backgroundColor:
                    DEFAULT_BOOTH_COLORS[item.fpeData.status].background,
                }}
              >
                <FormattedMessage id="app.common.booth" />
                {` ${item.title}`}
              </Badge>
            ))}
          </div>
          {apiData.CatLabels.length > 0 && (
            <div className="mt-2">
              {apiData.CatLabels.map((item) => (
                <Badge bg="secondary" className="me-1 mb-1" key={item}>
                  {item}
                </Badge>
              ))}
            </div>
          )}
          <div className="mt-3">
            {apiData.FirmLogo && (
              <span className="avatar avatar-xl float-start me-2">
                <Image
                  src={apiData.FirmLogo}
                  className="avatar-img"
                  onError={onImageError}
                />
              </span>
            )}
            {apiData.About}
          </div>
          <hr />
          <div>
            <ListGroup variant="flush">
              {apiData.FirmAddress && (
                <ListGroup.Item className="d-flex px-0">
                  <i className="bi-geo-alt me-2" />
                  <span>{apiData.FirmAddress}</span>
                </ListGroup.Item>
              )}
              {apiData.Phone && (
                <ListGroup.Item className="d-flex px-0">
                  <i className="bi-telephone me-2" />
                  <a
                    href={`tel:${apiData.Phone}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {apiData.Phone}
                  </a>
                </ListGroup.Item>
              )}
              {apiData.Web && (
                <ListGroup.Item className="d-flex px-0">
                  <i className="bi-globe me-2" />
                  <a
                    href={`tel:${apiData.Web}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {apiData.Web}
                  </a>
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
          <hr />
          <div>
            {socialMap.map((item) => {
              if (apiData[item.apiKey]) {
                return (
                  <a
                    key={item.apiKey}
                    href={apiData[item.apiKey]}
                    target="_blank"
                    rel="noreferrer"
                    className="me-2"
                  >
                    <i className={`${item.icon} fs-1`} />
                  </a>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

SelectedItemView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

function SelectedEmptyItemView({ data, unitType }) {
  let width = data.additionalData?.width || undefined;
  let height = data.additionalData?.height || undefined;

  if (width && !isNumber(width)) {
    width = width.replace('px', '');
    width = Number(width);
  }

  if (height && !isNumber(height)) {
    height = height.replace('px', '');
    height = Number(height);
  }

  return (
    <div className="d-flex flex-column px-3 mt-1">
      <h5>
        <FormattedMessage id="app.common.booth" />
        {` ${data.additionalData?.title}`}
      </h5>
      {width && height && (
        <div className="mt-3">
          {`${width}${unitType} x ${height}${unitType} / ${
            Math.round(width) * Math.round(height)
          }${unitType}²`}
        </div>
      )}
    </div>
  );
}

SelectedEmptyItemView.propTypes = {
  unitType: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

function PreviewLeftToolbar({
  layoutMargin,
  mapId,
  selectedItem,
  setSelectedItem,
  searchValue,
  setSearchValue,
}) {
  const { formatMessage } = useIntl();

  const layerManager = useStoreStateValue($layerManager);
  const editorRef = useStoreStateValue($editor);
  const unitType = useStoreStateValue($unitType);
  const setSearchResults = useStoreStateSetValue($searchResults);
  const setSearchResultsIndex = useStoreStateSetValue($searchResultsIndex);

  const [listItems, setListItems] = useState([]);

  const handleSearch = (text) => {
    setSearchValue(text);

    const foundedItems = layerManager.layers.filter((item) => {
      const isFounded =
        `${item.title}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1 ||
        item.fpeData?.exhibitors?.filter(
          (nItem) =>
            `${nItem.label}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1
        ).length > 0;
      return isFounded;
    });

    setListItems(foundedItems);
  };

  const onMouseOut = () => {
    editorRef.current.clearSearch();
  };

  const searchLayers = (text, options) => {
    editorRef.current.setSelectedLayers([]);
    if (!text) {
      editorRef.current.clearSearch();
      return;
    }

    const foundedItems = layerManager.layers.filter((item) => {
      const isFounded =
        `${item.title}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1 ||
        item.fpeData?.exhibitors?.filter(
          (nItem) =>
            `${nItem.label}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1
        ).length > 0;
      return isFounded;
    });

    setSearchResultsIndex(0);
    setSearchResults(foundedItems);
    editorRef.current.highlightLayer(foundedItems, 0, options);
  };

  const onMouseOver = (item) => {
    searchLayers(item.title, { opacity: 0.1, scroll: false, markAll: false });
  };

  const onClick = (item, exhibitor) => {
    setSelectedItem({
      mapId,
      exhibitor,
      layers: layerManager.layers,
      additionalData: {
        title: item.title,
        width: item.jsx?.props?.style?.width,
        height: item.jsx?.props?.style?.height,
      },
    });
    searchLayers(item.title, { opacity: 1, scroll: true });
  };

  useEffect(() => {
    setListItems(layerManager.layers);
  }, [layerManager.layers]);

  useEffect(() => {
    handleSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="position-relative overflow-hidden w-100">
      {selectedItem ? (
        <SimpleBar
          style={{
            width: '100%',
            height: `calc(100vh - ${layoutMargin}px)`,
          }}
        >
          <>
            <div className="d-flex align-items-center justify-content-end m-2">
              <Button
                variant="ghost-secondary"
                size="sm"
                className="btn-icon btn-no-focus rounded-circle me-1"
                onClick={() => {
                  setSelectedItem(null);
                }}
              >
                <i className="bi-x-lg" />
              </Button>
            </div>
            {selectedItem.exhibitor ? (
              <SelectedItemView data={selectedItem} />
            ) : (
              <SelectedEmptyItemView data={selectedItem} unitType={unitType} />
            )}
          </>
        </SimpleBar>
      ) : (
        <>
          <div className="d-block p-2">
            <InputGroup className="input-group-merge input-group-borderless w-100">
              <InputGroup.Text className="input-group-prepend">
                <i className="bi-search" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder={formatMessage({
                  id: 'app.common.search',
                })}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  handleSearch(e.target.value);
                }}
              />
            </InputGroup>
          </div>
          <SimpleBar
            style={{
              width: '100%',
              height: `calc(100vh - ${layoutMargin + 57}px)`,
            }}
          >
            {listItems.length === 0 && (
              <RequestResult
                type="secondary"
                message="app.common.noResultsFound"
                className="mx-2"
              />
            )}
            <ListGroup>
              {listItems.map((layer) => {
                if (
                  layer.type === LAYER_TYPES.booth &&
                  layer.fpeData?.exhibitors?.length > 0
                ) {
                  return layer.fpeData.exhibitors.map((exhibitor) => (
                    <ListGroup.Item
                      key={`${layer.id}_${exhibitor.value}`}
                      onMouseOut={onMouseOut}
                      onMouseOver={() => {
                        onMouseOver(layer);
                      }}
                      onClick={() => {
                        onMouseOut();
                        onClick(layer, exhibitor);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>{exhibitor.label}</div>
                        <div className="ps-3">
                          <Badge
                            bg=""
                            style={{
                              backgroundColor:
                                DEFAULT_BOOTH_COLORS[layer.fpeData.status]
                                  .background,
                            }}
                          >
                            {layer.title}
                          </Badge>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ));
                }
                if (
                  searchValue &&
                  layer.type === LAYER_TYPES.booth &&
                  (!layer.fpeData?.exhibitors ||
                    layer.fpeData?.exhibitors?.length === 0)
                ) {
                  return (
                    <ListGroup.Item
                      key={layer.id}
                      onMouseOut={onMouseOut}
                      onMouseOver={() => {
                        onMouseOver(layer);
                      }}
                      onClick={() => {
                        onMouseOut();
                        onClick(layer);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>{layer.title}</div>
                      </div>
                    </ListGroup.Item>
                  );
                }
                return null;
              })}
            </ListGroup>
          </SimpleBar>
        </>
      )}
    </div>
  );
}

PreviewLeftToolbar.propTypes = {
  layoutMargin: PropTypes.number,
  mapId: PropTypes.number.isRequired,
  selectedItem: PropTypes.objectOf(PropTypes.any),
  setSelectedItem: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
};

PreviewLeftToolbar.defaultProps = {
  layoutMargin: 0,
  selectedItem: null,
  setSelectedItem: () => {},
  searchValue: '',
  setSearchValue: () => {},
};

export default PreviewLeftToolbar;
