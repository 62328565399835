/* eslint-disable no-underscore-dangle */

import Debugger from '../utils/Debugger';

export default class HistoryManager {
  undoStack = [];

  redoStack = [];

  currentHistory = null;

  types = {};

  constructor(_editorRef) {
    this._editorRef = _editorRef;
  }

  registerType(type, undo, redo, description) {
    this.types[type] = { undo, redo, description };
  }

  addHistory(type, props, description) {
    const historyType = this.types[type];

    if (!historyType) {
      return;
    }
    const editor = this._editorRef.current;

    Debugger.groupLog('history', `Add:`, type, props);

    this.currentHistory = {
      type,
      props,
      description: description || historyType.description,
    };
    this.undoStack.push(this.currentHistory);
    this.redoStack = [];

    editor.actionManager.act('history.add');
  }

  undo() {
    const editor = this._editorRef.current;
    const { undoStack } = this;
    const undoAction = undoStack.pop();

    if (!undoAction) {
      if (this.currentHistory) {
        this.currentHistory = null;
        editor.actionManager.act('history.undo');
      }
      return;
    }
    this.currentHistory = undoStack[undoStack.length - 1];
    Debugger.groupLog('history', `Undo: ${undoAction.type}`, undoAction.props);
    this.types[undoAction.type].undo(undoAction.props, editor);
    this.redoStack.push(undoAction);
    editor.actionManager.act('history.undo');
  }

  redo() {
    const editor = this._editorRef.current;
    const redoAction = this.redoStack.pop();

    if (!redoAction) {
      return;
    }
    this.currentHistory = redoAction;
    Debugger.groupLog('history', `Redo: ${redoAction.type}`, redoAction.props);
    this.types[redoAction.type].redo(redoAction.props, editor);
    this.undoStack.push(redoAction);
    editor.actionManager.act('history.redo');
  }

  clear() {
    this.currentHistory = null;
    this.redoStack = [];
    this.undoStack = [];
  }
}
