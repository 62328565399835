const Brands = {
  airbnb: { id: 'airbnb', label: 'Airbnb', color: '#ff0000' },
  booking: { id: 'booking', label: 'Booking.com', color: '#ff0000' },
  expedia: { id: 'expedia', label: 'Expedia', color: '#ff0000' },
  facebook: { id: 'facebook', label: 'Facebook', color: '#ff0000' },
  google: { id: 'google', label: 'Google', color: '#ff0000' },
  hotels: { id: 'hotels', label: 'Hotels.com', color: '#ff0000' },
  tripadvisor: { id: 'tripadvisor', label: 'Trip Advisor', color: '#ff0000' },
  trustpilot: { id: 'trustpilot', label: 'Trust Pilot', color: '#ff0000' },
  vrbo: { id: 'vrbo', label: 'Vrbo', color: '#ff0000' },
  yelp: { id: 'yelp', label: 'Yelp', color: '#ff0000' },
  yahoo: { id: 'yahoo', label: 'Yahoo', color: '#ff0000' },
};

export default Brands;
