import React from 'react';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';

function SettingsPassword() {
  const fields = [
    {
      cols: [
        {
          key: 'currentPassword',
          apiKey: 'old_password',
          type: 'password',
          schema: yup.string().required().min(6),
        },
      ],
    },
    {
      cols: [
        {
          key: 'newPassword',
          apiKey: 'new_password',
          type: 'password',
          schema: yup.string().required().min(6),
        },
      ],
    },
    {
      cols: [
        {
          key: 'newPasswordConfirmation',
          type: 'password',
          local: true,
          schema: yup
            .string()
            .required()
            .min(6)
            .oneOf([yup.ref('newPassword')], {
              key: 'app.common.passwordConfirmationNotMatchError',
            }),
        },
      ],
    },
  ];

  return (
    <div>
      <SmartForm
        isHorizontal
        fields={fields}
        submitButtonText="saveChanges"
        requestUrl="/users/updatePassword"
      />
    </div>
  );
}

export default SettingsPassword;
