import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

function ShowMoreText({ text, maxLine, basedOn, hideButton }) {
  const [isExpandable, setIsExpandable] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onReflow = ({ clamped }) => {
    setIsExpandable(clamped);
  };

  return (
    <div>
      <HTMLEllipsis
        unsafeHTML={text}
        maxLine={showMore ? 99999 : maxLine}
        basedOn={basedOn}
        onReflow={showMore ? undefined : onReflow}
      />

      {!hideButton && isExpandable && (
        <Button
          variant="link"
          className="mt-1 p-0 text-primary fs-base fw-normal text-decoration-underline"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          <FormattedMessage
            id={`app.common.${showMore ? 'showLess' : 'showMore'}`}
          />
        </Button>
      )}
    </div>
  );
}

ShowMoreText.propTypes = {
  text: PropTypes.string,
  maxLine: PropTypes.number,
  basedOn: PropTypes.oneOf(['words', 'letters']),
  hideButton: PropTypes.bool,
};
ShowMoreText.defaultProps = {
  text: '',
  maxLine: 1,
  basedOn: 'letters',
  hideButton: false,
};

export default ShowMoreText;
