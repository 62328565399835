import React, { useEffect, useRef, useState } from 'react';
import { SmartWizard } from '../../../components';
import {
  WelcomeStep,
  BusinessInfoStep,
  BusinessContactInfoStep,
  BusinessSocialInfoStep,
  BusinessAppearanceInfoStep,
} from './partials';

function Overview() {
  const wizardRef = useRef();
  const [wizardData, setWizardData] = useState({});
  const [tempData, setTempData] = useState({});

  const onNext = () => {
    wizardRef.current.next();
  };

  const onPrev = () => {
    wizardRef.current.prev();
  };

  const getImageFileFromUrl = (url) => {
    let image;

    if (url) {
      fetch(url)
        .then((res) => res.blob())
        .then((file) => {
          const tempUrl = URL.createObjectURL(file);
          console.log(tempUrl);
        });
    }

    return image;
  };

  const steps = [
    {
      titleKey: 'welcome',
      size: 12,
      Component: <WelcomeStep onNext={onNext} />,
    },
    {
      titleKey: 'businessInfo',
      size: 12,
      Component: (
        <BusinessInfoStep
          onNext={(formData, siteData) => {
            setWizardData((prevData) => ({ ...prevData, ...formData }));
            setTempData(siteData);
            onNext();
          }}
          onPrev={onPrev}
        />
      ),
    },
    {
      titleKey: 'businessContactInfo',
      size: 12,
      Component: (
        <BusinessContactInfoStep
          onNext={(formData) => {
            setWizardData((prevData) => ({ ...prevData, ...formData }));
            onNext();
          }}
          onPrev={onPrev}
          defaultValues={{
            address: tempData.address,
            phone: tempData.phone,
            state: tempData.state,
          }}
        />
      ),
    },
    {
      titleKey: 'socialInfo',
      size: 12,
      Component: (
        <BusinessSocialInfoStep
          onNext={(formData) => {
            setWizardData((prevData) => ({ ...prevData, ...formData }));
            onNext();
          }}
          onPrev={onPrev}
          defaultValues={{
            facebook: tempData.facebook,
            twitter: tempData.twitter,
            instagram: tempData.instagram,
            pinterest: tempData.pinterest,
            linkedin: tempData.linkedin,
          }}
        />
      ),
    },
    {
      titleKey: 'appearance',
      size: 12,
      Component: (
        <BusinessAppearanceInfoStep
          onNext={(formData) => {
            setWizardData((prevData) => ({ ...prevData, ...formData }));
          }}
          onPrev={onPrev}
          defaultValues={{
            logo: getImageFileFromUrl(tempData.logo || tempData.icon),
            logoColor1: tempData.logo_colors?.[0]?.hex,
            logoColor2: tempData.logo_colors?.[1]?.hex,
            logoColor3: tempData.logo_colors?.[2]?.hex,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    console.log('wizardData:', wizardData);
    console.log('tempData:', tempData);
  }, [wizardData, tempData]);

  return (
    <div className="content container">
      <SmartWizard ref={wizardRef} steps={steps} />
    </div>
  );
}

export default Overview;
