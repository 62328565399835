import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Logo, SmartImage } from '../../components';

import Illustration from '../../assets/images/svg/illustrations/oc-unlock.svg';
import IllustrationLight from '../../assets/images/svg/illustrations-light/oc-unlock.svg';

function Unauthorized() {
  return (
    <Container>
      <Link to="/" className="position-absolute top-0 start-0 end-0 py-4">
        <Logo
          className="avatar avatar-xl avatar-4x3 avatar-centered"
          style={{ minWidth: '10rem', maxWidth: '10rem' }}
        />
      </Link>
      <div className="footer-height-offset d-flex justify-content-center align-items-center flex-column">
        <Row className="justify-content-center align-items-sm-center w-100">
          <Col xs="9" sm="6" lg="4">
            <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
              <SmartImage
                className="img-fluid"
                src={Illustration}
                darkSrc={IllustrationLight}
              />
            </div>
          </Col>
          <Col sm="6" lg="4" className="text-center text-sm-start">
            <h1 className="display-1 mb-0">401</h1>
            <p className="lead">
              <FormattedMessage id="app.public.unauthorized.error" />
            </p>
            <Button as={Link} variant="primary" to="/">
              <FormattedMessage id="app.common.goBackToTheApp" />
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Unauthorized;
