import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const DEFAULT_CENTER = { lat: 34.044575741341845, lng: -118.25247202528848 };
const DEFAULT_ZOOM = 14;
const RADIUS_RATIO = 0.0135;
const DEFAULT_MAP_STYLE = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

function render(status) {
  if (status === Status.LOADING)
    return (
      <div className="text-center m-3">
        <Spinner animation="border" size="sm" />
      </div>
    );
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
}

const MapComponent = forwardRef(
  ({ initialMarks, withCircle, ...props }, ref) => {
    const mapElement = useRef();
    const mapInstance = useRef();
    const circleRef = useRef();
    const initialMarkersRef = useRef([]);

    const mapChanged = () => {
      const center = mapInstance.current.getCenter();
      const zoom = mapInstance.current.getZoom();

      if (circleRef.current) {
        circleRef.current.setMap(null);
      }

      const p = 2 ** (21 - zoom);

      circleRef.current = new window.google.maps.Circle({
        strokeColor: '#377dff',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#377dff',
        fillOpacity: 0.35,
        // map: mapInstance.current,
        center,
        radius: p * 1128.49722 * RADIUS_RATIO,
      });

      if (withCircle) {
        circleRef.current.setMap(mapInstance.current);
      }

      /* onChange(
      center.lat(),
      center.lng(),
      (p * (RADIUS_RATIO - 0.005)).toFixed(2)
    ); */
    };

    useEffect(() => {
      const center =
        initialMarks?.length > 0
          ? { lat: initialMarks[0].latitude, lng: initialMarks[0].longitude }
          : DEFAULT_CENTER;

      if (!mapInstance.current) {
        mapInstance.current = new window.google.maps.Map(mapElement.current, {
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: DEFAULT_MAP_STYLE,
          center,
          zoom: DEFAULT_ZOOM,
        });

        mapInstance.current.addListener('dragend', () => {
          mapChanged();
        });

        mapInstance.current.addListener('zoom_changed', () => {
          mapChanged();
        });
      } else {
        mapInstance.current.setCenter(center);
        mapInstance.current.setZoom(DEFAULT_ZOOM);
      }

      mapChanged();

      initialMarkersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      initialMarkersRef.current = [];

      if (initialMarks?.length > 0) {
        const markerImageSvg = document.querySelector('.markerImage').innerHTML;

        initialMarks.forEach(
          ({ latitude: lat, longitude: lng, id, markerType }, i) => {
            let color;

            switch (markerType) {
              case 'myProperty':
                color = '#00c9a7';
                break;

              case 'myPoi':
                color = '#377dff';
                break;

              default:
                color = '#ff0000';
                break;
            }

            const marker = new window.google.maps.Marker({
              icon: {
                anchor: new window.google.maps.Point(24, 24),
                url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
                  markerImageSvg.replace('{{background}}', color)
                )}`,
              },
              // label: title,
              zIndex: 99000 + i,
              position: { lat, lng },
              map: mapInstance.current,
              id,
            });
            initialMarkersRef.current.push(marker);
          }
        );
      }
    }, [initialMarks, withCircle]);

    const focusToItem = (item) => {
      if (circleRef.current) {
        circleRef.current.setMap(null);
      }

      initialMarkersRef.current.forEach((marker) => {
        if (marker.get('id') !== item.id) {
          marker.setMap(null);
        } else {
          marker.setMap(mapInstance.current);
        }
      });

      mapInstance.current.setCenter({
        lat: item.latitude,
        lng: item.longitude,
      });
    };

    const unfocus = () => {
      initialMarkersRef.current.forEach((marker) => {
        marker.setMap(mapInstance.current);
      });
      if (circleRef.current) {
        circleRef.current.setMap(mapInstance.current);
      }
    };

    const resetMap = () => {
      const center =
        initialMarks?.length > 0
          ? { lat: initialMarks[0].latitude, lng: initialMarks[0].longitude }
          : DEFAULT_CENTER;

      mapInstance.current.setCenter(center);
      mapInstance.current.setZoom(DEFAULT_ZOOM);
      mapChanged();
    };

    useImperativeHandle(ref, () => ({
      focusToItem: (item) => {
        focusToItem(item);
      },
      unfocus: () => {
        unfocus();
      },
      resetMap: () => {
        resetMap();
      },
    }));

    return (
      <>
        <div ref={mapElement} id="map" {...props} />
        <div className="markerImage d-none">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.0233 41.1847C20.6947 38.3378 10 28.5994 10 21C10 12 16 6 25 6C34 6 40 13.5 40 21C40 27.3634 29.2018 38.0462 25.929 41.1378C25.3952 41.6421 24.5813 41.662 24.0233 41.1847ZM30 19C30 21.7614 27.7614 24 25 24C22.2386 24 20 21.7614 20 19C20 16.2386 22.2386 14 25 14C27.7614 14 30 16.2386 30 19Z"
              fill="{{background}}"
            />
          </svg>
        </div>
      </>
    );
  }
);

MapComponent.propTypes = {
  initialMarks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  withCircle: PropTypes.bool,
};

MapComponent.defaultProps = {
  initialMarks: [],
  withCircle: false,
};

const GoogleMap = forwardRef(({ height, initialMarks, withCircle }, ref) => (
  <Wrapper apiKey={mapsApiKey} render={render}>
    <MapComponent
      ref={ref}
      initialMarks={initialMarks}
      withCircle={withCircle}
      style={{ width: '100%', height }}
    />
  </Wrapper>
));

GoogleMap.propTypes = {
  height: PropTypes.number,
  initialMarks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  withCircle: PropTypes.bool,
};

GoogleMap.defaultProps = {
  height: 200,
  initialMarks: [],
  withCircle: false,
};

export default GoogleMap;
