import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';

function SmartImage({ src, darkSrc, ...props }) {
  const { theme } = useContext(ThemeContext);
  return <Image src={darkSrc && theme === 'dark' ? darkSrc : src} {...props} />;
}

SmartImage.propTypes = {
  src: PropTypes.string.isRequired,
  darkSrc: PropTypes.string,
};

SmartImage.defaultProps = {
  darkSrc: null,
};

export default SmartImage;
