import React, { useContext, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { AuthContext } from '../../../../context/AuthContext';
import DefaultProfileImage from '../../../../assets/images/default-profile-img.jpg';
import DefaultProfileHeaderImage from '../../../../assets/images/default-profile-header-img.jpg';
import { useAxiosMutation } from '../../../../hooks';
import Utils from '../../../../utils';
import { FormImageInput } from '../../../../components';

function SettingsPhoto() {
  const { user, update } = useContext(AuthContext);

  const [file, setFile] = useState();

  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/users/uploadPhoto',
    params: { id: user.id },
  });

  const handleFileChange = (files) => {
    if (files?.[0]) {
      setFile(files[0]);
    } else {
      setFile(null);
    }
  };

  const uploadFile = () => {
    const req = Utils.Object.toFormdata({ file: file || null });
    apiFetch(req, {
      onSuccess: (data) => {
        update({ user: { ...user, pp: data.pp } });
        setFile(null);
      },
    });
  };

  return (
    <Card className="mb-4">
      <div className="profile-cover">
        <div className="profile-cover-img-wrapper">
          <Image
            src={DefaultProfileHeaderImage}
            className="profile-cover-img"
          />
        </div>
      </div>
      <FormImageInput
        key={user?.pp}
        variant="circle"
        className="profile-cover-avatar"
        defaultValue={user?.pp || DefaultProfileImage}
        onChange={handleFileChange}
        objectFit="cover"
      >
        <>
          {file && apiLoading && !apiError && (
            <span
              className="avatar-uploader-trigger d-flex align-items-center justify-content-center bg-primary"
              style={{ width: 38.5, height: 38.5 }}
            >
              <span className="spinner-border w-50 h-50 text-white" />
            </span>
          )}
          {file && !apiLoading && apiError && (
            <span
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={uploadFile}
              className="avatar-uploader-trigger"
            >
              <i className="bi-exclamation-lg avatar-uploader-icon shadow-sm bg-danger text-white" />
            </span>
          )}
          {user?.pp && !apiLoading && !apiError && (
            <span
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={uploadFile}
              className="avatar-uploader-trigger top-0 bottom-100"
            >
              <i className="bi-x avatar-uploader-icon shadow-sm bg-danger text-white" />
            </span>
          )}
          {file && !apiLoading && !apiError && (
            <span
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={uploadFile}
              className="avatar-uploader-trigger"
            >
              <i className="bi-upload avatar-uploader-icon shadow-sm bg-primary text-white" />
            </span>
          )}
        </>
      </FormImageInput>
    </Card>
  );
}

export default SettingsPhoto;
