import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  DEFAULT_BLOCK_SPACE_STYLE,
  DEFAULT_BLOCK_SPACE_CHILD_STYLE,
  LAYER_TYPES,
} from '../consts';

const BlockSpace = forwardRef(({ title, style, childStyle, ...props }, ref) => {
  const blockSpaceRef = useRef();

  const [fontSize, setFontSize] = useState('2rem');

  useImperativeHandle(ref, () => blockSpaceRef.current, []);

  useEffect(() => {
    const blockSpace = blockSpaceRef.current;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentBoxSize) {
          const contentBoxSize = entry.contentBoxSize[0];
          const s =
            contentBoxSize.inlineSize < contentBoxSize.blockSize
              ? contentBoxSize.inlineSize
              : contentBoxSize.blockSize;
          const size = s / 60;
          setFontSize(`${size}rem`);
        }
      });
    });

    observer.observe(blockSpace);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={blockSpaceRef}
      data-type={LAYER_TYPES.blockSpace}
      style={style}
      {...props}
    >
      <div
        className="id-container"
        contentEditable={false}
        style={{
          ...childStyle,
          fontSize,
        }}
      >
        {title}
      </div>
    </div>
  );
});

BlockSpace.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  childStyle: PropTypes.objectOf(PropTypes.any),
};

BlockSpace.defaultProps = {
  style: DEFAULT_BLOCK_SPACE_STYLE,
  childStyle: DEFAULT_BLOCK_SPACE_CHILD_STYLE,
};

BlockSpace.displayName = 'BlockSpace';

export default BlockSpace;
