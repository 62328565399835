import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { Routes as AppRoutes } from '../router/routeMapping';

const siteTitle = process.env.REACT_APP_SITE_TITLE || '';

const useDocumentTitle = () => {
  const params = useParams();
  const location = useLocation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    let foundedParentRoute;
    let foundedChildRoute;

    const pathname = location.pathname.replace(/\/+$/, '');
    const pathnameSections = pathname.split('/');
    const parentPathname = pathname.slice(0, pathname.lastIndexOf('/'));

    Object.keys(AppRoutes).forEach((key) => {
      const section = AppRoutes[key];
      Object.keys(section).forEach((nKey) => {
        const route = section[nKey];

        if (route.path === pathname) {
          foundedParentRoute = route;
        }

        if (route.path === parentPathname) {
          foundedParentRoute = route;
        }

        if (route.children) {
          const { children } = route;
          Object.keys(children).forEach((cKey) => {
            const cRoute = children[cKey];
            if (cRoute.path === pathname) {
              foundedChildRoute = cRoute;
            }

            const cRouteSections = cRoute.path.split('/');
            Object.keys(params).forEach((pKey) => {
              const paramIndex = cRouteSections.indexOf(`:${pKey}`);
              if (paramIndex > -1) {
                pathnameSections[paramIndex] = `:${pKey}`;
                if (cRoute.path === pathnameSections.join('/')) {
                  foundedParentRoute = route;
                  foundedChildRoute = cRoute;
                }
              }
            });
          });
        }
      });
    });

    let title = siteTitle || '';
    if (foundedParentRoute?.title) {
      title += ` - ${formatMessage({
        id: `app.common.${foundedParentRoute.title}`,
        defaultMessage: foundedParentRoute.title,
      })}`;
    }

    if (foundedChildRoute?.title) {
      title += ` - ${formatMessage({
        id: `app.common.${foundedChildRoute.title}`,
        defaultMessage: foundedChildRoute.title,
      })}`;
    }

    document.title = title;
  }, [location, params, formatMessage]);
};

export default useDocumentTitle;
