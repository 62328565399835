import moment from 'moment';

export const getDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone();
  const end = moment(endDate).clone();
  const dates = [];

  while (now.isSameOrBefore(end)) {
    dates.push(now.toDate());
    now.add(1, 'days');
  }
  return dates;
};
