import React from 'react';
import PropTypes from 'prop-types';

function Main({ children, version }) {
  return (
    <div className="footer">
      <div className="row justify-content-between align-items-center">
        <div className="col">
          <p className="fs-6 mb-0">{children}</p>
        </div>
        {/* <!-- End Col --> */}

        <div className="col-auto">
          <div className="d-flex justify-content-end">
            {/* <!-- List Separator --> */}
            <ul className="list-inline list-separator">
              <li className="list-inline-item">{version}</li>

              <li className="list-inline-item">
                {/* <!-- Keyboard Shortcuts Toggle --> */}
                <button
                  className="btn btn-ghost-secondary btn btn-icon btn-ghost-secondary rounded-circle"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasKeyboardShortcuts"
                  aria-controls="offcanvasKeyboardShortcuts"
                >
                  <i className="bi-command" />
                </button>
                {/* <!-- End Keyboard Shortcuts Toggle --> */}
              </li>
            </ul>
            {/* <!-- End List Separator --> */}
          </div>
        </div>
        {/* <!-- End Col --> */}
      </div>
      {/* <!-- End Row --> */}
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  version: PropTypes.string.isRequired,
};

export default Main;
