import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image, Button } from 'react-bootstrap';
import FsLightbox from 'fslightbox-react';
import PlaceHolderImg from '../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function Gallery({ sources }) {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  return (
    <>
      <Row>
        {sources.map((item, index) => (
          <Col
            key={`thumb_${index.toString()}`}
            sm="6"
            lg="4"
            xl="3"
            className="mb-3 mb-lg-5"
          >
            <Button
              variant="link"
              className="p-0 w-100"
              onClick={() => {
                openLightboxOnSlide(index + 1);
              }}
            >
              <Image
                fluid
                src={item.thumb || item.src}
                className="w-100 rounded"
                onError={onImageError}
              />
            </Button>
          </Col>
        ))}
      </Row>
      <FsLightbox
        exitFullscreenOnClose
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={sources.map((item) => item.src)}
      />
    </>
  );
}

Gallery.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumb: PropTypes.string,
    })
  ),
};

Gallery.defaultProps = {
  sources: [],
};

export default Gallery;
