import React, { useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useParams } from 'react-router-dom';
import { RequestLoading, RequestResult, SmartForm } from '../../../components';
import Constants from '../../../constants';
import { useAxiosQuery } from '../../../hooks';
import { yup } from '../../../lib';

const countryOptionLabel = ({ name, iso }) => (
  <div className="d-flex align-items-center">
    <ReactCountryFlag countryCode={iso['alpha-2']} svg className="me-1" />
    {name}
  </div>
);

function Info() {
  const { id } = useParams();
  const formRef = useRef();

  const getMappedData = (data) => {
    const {
      FirmLogo: logo,
      FirmTitle: title,
      FirmAddress: address,
      About: about,
      Iso: country,
      Web: web,
      Phone: phone,
      Fax: fax,
      Categories: categories,
      ...rest
    } = data;

    return {
      logo,
      title,
      address,
      about,
      country,
      web,
      phone,
      fax,
      categories,
      ...rest,
    };
  };

  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/categories/list',
      params: { quantity: 999999 },
    });

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/exhibitors/detail',
    preventFetch: !id,
    params: {
      id,
    },
    select: (data) => getMappedData(data),
  });

  const fields = [
    {
      cols: [
        {
          key: 'logo',
          apiKey: 'FirmLogo',
          type: 'image',
          schema: yup.mixed(),
          readonly: true,
        },
      ],
    },
    {
      cols: [
        {
          key: 'title',
          apiKey: 'FirmTitle',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'about',
          apiKey: 'About',
          type: 'textarea',
          options: { controller: { props: { rows: 5 } } },
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'country',
          apiKey: 'Iso',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: Object.values(Constants.Countries),
                getOptionValue: (option) => `${option.iso['alpha-2']}`,
                getOptionLabel: (option) => `${option.name}`,
                formatOptionLabel: countryOptionLabel,
                isClearable: true,
              },
            },
          },
          schema: yup.string().required(),
        },
        {
          key: 'categories',
          apiKey: 'Categories',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: categoriesData,
                getOptionValue: (option) => option.id,
                getOptionLabel: (option) => option.Title,
                isLoading: categoriesDataIsLoading,
                isClearable: true,
                isMulti: true,
                isSearchable: true,
              },
            },
          },
          schema: yup.array().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'address',
          apiKey: 'FirmAddress',
          schema: yup.string(),
        },
        {
          key: 'web',
          apiKey: 'Web',
          schema: yup.string().customUrl(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'phone',
          apiKey: 'Phone',
          type: 'phone',
          schema: yup.string().nullable(),
        },
        {
          key: 'fax',
          apiKey: 'Fax',
          type: 'phone',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Facebook',
          apiKey: 'Facebook',
          schema: yup.string(),
        },
        {
          key: 'Instagram',
          apiKey: 'Instagram',
          schema: yup.string(),
        },
        {
          key: 'Linkedin',
          apiKey: 'Linkedin',
          schema: yup.string(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Twitter',
          apiKey: 'Twitter',
          schema: yup.string(),
        },
        {
          key: 'Youtube',
          apiKey: 'Youtube',
          schema: yup.string(),
        },
        { key: 'dummy', type: 'hidden' },
      ],
    },
  ];

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && (
        <SmartForm
          ref={formRef}
          fields={fields}
          defaultValues={apiData}
          footerAlign="start"
          submitButtonText="save"
          requestUrl="/exhibitors/update"
          requestParams={{ id }}
          onRequestSuccess={(data) => {
            if (data.Result) {
              formRef.current?.reset(getMappedData(data.Result));
            }
          }}
        />
      )}
    </>
  );
}

export default Info;
