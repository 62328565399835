/* eslint-disable no-nested-ternary */

import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Nav } from 'react-bootstrap';

import { Link, useMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function SubDropdown({ submenus, show, level }) {
  return (
    <div
      className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
      style={{ minWidth: '14rem', display: show ? 'block' : 'none' }}
    >
      {submenus.map((submenu, index) => (
        <SubMenuItem
          item={submenu}
          key={`${index.toString()}`}
          level={level + 1}
        />
      ))}
    </div>
  );
}

const MenuItemProps = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
};

const MenuItemShape = {
  ...MenuItemProps,
  submenu: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)),
};

SubDropdown.propTypes = {
  submenus: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)).isRequired,
  show: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
};

function SubMenuItem({ item, level }) {
  const match = useMatch({ path: item.href || '' });
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [showDropdown]);

  const onMouseEnter = () => {
    if (window.innerWidth > 960) {
      setShowDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth > 960) {
      setShowDropdown(false);
    }
  };

  let as = 'li';
  if (level > 0) {
    as = item.submenu ? 'div' : Link;
  }

  return (
    <Nav.Item
      as={as}
      role="button"
      className={`${item.submenu ? 'hs-has-sub-menu' : ''} ${
        item.submenu && showDropdown ? 'hs-sub-menu-opened' : ''
      }`}
      bsPrefix={level > 0 && !item.submenu ? 'dropdown-item' : undefined}
      to={item.href || ''}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!item.submenu ? (
        level === 0 ? (
          <Nav.Link as={Link} to={item.href} active={!!match}>
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </Nav.Link>
        ) : (
          <>
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </>
        )
      ) : (
        <>
          <Nav.Link
            className="hs-mega-menu-invoker dropdown-toggle"
            bsPrefix={level > 0 && 'dropdown-item'}
            active={!!match}
          >
            {item.icon && <i className={`${item.icon} dropdown-item-icon`} />}
            <FormattedMessage
              id={`app.common.${item.title}`}
              defaultMessage={item.title}
            />
          </Nav.Link>
          <SubDropdown
            submenus={item.submenu}
            show={showDropdown}
            level={level}
          />
        </>
      )}
    </Nav.Item>
  );
}
SubMenuItem.propTypes = {
  item: PropTypes.shape(MenuItemShape).isRequired,
  level: PropTypes.number.isRequired,
};

function NavbarNavMenu({ menuData }) {
  return (
    <>
      <Nav as="ul">
        {menuData.map((menu, index) => {
          const level = 0;
          return (
            <SubMenuItem
              item={menu}
              key={`${index.toString()}`}
              level={level}
            />
          );
        })}
      </Nav>
      <ul className="navbar-nav d-none">
        {/* <!-- Dashboards --> */}
        <li className="hs-has-sub-menu nav-item">
          <a
            id="dashboardsMegaMenu"
            className="hs-mega-menu-invoker nav-link dropdown-toggle @@if(category=='dashboards'){active}"
            href="#!"
            role="button"
          >
            <i className="bi-house-door dropdown-item-icon" /> Dashboards
          </a>

          {/* <!-- Mega Menu --> */}
          <div
            className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
            aria-labelledby="dashboardsMegaMenu"
            style={{ minWidth: '14rem' }}
          >
            <a
              className="dropdown-item @@if(link=='index.html'){active}"
              href="@@autopath/index.html"
            >
              Default
            </a>
            <a
              className="dropdown-item @@if(link=='dashboard-alternative.html'){active}"
              href="@@autopath/dashboard-alternative.html"
            >
              Alternative
            </a>
          </div>
          {/* <!-- End Mega Menu --> */}
        </li>
        {/* <!-- End Dashboards --> */}

        {/* <!-- Pages --> */}
        <li className="hs-has-sub-menu nav-item">
          <a
            id="pagesMegaMenu"
            className="hs-mega-menu-invoker nav-link dropdown-toggle @@if(category=='pages'){active}"
            href="#!"
            role="button"
          >
            <i className="bi-files-alt dropdown-item-icon" /> Pages
          </a>

          {/* <!-- Mega Menu --> */}
          <div
            className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
            aria-labelledby="pagesMegaMenu"
            style={{ minWidth: '14rem' }}
          >
            {/* <!-- Users --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="usersMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='users'){active}"
                href="#!"
                role="button"
              >
                Users
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="usersMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='users.html'){active}"
                  href="@@autopath/users.html"
                >
                  Overview
                </a>
                <a
                  className="dropdown-item @@if(link=='users-leaderboard.html'){active}"
                  href="@@autopath/users-leaderboard.html"
                >
                  Leaderboard
                </a>
                <a
                  className="dropdown-item @@if(link=='users-add-user.html'){active}"
                  href="@@autopath/users-add-user.html"
                >
                  Add User{' '}
                  <span className="badge bg-info rounded-pill ms-1">Hot</span>
                </a>
              </div>
            </div>
            {/* <!-- End Users --> */}

            {/* <!-- User Profile --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="userProfileMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='user-profile'){active}"
                href="#!"
                role="button"
              >
                User Profile{' '}
                <span className="badge bg-primary rounded-pill ms-1">5</span>
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="userProfileMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='user-profile.html'){active}"
                  href="@@autopath/user-profile.html"
                >
                  Profile
                </a>
                <a
                  className="dropdown-item @@if(link=='user-profile-teams.html'){active}"
                  href="@@autopath/user-profile-teams.html"
                >
                  Teams
                </a>
                <a
                  className="dropdown-item @@if(link=='user-profile-projects.html'){active}"
                  href="@@autopath/user-profile-projects.html"
                >
                  Projects
                </a>
                <a
                  className="dropdown-item @@if(link=='user-profile-connections.html'){active}"
                  href="@@autopath/user-profile-connections.html"
                >
                  Connections
                </a>
                <a
                  className="dropdown-item @@if(link=='user-profile-my-profile.html'){active}"
                  href="@@autopath/user-profile-my-profile.html"
                >
                  My Profile
                </a>
              </div>
            </div>
            {/* <!-- End User Profile --> */}

            {/* <!-- Account --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="accountMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='account'){active}"
                href="#!"
                role="button"
              >
                Account
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="accountMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='account-settings.html'){active}"
                  href="@@autopath/account-settings.html"
                >
                  Settings
                </a>
                <a
                  className="dropdown-item @@if(link=='account-billing.html'){active}"
                  href="@@autopath/account-billing.html"
                >
                  Billing
                </a>
                <a
                  className="dropdown-item @@if(link=='account-invoice.html'){active}"
                  href="@@autopath/account-invoice.html"
                >
                  Invoice
                </a>
              </div>
            </div>
            {/* <!-- End Account --> */}

            {/* <!-- E-commerce --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="ecommerceMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='ecommerce'){active}"
                href="#!"
                role="button"
              >
                E-commerce
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="ecommerceMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='ecommerce.html'){active}"
                  href="@@autopath/ecommerce.html"
                >
                  Overview
                </a>

                {/* <!-- Products --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="productsMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='products'){active}"
                    href="#!"
                    role="button"
                  >
                    Products
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="productsMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='ecommerce-products.html'){active}"
                      href="@@autopath/ecommerce-products.html"
                    >
                      Products
                    </a>
                    <a
                      className="dropdown-item @@if(link=='ecommerce-product-details.html'){active}"
                      href="@@autopath/ecommerce-product-details.html"
                    >
                      Product Details
                    </a>
                    <a
                      className="dropdown-item @@if(link=='ecommerce-add-product.html'){active}"
                      href="@@autopath/ecommerce-add-product.html"
                    >
                      Add Product
                    </a>
                  </div>
                </div>
                {/* <!-- End Products --> */}

                {/* <!-- Products --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="ordersMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='orders'){active}"
                    href="#!"
                    role="button"
                  >
                    Orders
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="ordersMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='ecommerce-orders.html'){active}"
                      href="@@autopath/ecommerce-orders.html"
                    >
                      Orders
                    </a>
                    <a
                      className="dropdown-item @@if(link=='ecommerce-order-details.html'){active}"
                      href="@@autopath/ecommerce-order-details.html"
                    >
                      Order Details
                    </a>
                  </div>
                </div>
                {/* <!-- End Products --> */}

                {/* <!-- Customers --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="customersMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='customers'){active}"
                    href="#!"
                    role="button"
                  >
                    Customers
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="customersMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='ecommerce-customers.html'){active}"
                      href="@@autopath/ecommerce-customers.html"
                    >
                      Customers
                    </a>
                    <a
                      className="dropdown-item @@if(link=='ecommerce-customer-details.html'){active}"
                      href="@@autopath/ecommerce-customer-details.html"
                    >
                      Customer Details
                    </a>
                    <a
                      className="dropdown-item @@if(link=='ecommerce-add-customers.html'){active}"
                      href="@@autopath/ecommerce-add-customers.html"
                    >
                      Add Customers
                    </a>
                  </div>
                </div>
                {/* <!-- End Customers --> */}

                <a
                  className="dropdown-item @@if(link=='ecommerce-referrals.html'){active}"
                  href="@@autopath/ecommerce-referrals.html"
                >
                  Referrals
                </a>
                <a
                  className="dropdown-item @@if(link=='ecommerce-manage-reviews.html'){active}"
                  href="@@autopath/ecommerce-manage-reviews.html"
                >
                  Manage Reviews
                </a>
                <a
                  className="dropdown-item @@if(link=='ecommerce-checkout.html'){active}"
                  href="@@autopath/ecommerce-checkout.html"
                >
                  Checkout
                </a>
              </div>
            </div>
            {/* <!-- End E-commerce --> */}

            {/* <!-- Projects --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="projectsMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='projects'){active}"
                href="#!"
                role="button"
              >
                Projects
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="projectsMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='projects.html'){active}"
                  href="@@autopath/projects.html"
                >
                  Overview
                </a>
                <a
                  className="dropdown-item @@if(link=='projects-timeline.html'){active}"
                  href="@@autopath/projects-timeline.html"
                >
                  Timeline
                </a>
              </div>
            </div>
            {/* <!-- End Projects --> */}

            {/* <!-- Project --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="projectMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='project'){active}"
                href="#!"
                role="button"
              >
                Project
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="projectMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                <a
                  className="dropdown-item @@if(link=='project.html'){active}"
                  href="@@autopath/project.html"
                >
                  Overview
                </a>
                <a
                  className="dropdown-item @@if(link=='project-files.html'){active}"
                  href="@@autopath/project-files.html"
                >
                  Files
                </a>
                <a
                  className="dropdown-item @@if(link=='project-activity.html'){active}"
                  href="@@autopath/project-activity.html"
                >
                  Activity
                </a>
                <a
                  className="dropdown-item @@if(link=='project-teams.html'){active}"
                  href="@@autopath/project-teams.html"
                >
                  Teams
                </a>
                <a
                  className="dropdown-item @@if(link=='project-settings.html'){active}"
                  href="@@autopath/project-settings.html"
                >
                  Settings
                </a>
              </div>
            </div>
            {/* <!-- End Project --> */}

            {/* <!-- Authentication --> */}
            <div className="hs-has-sub-menu nav-item">
              <a
                id="authenticationMegaMenu"
                className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='authentication'){active}"
                href="#!"
                role="button"
              >
                Authentication
              </a>

              <div
                className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="authenticationMegaMenu"
                style={{ minWidth: '14rem' }}
              >
                {/* <!-- Log In --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="loginMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='login'){active}"
                    href="#!"
                    role="button"
                  >
                    Log In
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="loginMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='authentication-login-basic.html'){active}"
                      href="@@autopath/authentication-login-basic.html"
                    >
                      Basic
                    </a>
                    <a
                      className="dropdown-item @@if(link=='authentication-login-cover.html'){active}"
                      href="@@autopath/authentication-login-cover.html"
                    >
                      Cover
                    </a>
                  </div>
                </div>
                {/* <!-- End Log In --> */}

                {/* <!-- Sign Up --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="signupMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='signup'){active}"
                    href="#!"
                    role="button"
                  >
                    Sign Up
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="signupMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='authentication-signup-basic.html'){active}"
                      href="@@autopath/authentication-signup-basic.html"
                    >
                      Basic
                    </a>
                    <a
                      className="dropdown-item @@if(link=='authentication-signup-cover.html'){active}"
                      href="@@autopath/authentication-signup-cover.html"
                    >
                      Cover
                    </a>
                  </div>
                </div>
                {/* <!-- End Sign Up --> */}

                {/* <!-- Reset Password --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="resetPasswordMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='resetPassword'){active}"
                    href="#!"
                    role="button"
                  >
                    Reset Password
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="resetPasswordMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='authentication-reset-password-basic.html'){active}"
                      href="@@autopath/authentication-reset-password-basic.html"
                    >
                      Basic
                    </a>
                    <a
                      className="dropdown-item @@if(link=='authentication-reset-password-cover.html'){active}"
                      href="@@autopath/authentication-reset-password-cover.html"
                    >
                      Cover
                    </a>
                  </div>
                </div>
                {/* <!-- End Reset Password --> */}

                {/* <!-- Email Verification --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="emailVerificationMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='emailVerification'){active}"
                    href="#!"
                    role="button"
                  >
                    Email Verification
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="emailVerificationMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='authentication-email-verification-basic.html'){active}"
                      href="@@autopath/authentication-email-verification-basic.html"
                    >
                      Basic
                    </a>
                    <a
                      className="dropdown-item @@if(link=='authentication-email-verification-cover.html'){active}"
                      href="@@autopath/authentication-email-verification-cover.html"
                    >
                      Cover
                    </a>
                  </div>
                </div>
                {/* <!-- End Email Verification --> */}

                {/* <!-- 2-step Verification --> */}
                <div className="hs-has-sub-menu nav-item">
                  <a
                    id="2stepVerificationMegaMenu"
                    className="hs-mega-menu-invoker dropdown-item dropdown-toggle @@if(subcategory=='2stepVerification'){active}"
                    href="#!"
                    role="button"
                  >
                    2-step Verification
                  </a>

                  <div
                    className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="2stepVerificationMegaMenu"
                    style={{ minWidth: '14rem' }}
                  >
                    <a
                      className="dropdown-item @@if(link=='authentication-2-step-verification-basic.html'){active}"
                      href="@@autopath/authentication-2-step-verification-basic.html"
                    >
                      Basic
                    </a>
                    <a
                      className="dropdown-item @@if(link=='authentication-2-step-verification-cover.html'){active}"
                      href="@@autopath/authentication-2-step-verification-cover.html"
                    >
                      Cover
                    </a>
                  </div>
                </div>
                {/* <!-- End 2-step Verification --> */}

                <a
                  className="dropdown-item"
                  href="#!"
                  data-bs-toggle="modal"
                  data-bs-target="#welcomeMessageModal"
                >
                  Welcome Message
                </a>
                <a
                  className="dropdown-item @@if(link=='error-404.html'){active}"
                  href="@@autopath/error-404.html"
                >
                  Error 404
                </a>
                <a
                  className="dropdown-item @@if(link=='error-500.html'){active}"
                  href="@@autopath/error-500.html"
                >
                  Error 500
                </a>
              </div>
            </div>
            {/* <!-- End Authentication --> */}

            <a
              className="dropdown-item @@if(link=='api-keys.html'){active}"
              href="@@autopath/api-keys.html"
              data-placement="left"
            >
              API Keys
            </a>
            <a
              className="dropdown-item @@if(link=='welcome-page.html'){active}"
              href="@@autopath/welcome-page.html"
              data-placement="left"
            >
              Welcome Page
            </a>
            <a
              className="dropdown-item @@if(link=='landing.html'){active}"
              href="@@autopath/landing.html"
              data-placement="left"
            >
              Landing Page{' '}
              <span className="badge bg-info rounded-pill ms-1">New</span>
            </a>
          </div>
          {/* <!-- End Mega Menu --> */}
        </li>
        {/* <!-- End Pages --> */}

        {/* <!-- Apps --> */}
        <li className="hs-has-sub-menu nav-item">
          <a
            id="appsMegaMenu"
            className="hs-mega-menu-invoker nav-link dropdown-toggle @@if(category=='apps'){active}"
            href="#!"
            role="button"
          >
            <i className="bi-app-indicator dropdown-item-icon" /> Apps
          </a>

          <div
            className="hs-sub-menu dropdown-menu navbar-dropdown-menu-borderless"
            aria-labelledby="appsMegaMenu"
            style={{ minWidth: '14rem' }}
          >
            <a
              className="dropdown-item @@if(link=='apps-kanban.html'){active}"
              href="@@autopath/apps-kanban.html"
            >
              Kanban
            </a>
            <a
              className="dropdown-item @@if(link=='apps-calendar.html'){active}"
              href="@@autopath/apps-calendar.html"
            >
              Calendar
            </a>
            <a
              className="dropdown-item @@if(link=='apps-invoice-generator.html'){active}"
              href="@@autopath/apps-invoice-generator.html"
            >
              Invoice Generator
            </a>
            <a
              className="dropdown-item @@if(link=='apps-file-manager.html'){active}"
              href="@@autopath/apps-file-manager.html"
            >
              File Manager
            </a>
          </div>
        </li>
        {/* <!-- End Apps --> */}

        <li className="nav-item">
          <a
            className="nav-link @@if(link=='layouts/index.html'){active}"
            href="@@autopath/layouts/index.html"
          >
            <i className="bi-grid-1x2 dropdown-item-icon" /> Layouts
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link @@if(link=='documentation/index.html'){active}"
            href="@@autopath/documentation/index.html"
            data-placement="left"
          >
            <i className="bi-book dropdown-item-icon" /> Docs
          </a>
        </li>
      </ul>
    </>
  );
}

NavbarNavMenu.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.shape(MenuItemShape)).isRequired,
};

export default NavbarNavMenu;
