import Constants from '../constants';

export const publicAreaBasePath = '';
export const protectedAreaBasePath = '/dashboard';
export const authBasePath = '/auth';

/**
 * @description Define supported routes
 */
export const Routes = {
  public: {
    FLOOR_PLAN: {
      title: 'floorPlan',
      path: `${publicAreaBasePath}/floor-plans/:guid`,
      componentName: 'FloorPlan',
    },
    NOT_FOUND: { title: 'notFound', path: '/404', componentName: 'NotFound' },
    UNAUTHORIZED: {
      title: 'unauthorized',
      path: '/unauthorized',
      componentName: 'Unauthorized',
    },
  },
  auth: {
    LOGIN: {
      title: 'login',
      path: `${authBasePath}/login`,
      componentName: 'Login',
    },
    FORGOT_PASSWORD: {
      title: 'forgotPassword',
      path: `${authBasePath}/forgot-password`,
      componentName: 'ForgotPassword',
    },
    /* SIGN_UP: {
      title: 'signUp',
      path: `${authBasePath}/sign-up`,
      componentName: 'SignUp',
    },
    VERIFY_EMAIL: {
      title: 'verifyEmail',
      path: `${authBasePath}/verify-email`,
      componentName: 'VerifyEmail',
    },
    VERIFY_CODE: {
      title: 'verifyCode',
      path: `${authBasePath}/verify-code`,
      componentName: 'VerifyCode',
    }, */
  },
  protected: {
    OVERVIEW: {
      title: 'dashboard',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/overview`,
      componentName: 'Overview',
      hidden: true,
    },
    FLOOR_PLANS: {
      title: 'floorPlans',
      icon: 'bi-layout-wtf',
      path: `${protectedAreaBasePath}/floor-plans`,
      componentName: 'FloorPlans',
    },
    FLOOR_PLAN_EDITOR: {
      title: 'floorPlanEditor',
      icon: 'bi-layout-wtf',
      path: `${protectedAreaBasePath}/floor-plans/:id/edit`,
      componentName: 'FloorPlanEditor',
      hidden: true,
      roles: [Constants.User.Types[15].id, Constants.User.Types[17].id],
    },
    ACCOUNT_SETUP: {
      title: 'accountSetup',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/account-setup`,
      componentName: 'AccountSetup',
      hidden: true,
    },
    ACCOUNT: {
      title: 'account',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/account`,
      componentName: 'Account',
      hidden: true,
      children: {
        ACCOUNT_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/account/settings`,
          componentName: 'AccountSettings',
        },
        /* ACCOUNT_BILLING: {
          title: 'billing',
          path: `${protectedAreaBasePath}/account/billing`,
          componentName: 'AccountBilling',
        }, */
      },
    },
    CALENDAR: {
      title: 'calendar',
      icon: 'bi-calendar-event',
      path: `${protectedAreaBasePath}/calendar`,
      componentName: 'Calendar',
    },
    FILE_MANAGER: {
      title: 'fileManager',
      icon: 'bi-files',
      path: `${protectedAreaBasePath}/file-manager`,
      componentName: 'FileManager',
    },

    USERS: {
      title: 'users',
      icon: 'bi-people',
      path: `${protectedAreaBasePath}/users`,
      componentName: 'Users',
      roles: [Constants.User.Types[15].id, Constants.User.Types[17].id],
    },
    /* GenericGridView: {
      title: 'genericGridView',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/generic-grid-view`,
      componentName: 'GenericGridView',
      hidden: true,
    }, */
    /* GENERIC_DETAIL: {
      title: 'genericDetail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/generic-detail/:id`,
      componentName: 'GenericDetail',
      hidden: true,
      children: {
        TABLE: {
          title: 'table',
          path: `${protectedAreaBasePath}/generic-detail/:id/table`,
          componentName: 'GenericDetailTable',
        },
        GALLERY: {
          title: 'gallery',
          path: `${protectedAreaBasePath}/generic-detail/:id/gallery`,
          componentName: 'GenericDetailGallery',
        },
        CHART: {
          title: 'chart',
          path: `${protectedAreaBasePath}/generic-detail/:id/chart`,
          componentName: 'GenericDetailChart',
        },
        TIMELINE: {
          title: 'timeline',
          path: `${protectedAreaBasePath}/generic-detail/:id/timeline`,
          componentName: 'GenericDetailTimeline',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/generic-detail/:id/files`,
          componentName: 'GenericDetailFiles',
        },
        FORM: {
          title: 'form',
          path: `${protectedAreaBasePath}/generic-detail/:id/form`,
          componentName: 'GenericDetailForm',
        },
      },
    }, */
  },
};
