/* eslint-disable no-plusplus, no-continue, no-empty */

import { prefixNames } from 'framework-utils';
import {
  splitComma,
  isArray,
  isFunction,
  isObject,
  splitUnit,
} from '@daybrush/utils';
import { getElementInfo } from 'react-moveable';
import { fromTranslation, matrix3d } from '@scena/matrix';
import { PREFIX, DATA_SCENA_ELEMENT_ID } from '../consts';

export function prefix(...classNames) {
  return prefixNames(PREFIX, ...classNames);
}

export function getContentElement(el) {
  if (el.contentEditable === 'inherit') {
    return getContentElement(el.parentElement);
  }
  if (el.contentEditable === 'true') {
    return el;
  }
  return null;
}

export function between(val, min, max) {
  return Math.min(Math.max(min, val), max);
}

export function getId(el) {
  return el.getAttribute(DATA_SCENA_ELEMENT_ID);
}
export function getIds(els) {
  return els.map((el) => getId(el));
}

export function checkInput(target) {
  const tagName = target.tagName.toLowerCase();

  return (
    target.isContentEditable || tagName === 'input' || tagName === 'textarea'
  );
}

export function inputChecker(e) {
  const { inputEvent } = e;
  const { target } = inputEvent;

  if (!target || checkInput(target)) {
    return false;
  }
  return true;
}
export function keyChecker(e) {
  if (inputChecker(e)) {
    e.inputEvent.preventDefault();
  }
}

export function checkImageLoaded(el) {
  if (el.tagName.toLowerCase() !== 'img') {
    return Promise.all(
      [].slice
        .call(el.querySelectorAll('img'))
        .map((nEl) => checkImageLoaded(nEl))
    );
  }
  return new Promise((resolve) => {
    if (el.complete) {
      resolve();
    } else {
      el.addEventListener('load', function loaded() {
        resolve();

        el.removeEventListener('load', loaded);
      });
    }
  });
}

export function getParentScenaElement(el) {
  if (!el) {
    return null;
  }
  if (el.hasAttribute(DATA_SCENA_ELEMENT_ID)) {
    return el;
  }
  return getParentScenaElement(el.parentElement);
}

export function makeScenaFunctionComponent(id, component) {
  const nComponent = component;
  nComponent.scenaComponentId = id;

  return nComponent;
}

export function getScenaAttrs(el) {
  const { attributes } = el;
  const { length } = attributes;
  const attrs = {};

  for (let i = 0; i < length; i += 1) {
    const { name, value } = attributes[i];

    if (name === DATA_SCENA_ELEMENT_ID || name === 'style') {
      continue;
    }
    attrs[name] = value;
  }

  return attrs;
}

export function isScenaFunction(value) {
  return isFunction(value) && 'scenaComponentId' in value;
}

export function isScenaElement(value) {
  return isObject(value) && !isScenaFunction(value);
}

export function isScenaFunctionElement(value) {
  return isScenaElement(value) && isFunction(value.type);
}

export function setMoveMatrix(frame, moveMatrix) {
  const transformOrders = [...(frame.getOrders(['transform']) || [])];

  if (`${transformOrders[0]}`.indexOf('matrix3d') > -1) {
    const matrix = frame.get('transform', transformOrders[0]);
    const prevMatrix = isArray(matrix)
      ? matrix
      : splitComma(matrix).map((v) => parseFloat(v));

    frame.set(
      'transform',
      transformOrders[0],
      matrix3d(moveMatrix, prevMatrix)
    );
  } else if (frame.has('transform', 'matrix3d')) {
    let num = 1;
    while (frame.has('transform', `matrix3d${++num}`)) {}

    frame.set('transform', `matrix3d${num}`, [...moveMatrix]);
    frame.setOrders(['transform'], [`matrix3d${num}`, ...transformOrders]);
  } else {
    frame.set('transform', 'matrix3d', [...moveMatrix]);
    frame.setOrders(['transform'], ['matrix3d', ...transformOrders]);
  }
}

export function getOffsetOriginMatrix(el, container) {
  const stack = getElementInfo(el, container);
  const origin = stack.targetOrigin;
  const translation = fromTranslation(
    [origin[0], origin[1], origin[2] || 0],
    4
  );

  return matrix3d(stack.offsetMatrix, translation);
}

export function flattenLayerGroup(group) {
  const layers = [];

  group.children.forEach((child) => {
    if (child.type === 'group') {
      layers.push(...flattenLayerGroup(child));
    } else {
      layers.push(child);
    }
  });
  return layers;
}

export function isArrayEquals(arr1, arr2) {
  return arr1.length === arr2.length && arr1.every((el, i) => el === arr2[i]);
}

export function isDeepArrayEquals(arr1, arr2) {
  return (
    arr1.length === arr2.length &&
    arr1.every((value1, i) => {
      const value2 = arr2[i];
      const isArray1 = isArray(value1);
      const isArray2 = isArray(value2);
      if (isArray1 && isArray2) {
        return isDeepArrayEquals(value1, value2);
      }
      if (!isArray1 && !isArray2) {
        return value1 === value2;
      }
      return false;
    })
  );
}

export function isArrayContains(arr1, arr2) {
  return arr1.every((el, i) => el === arr2[i]);
}

export function getPureValue(value) {
  return splitUnit(`${value || 0}`).value;
}
