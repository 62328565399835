import { diff } from '@egjs/list-differ';

function restoreRender(layer, prev, next, editor) {
  const el = layer.ref.current;

  if (!el) {
    throw new Error('No Element');
    // return false;
  }

  const frame = editor.layerManager.getFrame(layer);

  frame.clear();
  frame.set(next);

  const result = diff(Object.keys(prev), Object.keys(next));
  const { removed, prevList } = result;

  removed.forEach((index) => {
    el.style.removeProperty(prevList[index]);
  });
  el.style.cssText += frame.toCSSText();

  return true;
}

/* function undoRender({ layer, prev, next }, editor) {
  if (!restoreRender(layer, next, prev, editor)) {
    return;
  }
  editor.moveableRef.current.updateRect();
  editor.actionManager.act('render.end');
}
function redoRender({ layer, prev, next }, editor) {
  if (!restoreRender(layer, prev, next, editor)) {
    return;
  }
  editor.moveableRef.current.updateRect();
  editor.actionManager.act('render.end');
} */

function undoRenderGroup({ infos }, editor) {
  infos.forEach(({ layer, prev, next }) => {
    restoreRender(layer, next, prev, editor);
  });
  editor.moveableRef.current.updateRect();
  editor.actionManager.act('render.end');
}

function redoRenderGroup({ infos }, editor) {
  infos.forEach(({ layer, prev, next }) => {
    restoreRender(layer, prev, next, editor);
  });
  editor.moveableRef.current.updateRect();
  editor.actionManager.act('render.end');
}

function undoSelectTargets({ prevs }, editor) {
  editor.setSelectedLayers(prevs, true);
}

function redoSelectTargets({ nexts }, editor) {
  editor.setSelectedLayers(nexts, true);
}

function undoChangeText({ layer, prev }) {
  const nLayer = layer;
  const el = nLayer.ref.current;
  el.innerText = prev;
  nLayer.innerText = prev;
}

function redoChangeText({ layer, next }) {
  const nLayer = layer;
  const el = nLayer.ref.current;
  el.innerText = next;
  nLayer.innerText = next;
}

// function undoCreateElements({ infos, prevSelected }: Record<string, any>, editor: EditorManagerInstance) {
//     const res = editor.removeByIds(infos.map((info: ElementInfo) => info.id), true);

//     if (prevSelected) {
//         res.then(() => {
//             editor.setSelectedTargets(editor.viewportRef.current!.getElements(prevSelected), true);
//         })
//     }
// }
// function restoreElements({ infos }: Record<string, any>, editor: EditorManagerInstance) {
//     editor.appendJSXs(infos.map((info: ElementInfo) => ({
//         ...info,
//     })), true);
// }

// function undoChangeText({ prev, next, id }: Record<string, any>, editor: EditorManagerInstance) {
//     const info = editor.viewportRef.current!.getInfo(id)!;
//     info.innerText = prev;
//     info.el!.innerText = prev;
// }
// function redoChangeText({ prev, next, id }: Record<string, any>, editor: EditorManagerInstance) {
//     const info = editor.viewportRef.current!.getInfo(id)!;
//     info.innerText = next;
//     info.el!.innerText = next;
// }
// function undoMove({ prevInfos }: MovedResult, editor: EditorManagerInstance) {
//     editor.moves(prevInfos, true);
// }
// function redoMove({ nextInfos }: MovedResult, editor: EditorManagerInstance) {
//     editor.moves(nextInfos, true);
// }

export function registerHistoryTypes(historyManager) {
  historyManager.registerType(
    'render',
    undoRenderGroup,
    redoRenderGroup,
    'render elements'
  );
  historyManager.registerType(
    'selectTargets',
    undoSelectTargets,
    redoSelectTargets,
    'select targets'
  );
  historyManager.registerType(
    'changeText',
    undoChangeText,
    redoChangeText,
    'change text'
  );
}
