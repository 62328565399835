import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';

function BusinessAppearanceInfoStep({ onNext, onPrev, defaultValues }) {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fields = [
    {
      cols: [
        {
          key: 'logo',
          type: 'image',
          schema: yup.mixed().requiredFile(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'color1',
          type: 'color',
          schema: yup.string().required(),
        },
        {
          key: 'color2',
          type: 'color',
          schema: yup.string().required(),
        },
        {
          key: 'color3',
          type: 'color',
          schema: yup.string().required(),
        },
      ],
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitted(true);
    onNext(data);
  };

  useEffect(() => {
    if (!isSubmitted) {
      formRef.current.reset(defaultValues);
    }
  }, [isSubmitted, defaultValues]);

  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="8">
        <SmartForm
          ref={formRef}
          fields={fields}
          onSubmit={onSubmit}
          isWizard
          onWizardPrev={onPrev}
          // submitButtonText="send"
          defaultValues={defaultValues}
        />
      </Col>
    </Row>
  );
}

BusinessAppearanceInfoStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  defaultValues: PropTypes.objectOf(PropTypes.any),
};

BusinessAppearanceInfoStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  defaultValues: null,
};

export default BusinessAppearanceInfoStep;
