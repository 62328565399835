import React from 'react';
import { Row, Col, Badge, Image, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { PageHeader, SmartGrid, GoogleMap } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import PlaceHolderImg from '../../../assets/images/placeholder.png';
import { useAxiosQuery } from '../../../hooks';

const memoizedLink = (link) => (
  <a href={link} target="_blank" rel="noreferrer">
    {link}
  </a>
);

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function GenericGridView() {
  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/categories/list',
      params: { quantity: 999999 },
    });

  const formatter = (data) => {
    const { id, FirmTitle, FirmLogo, Latitude, Longitude } = data;
    const listKeys = [
      {
        labelKey: 'categories',
        apiKey: 'Categories',
        resolver: (d) =>
          d.map((item) => (
            <Badge key={`category_${item}`} className="me-1 mb-1">
              {categoriesData.find((cD) => cD.id === item)?.Title}
            </Badge>
          )),
      },
      {
        labelKey: 'address',
        apiKey: 'FirmAddress',
      },
      {
        labelKey: 'web',
        apiKey: 'Web',
        resolver: (d) => memoizedLink(d),
      },
    ];

    const formatted = {
      original: data,
      id,
      title: FirmTitle,
      link: `${AppRoutes.protected.EXHIBITORS_DETAIL.path.replace(':id', id)}`,
      content: (
        <Row>
          {(Latitude && Longitude) ||
            (FirmLogo && (
              <Col xs="4">
                {FirmLogo && (
                  <Image
                    src={FirmLogo}
                    onError={onImageError}
                    className="w-100 mb-3"
                  />
                )}
                {Latitude && Longitude && (
                  <GoogleMap
                    initialMarks={[
                      {
                        latitude: Number(Latitude),
                        longitude: Number(Longitude),
                      },
                    ]}
                    defaultCenter={{
                      lat: Number(Latitude),
                      lng: Number(Longitude),
                    }}
                  />
                )}
              </Col>
            ))}
          <Col xs={(Latitude && Longitude) || FirmLogo ? '8' : '12'}>
            <ListGroup variant="flush">
              {listKeys.map(({ labelKey, apiKey, resolver }, index) => (
                <ListGroup.Item key={`list_item_${index.toString()}`}>
                  <Row className="align-items-center">
                    <Col>
                      <span className="card-subtitle">
                        <FormattedMessage id={`app.common.${labelKey}`} />
                      </span>
                    </Col>
                    <Col xs="auto">
                      {resolver ? resolver(data[apiKey]) : data[apiKey]}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      ),
    };

    return formatted;
  };

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="grid"
        breadcrumbData={{
          current: 'grid',
        }}
      />
      <Row>
        <Col className="mb-5">
          {categoriesData && !categoriesDataIsLoading && (
            <SmartGrid
              paths={{
                list: '/exhibitors/list',
              }}
              formatter={formatter}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default GenericGridView;
