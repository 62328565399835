import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

function FormError({ error, ...props }) {
  const { formatMessage } = useIntl();

  let err = error;

  if (!err) return null;

  if (!err.message) {
    [err] = Object.values(err);
  }

  if (err.message.values) {
    err.message.values.path = formatMessage({
      id: `app.common.${err.message.values.path}`,
      defaultMessage: err.message.values.path,
    });
  }

  return (
    <Form.Control.Feedback type="invalid" {...props}>
      {err.message.key ? (
        <FormattedMessage
          id={err.message.key}
          values={err.message.values || {}}
        />
      ) : (
        err.message
      )}
    </Form.Control.Feedback>
  );
}

FormError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string,
        values: PropTypes.shape({ path: PropTypes.string }),
      }),
    ]),
  }),
};

FormError.defaultProps = {
  error: null,
};

export default FormError;
