import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'react-bootstrap';
import { useStoreStateValue } from '@scena/react-store';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';
// import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { $selectedFlattenLayers } from '../../stores/stores';
import { LAYER_TYPES } from '../../consts';
import { useAxiosQuery } from '../../../../../../hooks';

function ExhibitorsSection({ onChange }) {
  const { id: mapId } = useParams();
  const [inputValue, setInputValue] = useState('');
  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/exhibitors/list',
    preventFetch: inputValue?.length < 3,
    params: {
      // quantity: 99999999,
      page: 1,
      quantity: 50,
      FirmTitle: inputValue,
      mapid: mapId,
    },
    select: (data) =>
      data.map(({ id, FirmTitle }) => ({
        label: FirmTitle,
        value: id,
      })),
  });

  const onInputChange = debounce((text) => {
    setInputValue(text);
  }, 100);

  const changeExhibitorsData = (value) => {
    onChange({ datas: [{ property: 'exhibitors', value }] });
  };

  useEffect(() => {
    setInputValue('');
  }, []);

  const selectedLayers = useStoreStateValue($selectedFlattenLayers);

  if (selectedLayers.length === 0 || selectedLayers.length > 1) {
    return null;
  }

  const selectedLayer = selectedLayers[0];

  if (selectedLayer.type !== LAYER_TYPES.booth) {
    return null;
  }

  return (
    <Row>
      <Col xs="12">
        <Form.Label>
          <FormattedMessage id="app.common.exhibitors" />
        </Form.Label>
      </Col>
      <Col xs="12" className="mb-3">
        <div className="position-relative" style={{ zIndex: 100 }}>
          <ReactSelect
            key={selectedLayer.id}
            options={apiData}
            className="react-select-custom-container"
            classNamePrefix="react-select-custom"
            isSearchable
            isMulti
            isLoading={apiLoading}
            isDisabled={selectedLayer.lock || apiError}
            onChange={changeExhibitorsData}
            onInputChange={(val) => {
              if (val) {
                onInputChange(val);
              } else {
                setInputValue('');
              }
            }}
            defaultValue={selectedLayer.fpeData?.exhibitors || []}
          />
        </div>
      </Col>
    </Row>
  );
}

ExhibitorsSection.propTypes = {
  onChange: PropTypes.func,
};

ExhibitorsSection.defaultProps = {
  onChange: () => {},
};

export default ExhibitorsSection;
