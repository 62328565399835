import React, { useState } from 'react';

import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

function Rating({ type, initVal, editable, className }) {
  const [rating, setRating] = useState(initVal);

  const changeRating = (val) => {
    if (editable) {
      setRating(val);
    }
  };

  const getIcon = (val, max) => {
    const isInt = val % 1 === 0;
    return !isInt && val < max ? `${type}-half` : `${type}-fill`;
  };

  return (
    <div className={`d-flex gap-1 ${className || ''}`}>
      {Array(5)
        .fill(0)
        .map((x, i) => (
          <Button
            key={`rating-${i.toString()}`}
            variant="link"
            onClick={() => {
              changeRating(i + 1);
            }}
            className="p-0 text-warning"
            style={{ cursor: editable ? 'pointer' : 'default' }}
          >
            <i
              className={`bi-${
                rating >= i + 1 - 0.5 ? getIcon(rating, i + 1) : type
              }`}
            />
          </Button>
        ))}
    </div>
  );
}

Rating.propTypes = {
  type: PropTypes.string,
  initVal: PropTypes.number,
  editable: PropTypes.bool,
  className: PropTypes.string,
};

Rating.defaultProps = {
  type: 'star',
  initVal: 0,
  editable: false,
  className: null,
};

export default Rating;
