import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'react-bootstrap';
import { useStoreStateValue } from '@scena/react-store';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';
// import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { $selectedFlattenLayers } from '../../stores/stores';
import { LAYER_TYPES } from '../../consts';
import { useAxiosQuery } from '../../../../../../hooks';
import Constants from '../../../../../../constants';

const usersOptionLabel = ({ label, data }) => (
  <div className="d-flex flex-column">
    {label}
    <span className="small">{data.email}</span>
  </div>
);

function UserSection({ onChange }) {
  const { id: mapId } = useParams();
  const [inputValue, setInputValue] = useState('');
  const {
    data: apiData,
    isFetching: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/users/List',
    preventFetch: inputValue?.length < 3,
    params: {
      // quantity: 99999999,
      page: 1,
      quantity: 50,
      name: inputValue,
      mapid: mapId,
      user_type: Constants.User.Types[18].id,
    },
    select: (data) =>
      data.map(({ id, name, surname, ...rest }) => ({
        label: `${name} ${surname}`,
        value: id,
        data: rest,
      })),
  });

  const onInputChange = debounce((text) => {
    setInputValue(text);
  }, 100);

  const changeUserData = (value) => {
    onChange({ datas: [{ property: 'user', value }] });
  };

  useEffect(() => {
    setInputValue('');
  }, []);

  const selectedLayers = useStoreStateValue($selectedFlattenLayers);

  if (selectedLayers.length === 0 || selectedLayers.length > 1) {
    return null;
  }

  const selectedLayer = selectedLayers[0];

  if (selectedLayer.type !== LAYER_TYPES.booth) {
    return null;
  }

  return (
    <Row>
      <Col xs="12">
        <Form.Label>
          <FormattedMessage id="app.common.salesRepresentative" />
        </Form.Label>
      </Col>
      <Col xs="12" className="mb-3">
        <div className="position-relative" style={{ zIndex: 99 }}>
          <ReactSelect
            key={selectedLayer.id}
            options={apiData}
            className="react-select-custom-container"
            classNamePrefix="react-select-custom"
            formatOptionLabel={usersOptionLabel}
            isSearchable
            isClearable
            isMulti={false}
            isLoading={apiLoading}
            isDisabled={selectedLayer.lock || apiError}
            onChange={changeUserData}
            onInputChange={(val) => {
              if (val) {
                onInputChange(val);
              } else {
                setInputValue('');
              }
            }}
            defaultValue={selectedLayer.fpeData?.user || []}
          />
        </div>
      </Col>
    </Row>
  );
}

UserSection.propTypes = {
  onChange: PropTypes.func,
};

UserSection.defaultProps = {
  onChange: () => {},
};

export default UserSection;
