import { atom, compute } from '@scena/react-store';

export const $layerManager = atom(null);
export const $historyManager = atom(null);
export const $clipboardManager = atom(null);
export const $keyManager = atom(null);
export const $memoryManager = atom(null);
export const $actionManager = atom(null);

export const $horizontalGuidelines = atom([]);
export const $verticalGuidelines = atom([]);
export const $selectedLayers = atom([]);
export const $selectedFlattenLayers = compute(({ get }) => {
  const layerManager = get($layerManager);
  const selectedLayers = get($selectedLayers);

  return layerManager.toFlatten(selectedLayers);
});
export const $layers = atom([]);
export const $zoom = atom(1);
export const $unitType = atom('m');
export const $groupOrigin = atom('50% 50%');
export const $selectedTool = atom('pointer');
export const $pointer = atom('move');
export const $rect = atom('rect');
export const $searchResults = atom([]);
export const $searchResultsIndex = atom(0);

export const $editor = atom(null);
export const $selecto = atom(null);
export const $moveable = atom(null);
export const $infiniteViewer = atom(null);
export const $horizontalGuides = atom(null);
export const $verticalGuides = atom(null);
// export const $menu = atom<MutableRefObject<Menu | null> | null>(null);
