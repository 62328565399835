import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useAxiosQuery, useConfirmModal, useFormModal } from '../../hooks';
import RequestLoading from '../RequestLoading';
import RequestResult from '../RequestResult';
import SmartImage from '../SmartImage';
import Illustration from '../../assets/images/svg/illustrations/oc-megaphone.svg';
import IllustrationLight from '../../assets/images/svg/illustrations-light/oc-megaphone.svg';
import Pagination from '../SmartTable/Pagination';

const perPageValues = [10, 20, 30];

function SmartGrid({
  paths,
  params,
  formatter,
  defaultValuesFormatter,
  gridSizes,
  createUpdateFields,
}) {
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { formatMessage } = useIntl();
  const [items, setItems] = useState();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(perPageValues[0]);

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: paths.list,
    params: {
      ...params,
      ...paths.listParams,
      page: currentPage,
      quantity: itemPerPage,
    },
    dataWithCount: true,
    onSuccess: (data) => {
      const formatted = data.rows.map((item) => formatter(item));
      setItems(formatted);
      setCount(data.count);
    },
  });

  const onDeleteItem = async (id, title) => {
    const requestUrl = paths.delete;
    const requestParams = {
      ...params,
      ...paths.deleteParams,
      id,
    };

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureToDeleteTheXY"
          values={{
            x: title,
            y: lowerCase(formatMessage({ id: `app.common.item` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      setItems((prevFiles) => prevFiles.filter((item) => item.id !== id));
    }
  };

  const onCreateOrUpdateItem = async (item) => {
    const defaultValues = item ? defaultValuesFormatter(item) : {};

    const requestParams = item
      ? {
          id: item.id,
          ...params,
          ...paths.updateParams,
        }
      : {
          ...params,
          ...paths.createParams,
        };

    const formData = await form({
      size: 'lg',
      title: item ? 'editItem' : 'newItem',
      confirmLabel: item ? 'save' : 'create',
      requestUrl: item ? paths.update : paths.create,
      requestParams,
      fields: createUpdateFields,
      fetchOnStart: false,
      defaultValues,
    });

    if (formData) {
      setItems((prevItems) => [
        ...prevItems.filter((nItem) => nItem.id !== formData.Result.id),
        formatter(formData.Result),
      ]);
    }
  };

  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  const onPerPageChange = (perPage) => {
    if (perPage !== itemPerPage) {
      setCurrentPage(1);
      setItemPerPage(perPage);
    }
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading &&
        !apiError &&
        items &&
        (items.length > 0 ? (
          <>
            {paths.create && (
              <div className="d-flex justify-content-end mb-5">
                <Button
                  onClick={() => {
                    onCreateOrUpdateItem();
                  }}
                >
                  <i className="bi-plus me-1" />
                  <FormattedMessage id="app.common.newItem" />
                </Button>
              </div>
            )}
            <Row {...gridSizes}>
              {items.map(({ id, title, content, link, original }, index) => (
                <Col key={`grid_${index.toString()}`} className="mb-3 mb-lg-5">
                  <Card className="h-100">
                    <Card.Body>
                      <Row className="align-items-center mb-2">
                        <Col xs={paths.delete || paths.update ? '9' : '12'}>
                          <h4 className="mb-1">{title}</h4>
                        </Col>

                        {(paths.delete || paths.update) && (
                          <Col xs="3" className="text-end">
                            <Dropdown align="end" className="ms-auto">
                              <Dropdown.Toggle
                                bsPrefix="btn"
                                variant="ghost-secondary"
                                size="sm"
                                className="btn-icon rounded-circle card-dropdown-btn"
                              >
                                <i className="bi-three-dots-vertical" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="m-0" renderOnMount>
                                <Dropdown.Header>
                                  <FormattedMessage id="app.common.actions" />
                                </Dropdown.Header>
                                {paths.update && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      onCreateOrUpdateItem(original);
                                    }}
                                  >
                                    <i className="bi-pencil-square dropdown-item-icon" />
                                    <span>
                                      <FormattedMessage id="app.common.edit" />
                                    </span>
                                  </Dropdown.Item>
                                )}
                                {paths.delete && (
                                  <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                      onClick={() => {
                                        onDeleteItem(id, title);
                                      }}
                                    >
                                      <i className="bi-trash dropdown-item-icon text-danger" />
                                      <span className="text-danger">
                                        <FormattedMessage id="app.common.delete" />
                                      </span>
                                    </Dropdown.Item>
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        )}
                      </Row>
                      {typeof content === 'string' ? <p>{content}</p> : content}
                    </Card.Body>
                    {link && (
                      <Card.Footer className="text-end">
                        <Button size="sm" as={Link} to={link}>
                          <i className="bi-eye me-1" />
                          <FormattedMessage id="app.common.view" />
                        </Button>
                      </Card.Footer>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
              <Col className="col-sm mb-2 mb-sm-0">
                <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                  <span className="me-2">
                    <FormattedMessage id="app.common.showing" />:
                  </span>

                  <Select
                    menuPlacement="top"
                    options={perPageValues.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    defaultValue={{
                      value: perPageValues[0],
                      label: perPageValues[0],
                    }}
                    value={{ value: itemPerPage, label: itemPerPage }}
                    onChange={(item) => {
                      onPerPageChange(item.value);
                    }}
                    className="react-select-custom-container"
                    classNamePrefix="react-select-custom"
                    isSearchable={false}
                  />

                  <span className="text-secondary mx-2">
                    <FormattedMessage id="app.common.of" />
                  </span>
                  {count}
                </div>
              </Col>

              <Col className="col-sm-auto">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <Pagination
                    currentPage={currentPage}
                    totalRows={count}
                    pageChangeHandler={onPageChange}
                    rowsPerPage={itemPerPage}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Card className="card-dashed shadow-none card-centered">
            <Card.Body>
              <div className="mb-3">
                <SmartImage
                  className="avatar avatar-xl"
                  src={Illustration}
                  darkSrc={IllustrationLight}
                />
              </div>
              <p>
                <FormattedMessage id="app.common.noResultsFound" />
              </p>
              {paths.create && (
                <div className="d-flex flex-column align-items-center">
                  <Button
                    onClick={() => {
                      onCreateOrUpdateItem();
                    }}
                    className="mt-3"
                  >
                    <i className="bi-plus me-1" />
                    <FormattedMessage id="app.common.newItem" />
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
    </>
  );
}

SmartGrid.propTypes = {
  paths: PropTypes.shape({
    list: PropTypes.string,
    listParams: PropTypes.objectOf(PropTypes.any),
    create: PropTypes.string,
    createParams: PropTypes.objectOf(PropTypes.any),
    delete: PropTypes.string,
    deleteParams: PropTypes.objectOf(PropTypes.any),
    update: PropTypes.string,
    updateParams: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  formatter: PropTypes.func,
  defaultValuesFormatter: PropTypes.func,
  gridSizes: PropTypes.shape({
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  createUpdateFields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

SmartGrid.defaultProps = {
  params: {},
  formatter: (data) => data,
  defaultValuesFormatter: (data) => data,
  gridSizes: { xs: '1', lg: '2' },
  createUpdateFields: [],
};

export default SmartGrid;
