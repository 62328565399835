import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
  Routes as AppRoutes,
  authBasePath,
  protectedAreaBasePath,
} from './routeMapping';
import { Auth } from '../layouts';

function AuthRoute() {
  const location = useLocation();

  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return (
      <Navigate to={protectedAreaBasePath} state={{ from: location }} replace />
    );
  }

  return (
    <Auth>
      {location.pathname === authBasePath ? (
        <Navigate to={AppRoutes.auth.LOGIN.path} replace />
      ) : (
        <Outlet />
      )}
    </Auth>
  );
}

export default AuthRoute;
