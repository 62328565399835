/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  cloneElement,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useStoreStateValue } from '@scena/react-store';
import { prefix } from '../utils/utils';
import { DATA_SCENA_ELEMENT_ID } from '../consts';
import { $layerManager, $layers } from '../stores/stores';

export function ScenaLayerElement(props) {
  const layerManager = useStoreStateValue($layerManager);
  const { layer } = props;
  const { jsx } = layer;
  const jsxProps = {
    key: layer.id,
    ref: layer.ref,
    [DATA_SCENA_ELEMENT_ID]: layer.id,
  };

  useEffect(() => {
    const element = layer.ref.current;
    element.style.cssText += layerManager.compositeFrame(layer).toCSSText();
  }, [layer.id]);

  return cloneElement(jsx, {
    ...jsx.props,
    ...jsxProps,
  });
}
const Viewport = forwardRef(
  ({ onBlur, style, children, showGrid, isPreview }, ref) => {
    const layers = useStoreStateValue($layers);

    useImperativeHandle(ref, () => ({}), []);

    return (
      <div
        className={`${prefix('viewport-container')} position-relative`}
        onBlur={onBlur}
        style={style}
      >
        {!isPreview && showGrid && (
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            style={{ userSelect: 'none', position: 'absolute' }}
          >
            <defs>
              <pattern
                id="smallGrid"
                width="1"
                height="1"
                patternUnits="userSpaceOnUse"
              >
                <path
                  d="M 1 0 L 0 0 0 1"
                  fill="none"
                  stroke="#f2f2f2"
                  strokeWidth="0.1"
                />
              </pattern>
              <pattern
                id="grid"
                width="10"
                height="10"
                patternUnits="userSpaceOnUse"
              >
                <rect width="10" height="10" fill="url(#smallGrid)" />
                <path
                  d="M 10 0 L 0 0 0 10"
                  fill="none"
                  stroke="#cccccc"
                  strokeWidth="0.1"
                />
              </pattern>
            </defs>

            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        )}
        <svg
          width="100%"
          height="100%"
          style={{ pointerEvents: 'none', position: 'absolute' }}
        >
          <rect
            width="100%"
            height="100%"
            style={{
              fill: 'transparent',
              strokeWidth: 0.2,
              stroke: 'black',
            }}
          />
        </svg>
        {children}
        <div
          className={`${prefix('viewport')} position-relative w-100 h-100`}
          {...{ [DATA_SCENA_ELEMENT_ID]: 'viewport' }}
        >
          {layers.map((layer) => (
            <ScenaLayerElement key={layer.id} layer={layer} />
          ))}
        </div>
      </div>
    );
  }
);

Viewport.displayName = 'Viewport';

Viewport.propTypes = {
  onBlur: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  showGrid: PropTypes.bool,
  isPreview: PropTypes.bool,
};

Viewport.defaultProps = {
  onBlur: () => {},
  style: {},
  children: null,
  showGrid: true,
  isPreview: false,
};

export default Viewport;
