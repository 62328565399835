import React from 'react';

import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Constants from '../../constants';
import { ReactComponent as Airbnb } from '../../assets/images/brands/icon/airbnb.svg';
import { ReactComponent as Bookingcom } from '../../assets/images/brands/icon/bookingcom.svg';
import { ReactComponent as Expedia } from '../../assets/images/brands/icon/expedia.svg';
import { ReactComponent as Facebook } from '../../assets/images/brands/icon/facebook.svg';
import { ReactComponent as Google } from '../../assets/images/brands/icon/google.svg';
import { ReactComponent as Hotelscom } from '../../assets/images/brands/icon/hotelscom.svg';
import { ReactComponent as TripAdvisor } from '../../assets/images/brands/icon/tripadvisor.svg';
import { ReactComponent as TrustPilot } from '../../assets/images/brands/icon/trustpilot.svg';
import { ReactComponent as Vrbo } from '../../assets/images/brands/icon/vrbo.svg';
import { ReactComponent as Yelp } from '../../assets/images/brands/icon/yelp.svg';
import { ReactComponent as Yahoo } from '../../assets/images/brands/icon/yahoo.svg';

const defaulSize = 20;

function BrandIcon({ brand, size, style, ...props }) {
  let BrandComponent;
  let s;
  switch (brand) {
    case 'airbnb':
      BrandComponent = Airbnb;
      s = size || defaulSize;
      break;
    case 'booking':
      BrandComponent = Bookingcom;
      s = size || defaulSize;
      break;
    case 'expedia':
      BrandComponent = Expedia;
      s = size || defaulSize;
      break;
    case 'facebook':
      BrandComponent = Facebook;
      s = size || defaulSize;
      break;
    case 'google':
      BrandComponent = Google;
      s = size || defaulSize;
      break;
    case 'hotels':
      BrandComponent = Hotelscom;
      s = size || defaulSize;
      break;
    case 'tripadvisor':
      BrandComponent = TripAdvisor;
      s = size || defaulSize;
      break;
    case 'trustpilot':
      BrandComponent = TrustPilot;
      s = size || defaulSize;
      break;
    case 'vrbo':
      BrandComponent = Vrbo;
      s = size || defaulSize;
      break;
    case 'yelp':
      BrandComponent = Yelp;
      s = size || defaulSize;
      break;
    case 'yahoo':
      BrandComponent = Yahoo;
      s = size || defaulSize;
      break;
    default:
      BrandComponent = Airbnb;
      s = size || defaulSize;
      break;
  }

  return (
    <div
      className="brand-icon-container d-flex align-items-center"
      style={{ height: size, ...style }}
      {...props}
    >
      <div
        className="brand-icon-wrapper d-flex align-items-center justify-content-center"
        style={{ height: s }}
      >
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{Constants.Brands[brand].label}</Tooltip>}
        >
          <BrandComponent className="brand-icon" id={`${brand}-icon`} />
        </OverlayTrigger>
      </div>
    </div>
  );
}

BrandIcon.propTypes = {
  brand: PropTypes.oneOf(Object.keys(Constants.Brands)).isRequired,
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

BrandIcon.defaultProps = {
  size: null,
  style: null,
};

export default BrandIcon;
