import * as Dom from './dom';
import * as Date from './date';
import * as Color from './color';
import * as Object from './object';
import * as Chart from './chart';
import * as String from './string';
import * as Array from './array';

const Utils = { Dom, Date, Color, Object, Chart, String, Array };

export default Utils;
