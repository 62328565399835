/* eslint-disable no-console */
export default class Debugger {
  static log(...args) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        '%cFloorPlanEditor',
        'padding: 1px 3px; background: #4af; color: #fff;',
        ...args
      );
    }
  }

  static groupLog(group, ...args) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        `%cFloorPlanEditor%c${group}`,
        'padding: 1px 3px; background: #4af; color: #fff;',
        'padding: 1px 3px; background: #fa4; color: #fff;',
        ...args
      );
    }
  }
}
