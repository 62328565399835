import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Nav } from 'react-bootstrap';
import { useStoreStateValue } from '@scena/react-store';
import { FormattedMessage } from 'react-intl';
import { $layerManager, $selectedFlattenLayers } from '../../stores/stores';
import { LAYER_TYPES } from '../../consts';
import Utils from '../../../../../../utils';

const alignItems = [
  { id: 'left', titleKey: 'alignLeft', icon: 'bi-text-left' },
  { id: 'center', titleKey: 'alignCenter', icon: 'bi-text-center' },
  { id: 'right', titleKey: 'alignRight', icon: 'bi-text-right' },
];

function TextSection({ onChange }) {
  const layerManager = useStoreStateValue($layerManager);
  const selectedLayers = useStoreStateValue($selectedFlattenLayers);

  if (selectedLayers.length === 0 || selectedLayers.length > 1) {
    return null;
  }

  const selectedLayer = selectedLayers[0];
  const layerCSS = layerManager.getFrame(selectedLayer).toCSSObject();

  if (selectedLayer.type !== LAYER_TYPES.text) {
    return null;
  }

  return (
    <Row>
      <Col xs="12" className="mb-3">
        <Form.Label className="d-block">
          <FormattedMessage id="app.common.text" />
        </Form.Label>
        <Nav
          variant="segment"
          className="nav-sm"
          activeKey={layerCSS.textAlign}
          onSelect={(value) => {
            onChange({ properties: [{ property: 'textAlign', value }] });
          }}
        >
          {alignItems.map(({ id, icon }) => (
            <Nav.Item key={id}>
              <Nav.Link eventKey={id} disabled={selectedLayer.lock}>
                <i className={icon} />
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col xs="12" className="mb-3">
        <Form.Label>
          <FormattedMessage id="app.common.textColor" />
        </Form.Label>
        <Form.Control
          type="color"
          value={Utils.Color.getHexColorFromRGBA(layerCSS.color)}
          onChange={(e) => {
            onChange({
              properties: [{ property: 'color', value: e.target.value }],
            });
          }}
          disabled={selectedLayer.lock}
        />
      </Col>
    </Row>
  );
}

TextSection.propTypes = {
  onChange: PropTypes.func,
};

TextSection.defaultProps = {
  onChange: () => {},
};

export default TextSection;
