import { merge } from 'lodash';
import moment from 'moment';
import Constants from '../constants';
import { getRGBAColorFromHex } from './color';

const fontFamily = 'Open Sans, sans-serif';

const lightColor = '#c5c8cc';
const darkColor = '#677788';

const lightGridColor = '#2f3235';
const darkGridColor = '#e7eaf3';

const lightBgColor = '#ffffff';
const darkBgColor = '#25282a';

export const getDoughnutChartDefaultOptions = (options, theme, plugins) => {
  const opts = options || {};

  let fontColor = darkColor;
  let labelColor = lightBgColor;
  let labelBgColor = getRGBAColorFromHex(darkBgColor, 0.8);

  if (theme === 'dark') {
    fontColor = lightColor;
    labelColor = darkBgColor;
    labelBgColor = getRGBAColorFromHex(lightBgColor, 0.8);
  }

  const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '75%',
    plugins: {
      legend: {
        display: true,
        onHover: (e) => {
          e.native.target.style.cursor = 'pointer';
        },
        onLeave: (e) => {
          e.native.target.style.cursor = 'default';
        },
        position: 'bottom',
        title: {
          display: true,
          padding: 10,
        },
        labels: {
          padding: 10,
          usePointStyle: true,
          color: fontColor,
          boxHeight: 7,
          font: {
            family: fontFamily,
            size: 14,
          },
        },
      },
      datalabels: {
        display: !!plugins?.datalabels,
        color: labelColor,
        backgroundColor: labelBgColor,
        borderRadius: 99999,
        font: {
          family: fontFamily,
          size: 13,
        },
      },
    },
  };

  return merge(baseOptions, opts);
};

export const getLineChartDefaultOptions = (options, theme, plugins) => {
  const opts = options || {};

  let gridColor = darkGridColor;
  let fontColor = darkColor;
  let labelColor = lightColor;
  let labelBgColor = getRGBAColorFromHex(lightGridColor, 0.8);

  if (theme === 'dark') {
    gridColor = lightGridColor;
    fontColor = lightColor;
    labelColor = darkColor;
    labelBgColor = getRGBAColorFromHex(darkGridColor, 0.8);
  }

  const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    gradientPosition: {
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0,
    },
    plugins: {
      legend: {
        display: true,
        onHover: (e) => {
          e.native.target.style.cursor = 'pointer';
        },
        onLeave: (e) => {
          e.native.target.style.cursor = 'default';
        },
        position: 'bottom',
        title: {
          display: true,
          padding: 10,
        },
        labels: {
          padding: 10,
          usePointStyle: true,
          color: fontColor,
          boxHeight: 7,
          font: {
            family: fontFamily,
            size: 14,
          },
        },
      },
      datalabels: {
        display: !!plugins?.datalabels,
        color: labelColor,
        backgroundColor: labelBgColor,
        borderRadius: 99999,
        font: {
          family: fontFamily,
          size: 12,
        },
      },
      tooltip: {
        enabled: true,
        indicatorWidth: '8px',
        indicatorHeight: '8px',
        transition: '0.2s',
        prefix: null,
        postfix: null,
        hasIndicator: true,
        mode: 'index',
        intersect: false,
        lineMode: true,
        usePointStyle: true,
        callbacks: {
          title: (context) => {
            if (context[0].chart.config?.options?.scales?.x?.type === 'time') {
              return moment(context[0].parsed.x).format(
                Constants.DateFormats.APP.Moment.Common
              );
            }
            return context[0].label;
          },
          label: (context) => {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (context.parsed.y !== null) {
              const prefix =
                context.chart.config?.options?.plugins?.tooltip?.prefix || '';
              const postfix =
                context.chart.config?.options?.plugins?.tooltip?.postfix || '';

              label += `${prefix}${context.parsed.y}${postfix}`;
            }
            return label;
          },
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      y: {
        grid: {
          color: gridColor,
          drawBorder: false,
        },
        ticks: {
          color: fontColor,
          font: {
            family: fontFamily,
          },
          padding: 10,
          postfix: 'k',
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: fontColor,
          font: { size: 12, family: fontFamily },
          padding: 5,
        },
      },
    },
  };

  return merge(baseOptions, opts);
};

export const getLineChartOptions = (index, theme, options) => {
  const opts = options || {};
  const color =
    Constants.Colors.Palette[index] ||
    Constants.Colors.Palette[Constants.Colors.Palette.length - 1];
  const baseOptions = {
    backgroundColor: [
      getRGBAColorFromHex(color, 0.5),
      'rgba(255, 255, 255, 0)',
    ],
    borderColor: color,
    borderWidth: 2,
    pointRadius: 0,
    pointBorderColor: theme === 'dark' ? '#25282a' : '#fff',
    pointBackgroundColor: color,
    pointHoverRadius: 0,
    hoverBorderColor: theme === 'dark' ? '#25282a' : '#fff',
    hoverBackgroundColor: color,
  };

  return merge(baseOptions, opts);
};
