import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import ReactSelect, { components } from 'react-select';
import { Col, Row } from 'react-bootstrap';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';

function Option({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...props
}) {
  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className="d-flex justify-content-between">
        {children}
        {isSelected && <i className="bi bi-check2" />}
      </div>
    </components.Option>
  );
}

Option.propTypes = {
  getStyles: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  innerProps: PropTypes.any.isRequired,
};

function ValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(children[0]) && children[0].length > 0 ? (
        <div className="position-absolute text-nowrap">
          <FormattedPlural
            value={children[0].length}
            one={<FormattedMessage id="app.common.oneItemSelected" />}
            other={
              <FormattedMessage
                id="app.common.nItemsSelected"
                values={{ n: children[0].length }}
              />
            }
          />
        </div>
      ) : (
        children[0]
      )}
      {children[1]}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any),
    ])
  ),
};

ValueContainer.defaultProps = {
  children: [],
};

function FieldSelect({
  options,
  loading,
  error,
  onChange,
  isMulti,
  required,
  disabled,
}) {
  return (
    <div style={{ minWidth: 200 }}>
      <ReactSelect
        options={!loading && !error && options ? options : []}
        className="react-select-custom-container"
        classNamePrefix="react-select-custom"
        components={isMulti ? { ValueContainer, Option } : {}}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={!isMulti}
        isClearable={!required}
        isMulti={isMulti}
        isSearchable={false}
        isLoading={loading}
        isDisabled={disabled}
        onChange={onChange}
        defaultValue={required && options.length > 0 ? options[0] : undefined}
      />
    </div>
  );
}

FieldSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })
  ).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldSelect.defaultProps = {
  loading: false,
  error: null,
  onChange: () => {},
  isMulti: false,
  required: false,
  disabled: false,
};

const filters = [
  {
    id: 'date',
    labelKey: 'dateType',
    apiKey: 'date_field',
    isMulti: false,
    required: true,
  },
  {
    id: 'sum',
    labelKey: 'sum',
    apiKey: 'sum_fields',
    isMulti: false,
    required: true,
  },
  { id: 'group', labelKey: 'groups', apiKey: 'group_field', isMulti: false },
];

function ChartFilters({ onChange, disabled }) {
  const { formatMessage } = useIntl();
  const [apiData, setApiData] = useState();
  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: '/events/listCategories',
    onComplete: (data) => {
      if (data) {
        console.log(data);

        const fakeApiData = {
          date: [
            'BidStartDate',
            'BidEndDate',
            'listingDate',
            'soldDate',
            'removedDate',
          ],
          sum: ['price', 'soldPrice', 'soldAboveListing', 'soldAfterDays'],
          group: [
            'site',
            'type',
            'propertyType',
            'BuildingType',
            'title',
            'zipCode',
            'mlsNumber',
            'apnNumber',
            'status',
            'yearBuilt',
            'yearRenovated',
            'apartmentStyle',
            'bathrooms',
            'bedrooms',
            'community',
            'lotSize',
            'parking',
            'zoning',
            'totalSqFt',
            'buyerAgencyCompensation',
            'buyerAgencyCompensationType',
          ],
        };

        Object.keys(fakeApiData).forEach((key) => {
          fakeApiData[key] = fakeApiData[key].map((subItem) => ({
            label: formatMessage({
              id: `app.common.${subItem}`,
              defaultMessage: subItem,
            }),
            value: subItem,
          }));
        });

        fakeApiData.sum.unshift({
          label: formatMessage({
            id: 'app.common.count',
            defaultMessage: 'count',
          }),
          value: null,
        });

        setApiData(fakeApiData);
      }
    },
  }); // use -> homes/listFieldsByTypes

  const [activeFilters, setActiveFilters] = useState();

  const handleChange = (field, val, isMulti) => {
    const nFilters = {
      ...activeFilters,
    };

    const apiVal = isMulti
      ? val?.map((item) => item.value).join(',')
      : val?.value;
    if (apiVal) {
      nFilters[field] = apiVal;
    } else if (nFilters[field]) {
      delete nFilters[field];
    }

    setActiveFilters(nFilters);
    onChange(nFilters);
  };

  useEffect(() => {
    if (!apiLoading && !apiError && apiData && !activeFilters) {
      const nFilters = {};
      filters.forEach((item) => {
        if (item.required && apiData[item.id]?.length > 0) {
          const [firstItem] = apiData[item.id];

          if (firstItem?.value) {
            nFilters[item.apiKey] = firstItem.value;
          }
        }
      });

      setActiveFilters(nFilters);
      onChange(nFilters);
    }
  }, [apiLoading, apiError, apiData, activeFilters, onChange]);

  return (
    <>
      {(apiLoading || apiError) && (
        <Col sm="auto" className="mb-2 mb-md-0">
          <RequestLoading loading={apiLoading} margin="0" />
          <RequestResult
            type="secondary"
            message={apiError && 'app.common.filtersNotLoaded'}
            className="m-0 px-2 py-1"
          />
        </Col>
      )}

      {!apiLoading && !apiError && apiData && (
        <>
          {filters.map(({ id, labelKey, apiKey, isMulti, required }) =>
            apiData[id]?.length > 0 ? (
              <Col key={id} sm="auto" className="mb-2">
                <Row className="align-items-center gx-0 ">
                  <Col>
                    <span className="text-secondary me-2">
                      <FormattedMessage id={`app.common.${labelKey}`} />
                    </span>
                  </Col>
                  <Col xs="auto">
                    <FieldSelect
                      options={apiData[id]}
                      loading={apiLoading}
                      error={apiError}
                      onChange={(val) => {
                        handleChange(apiKey, val, isMulti);
                      }}
                      isMulti={isMulti}
                      required={required === true}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </Col>
            ) : null
          )}
        </>
      )}
    </>
  );
}

ChartFilters.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ChartFilters.defaultProps = {
  onChange: () => {},
  disabled: false,
};

export default ChartFilters;
