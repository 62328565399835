import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';
import { yup } from '../../../lib';
import { SmartForm } from '../../../components';

function Form() {
  const { id } = useParams();

  /* const fields = [
    {
      cols: [
        {
          key: 'name',
          schema: yup.string().required(),
        },
        {
          key: 'email',
          schema: yup.string().email().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'password',
          type: 'password',
          schema: yup.string().min(6).required(),
        },
        {
          key: 'passwordConfirmation',
          type: 'password',
          schema: yup
            .string()
            .min(6)
            .oneOf([yup.ref('password')], {
              key: 'app.common.passwordConfirmationNotMatchError',
            })
            .required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'notification',
          type: 'switch',
          label: <FormattedMessage id="app.common.allowNotifications" />,
          schema: yup.boolean().oneOf([true], {
            key: 'app.common.allowNotifications',
          }),
        },
        {
          key: 'summary',
          type: 'textarea',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'terms',
          type: 'checkbox',
          label: <FormattedMessage id="app.common.acceptTerms" />,
          schema: yup.boolean().oneOf([true], {
            key: 'app.common.acceptTermsError',
          }),
        },
        {
          key: 'selectProperty',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: [
                  { label: 'x', value: 'x' },
                  { label: 'y', value: 'y' },
                ],
                isSearchable: false,
              },
            },
          },
          schema: yup.object().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'color',
          type: 'color',
          schema: yup.string().required(),
        },
        {
          key: 'file',
          type: 'file',
          schema: yup
            .object()
            .shape({
              file: yup.mixed().required(),
            })
            .nullable(),
        },
      ],
    },
  ]; */

  const fields = [
    {
      cols: [
        {
          key: 'color',
          type: 'color',
          schema: yup.string(),
        },
        {
          key: 'asd',
          schema: yup.string().required(),
        },
        {
          key: 'file',
          type: 'file',
          options: {
            controller: {
              props: {
                multiple: true,
              },
            },
          },
          schema: yup.mixed().requiredFile(),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    console.log(data);
  };

  const onRequestError = (err) => {
    console.log(err);
  };

  return (
    <Row>
      <Col xs="12">
        <SmartForm
          fields={fields}
          requestUrl="/test/update"
          requestParams={{ id }}
          // defaultValues={{ summary: 'initial value example' }}
          onRequestSuccess={onRequestSuccess}
          onRequestError={onRequestError}
          /* onSubmit={(data) => {
            console.log(data);
          }} */
        />
      </Col>
    </Row>
  );
}

export default Form;
