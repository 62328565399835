import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useEffectOnce } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { axiosApiInstance } from '../../lib';
import { authBasePath } from '../../router/routeMapping';
import { RequestResult, SmartImage } from '../../components';

import EmailVerificationImage from '../../assets/images/svg/illustrations/oc-email-verification.svg';
import EmailVerificationImageLight from '../../assets/images/svg/illustrations-light/oc-email-verification.svg';

function VerifyEmail() {
  const source = axios.CancelToken.source();

  const [email] = useState('asd@asd.com');
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const resend = () => {
    setRequestError(null);
    setRequestLoading(true);

    axiosApiInstance
      .post(
        '/user/resend-verification',
        qs.stringify({
          email,
        }),
        {
          cancelToken: source.token,
        }
      )
      .then(({ data }) => {
        setRequestLoading(false);
        if (data.status) {
          console.log(data);
        } else {
          setRequestError(`errors.api.${data.Message}`);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setRequestError(`errors.network.${err.message}`);
          setRequestLoading(false);
        }
      });
  };

  useEffectOnce(() => () => {
    source.cancel('Component got unmounted');
  });

  return (
    <div
      className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
      style={{ maxWidth: '25rem' }}
    >
      <div className="text-center">
        <div className="mb-4">
          <SmartImage
            className="avatar avatar-xxl avatar-4x3"
            src={EmailVerificationImage}
            darkSrc={EmailVerificationImageLight}
          />
        </div>

        <h1 className="display-5">
          <FormattedMessage id="app.common.verifyYourEmail" />
        </h1>

        <RequestResult
          type="error"
          message={requestError}
          className="mb-5 mt-5 text-start"
        />

        <p className="mb-1">
          <FormattedMessage id="app.auth.verifyEmail.sentEmailInfo" />
        </p>

        <span className="d-block text-dark fw-semi-bold mb-1">{email}</span>

        <p>
          <FormattedMessage id="app.auth.verifyEmail.followLinkInfo" />
        </p>

        <div className="mt-4 mb-3">
          <Button as={Link} to={authBasePath} variant="primary">
            <FormattedMessage id="app.common.signIn" />
          </Button>
        </div>

        <p className="d-flex justify-content-center align-items-center">
          <FormattedMessage id="app.common.didntReceiveAnEmail" />
          <Button
            variant=""
            className="ms-1 p-0 fw-normal"
            disabled={requestLoading}
            onClick={resend}
          >
            {requestLoading ? (
              <>
                <Spinner animation="border" size="sm" className="me-1" />
                <FormattedMessage id="app.common.loading" />
              </>
            ) : (
              <FormattedMessage id="app.common.resend" />
            )}
          </Button>
        </p>
      </div>
    </div>
  );
}

export default VerifyEmail;
