import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import moment from 'moment';
import Picker from 'react-flatpickr';
import Constants from '../constants';

function Flatpickr({
  disabled,
  value,
  onChange,
  isInvalid,
  isValid,
  size,
  // defaultValue,
  isModal,
  options,
  placeholder,
  formatter,
}) {
  const pickerRef = useRef();

  return (
    <InputGroup
      className={`input-group-merge ${isInvalid ? 'is-invalid' : ''} ${
        isValid ? 'is-valid' : ''
      } ${isModal ? 'overflow-visible' : ''}`}
    >
      <Picker
        ref={pickerRef}
        className={`form-control form-control-${size} flatpickr-custom`}
        options={{
          static: isModal,
          nextArrow: '<i class="bi-chevron-right flatpickr-custom-arrow"></i>',
          prevArrow: '<i class="bi-chevron-left flatpickr-custom-arrow"></i>',
          dateFormat: Constants.DateFormats.APP.Flatpickr.Common,
          // defaultDate: moment(defaultValue).toDate(),
          clickOpens: !disabled,
          ...options?.controller?.options,
        }}
        value={value ? moment(value).toDate() : null}
        placeholder={placeholder}
        onChange={([date]) => {
          onChange(formatter(date));
        }}
      />
      {value && (
        <InputGroup.Text
          as="button"
          type="button"
          className="input-group-append"
          disabled={disabled}
          onClick={() => {
            onChange(null);
            if (!pickerRef?.current?.flatpickr) return;
            pickerRef.current.flatpickr.clear(false, false);
          }}
        >
          <i className="bi-x-lg" />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
}

Flatpickr.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  /* defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]), */
  isModal: PropTypes.bool,
  options: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  formatter: PropTypes.func,
};

Flatpickr.defaultProps = {
  disabled: false,
  value: undefined,
  onChange: () => {},
  isInvalid: null,
  isValid: null,
  size: 'sm',
  // defaultValue: null,
  isModal: false,
  options: {},
  placeholder: '',
  formatter: (v) => v,
};

export default Flatpickr;
