import React, {
  useState,
  createContext,
  useMemo,
  useReducer,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import { Logo } from '../components';
import { useAxiosQuery } from '../hooks';

const DashboardContext = createContext();

const initialState = {
  params: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PARAMS':
      return {
        ...state,
        params: action.payload.params,
      };
    default:
      return state;
  }
};

function DashboardProvider({
  children,
  requestUrl,
  requestParams,
  requiredParam,
  parametersParam,
  forcePath,
  forcePathMessage,
  returnPath,
  allowedPaths,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const location = useLocation();
  const [hasAccess, setHasAccess] = useState(!(forcePath && requiredParam));

  const updateParams = useCallback(
    (params) => {
      dispatch({
        type: 'UPDATE_PARAMS',
        payload: {
          params,
        },
      });
    },
    [dispatch]
  );

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: requestUrl,
    params: requestParams,
    preventFetch: !requestUrl,
    onSuccess: (data) => {
      if (
        forcePath &&
        requiredParam &&
        Object.keys(data[requiredParam]).length > 0
      ) {
        setHasAccess(false);
      } else {
        setHasAccess(true);
      }

      if (parametersParam) {
        updateParams(data[parametersParam]);
      }
    },
  });

  const value = useMemo(
    () => ({
      hasAccess,
      params: state.params,
      updateParams,
    }),
    [hasAccess, state.params, updateParams]
  );

  const isAllowedPath = () => {
    let allowed = false;
    if (!requiredParam) {
      allowed = true;
    }
    if (!hasAccess) {
      const founded = [...allowedPaths, forcePath].find(
        (path) => path === location.pathname
      );

      if (founded) {
        allowed = true;
      }
    } else if (location.pathname !== forcePath) {
      allowed = true;
    }

    return allowed;
  };

  return (
    <DashboardContext.Provider value={value}>
      {(apiError || apiLoading) && (
        <Row className="justify-content-lg-center mt-5">
          <Col lg="4">
            <div className="d-flex justify-content-center mb-5">
              <Logo
                className="w-100"
                style={{ minWidth: '10rem', maxWidth: '10rem' }}
              />
            </div>
            {apiError && (
              <Alert variant="danger" className="mb-5">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bi-exclamation-triangle-fill" />
                  </div>
                  <div className="flex-grow-1 ms-2">{apiError}</div>
                </div>
              </Alert>
            )}
            {apiLoading && (
              <div className="text-center mt-5">
                <Spinner animation="border" size="lg" />
              </div>
            )}
          </Col>
        </Row>
      )}

      {!apiLoading && !apiError && (
        <>
          {forcePath !== location.pathname &&
            !hasAccess &&
            forcePathMessage && (
              <Alert variant="danger" className="rounded-0 mb-0">
                <div className="container d-flex align-items-center my-3">
                  <div className="flex-shrink-0">
                    <i className="bi-exclamation-triangle-fill fs-1" />
                  </div>
                  <div className="flex-grow-1 ms-2">{forcePathMessage}</div>
                </div>
              </Alert>
            )}
          {isAllowedPath() && children}
          {forcePath &&
            forcePath === location.pathname &&
            hasAccess &&
            returnPath && <Navigate to={returnPath} replace />}
          {forcePath &&
            forcePath !== location.pathname &&
            !hasAccess &&
            !isAllowedPath() && <Navigate to={forcePath} replace />}
        </>
      )}
    </DashboardContext.Provider>
  );
}

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  requestUrl: PropTypes.string,
  requestParams: PropTypes.objectOf(PropTypes.any),
  requiredParam: PropTypes.string,
  parametersParam: PropTypes.string,
  forcePath: PropTypes.string,
  forcePathMessage: PropTypes.node,
  returnPath: PropTypes.string,
  allowedPaths: PropTypes.arrayOf(PropTypes.string.isRequired),
};

DashboardProvider.defaultProps = {
  requestUrl: null,
  requestParams: {},
  requiredParam: null,
  parametersParam: null,
  forcePath: null,
  forcePathMessage: null,
  returnPath: null,
  allowedPaths: [],
};

export { DashboardContext, DashboardProvider };
