import Utils from '../../../../utils';

export const EDITOR_PROPERTIES = [
  'memory',
  'eventBus',
  'keyManager',
  'moveableData',
  'moveableManager',
  'historyManager',
  'getViewport',
  'getSelecto',
  'getEditorElement',
  'getSelectedTargets',
  'selectMenu',
  'getSelectedFrames',
];
export const PREFIX = 'floorplan-';
export const SCENA_LAYER_SEPARATOR = '//__$__//';
export const DATA_SCENA_ELEMENT_ID = 'data-floorplan-element-id';
export const DATA_SCENA_ELEMENT = 'data-floorplan-element';

export const TYPE_SCENA_LAYERS = 'application/x-floorplan-layers';

export const RULER_SIZE = 30;
export const DEFAULT_VIEWPORT_WIDTH = 2000;
export const DEFAULT_VIEWPORT_HEIGHT = 2000;

export const ZOOM_MIN = 0.1;
export const ZOOM_MAX = 5;
export const ZOOM_STEP = 0.1;

export const ALLOW_DROP_FILES = false;

export const LAYER_TYPES = {
  bg: 'bg',
  booth: 'booth',
  carpet: 'carpet',
  otherSpace: 'otherSpace',
  blockSpace: 'blockSpace',
  icon: 'icon',
  rectangle: 'rectangle',
  circle: 'circle',
  text: 'text',
};

export const BOOTH_STATUS_TYPES = {
  avail: 'avail',
  onHold: 'onHold',
  sold: 'sold',
  proposal: 'proposal',
  contract: 'contract',
};

export const DEFAULT_BOOTH_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_BOOTH_COLORS = {
  avail: {
    background: Utils.Color.getRGBAColorFromHex('#aaaaaa', 0.75),
    text: '#ffffff',
  },
  onHold: {
    background: Utils.Color.getRGBAColorFromHex('#d6c89d', 0.75),
    text: '#ffffff',
  },
  sold: {
    background: Utils.Color.getRGBAColorFromHex('#40a3cb', 0.75),
    text: '#ffffff',
  },
  proposal: {
    background: Utils.Color.getRGBAColorFromHex('#2ecaca', 0.75),
    text: '#e8f011',
  },
  contract: {
    background: Utils.Color.getRGBAColorFromHex('#c6529e', 0.75),
    text: '#04093d',
  },
};

export const DEFAULT_BOOTH_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_BOOTH_COLORS.avail.background,
  width: `${DEFAULT_BOOTH_SIZE.width}px`,
  height: `${DEFAULT_BOOTH_SIZE.height}px`,
  left: 0,
  top: 0,
};

export const DEFAULT_BOOTH_CHILD_STYLE = {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: DEFAULT_BOOTH_COLORS.avail.text,
  userSelect: 'none',
  textAlign: 'center',
};

export const DEFAULT_CARPET_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_CARPET_COLORS = {
  background: '#d71649',
  text: '#ffffff',
};

export const DEFAULT_CARPET_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_CARPET_COLORS.background,
  width: `${DEFAULT_CARPET_SIZE.width}px`,
  height: `${DEFAULT_CARPET_SIZE.height}px`,
  left: 0,
  top: 0,
};

export const DEFAULT_CARPET_CHILD_STYLE = {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: DEFAULT_CARPET_COLORS.text,
  userSelect: 'none',
  textAlign: 'center',
};

export const DEFAULT_ICON_SIZE = {
  width: 5,
  height: 5,
};

export const DEFAULT_ICON_COLORS = {
  fill: '#48484A',
};

export const DEFAULT_ICON_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  width: `${DEFAULT_ICON_SIZE.width}px`,
  height: `${DEFAULT_ICON_SIZE.height}px`,
  left: 0,
  top: 0,
  fill: DEFAULT_ICON_COLORS.fill,
};

export const DEFAULT_OTHER_SPACE_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_OTHER_SPACE_COLORS = {
  background: '#00c9a7',
  text: '#ffffff',
};

export const DEFAULT_OTHER_SPACE_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_OTHER_SPACE_COLORS.background,
  width: `${DEFAULT_OTHER_SPACE_SIZE.width}px`,
  height: `${DEFAULT_OTHER_SPACE_SIZE.height}px`,
  left: 0,
  top: 0,
};

export const DEFAULT_OTHER_SPACE_CHILD_STYLE = {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: DEFAULT_OTHER_SPACE_COLORS.text,
  userSelect: 'none',
  textAlign: 'center',
};

export const DEFAULT_BLOCK_SPACE_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_BLOCK_SPACE_COLORS = {
  background: Utils.Color.getRGBAColorFromHex('#ed4c78', 0.75),
  text: '#ffffff',
};

export const DEFAULT_BLOCK_SPACE_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_BLOCK_SPACE_COLORS.background,
  width: `${DEFAULT_BLOCK_SPACE_SIZE.width}px`,
  height: `${DEFAULT_BLOCK_SPACE_SIZE.height}px`,
  left: 0,
  top: 0,
};

export const DEFAULT_BLOCK_SPACE_CHILD_STYLE = {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: DEFAULT_BLOCK_SPACE_COLORS.text,
  userSelect: 'none',
  textAlign: 'center',
};

export const DEFAULT_RECTANGLE_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_RECTANGLE_COLORS = {
  background: '#cccccc',
};

export const DEFAULT_RECTANGLE_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_RECTANGLE_COLORS.background,
  width: `${DEFAULT_RECTANGLE_SIZE.width}px`,
  height: `${DEFAULT_RECTANGLE_SIZE.height}px`,
  left: 0,
  top: 0,
};

export const DEFAULT_CIRCLE_SIZE = {
  width: 10,
  height: 10,
};

export const DEFAULT_CIRCLE_COLORS = {
  background: '#cccccc',
};

export const DEFAULT_CIRCLE_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  backgroundColor: DEFAULT_CIRCLE_COLORS.background,
  width: `${DEFAULT_CIRCLE_SIZE.width}px`,
  height: `${DEFAULT_CIRCLE_SIZE.height}px`,
  left: 0,
  top: 0,
  borderRadius: '50%',
};

export const DEFAULT_TEXT_SIZE = {
  width: 20,
  height: 5,
};

export const DEFAULT_TEXT_COLORS = {
  text: '#000000',
};

export const DEFAULT_TEXT_STYLE = {
  boxSizing: 'border-box',
  position: 'absolute',
  width: `${DEFAULT_TEXT_SIZE.width}px`,
  height: `${DEFAULT_TEXT_SIZE.height}px`,
  left: 0,
  top: 0,
  overflow: 'hidden',
  color: DEFAULT_TEXT_COLORS.text,
  textAlign: 'left',
};

export const DEFAULT_TEXT_EMPTY_VALUE = 'emptyText';
