import React from 'react';

import { Offcanvas, Image, Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

import AvatarImg3 from '../../../assets/images/temp-content/img/160x160/img3.jpg';
import AvatarImg5 from '../../../assets/images/temp-content/img/160x160/img5.jpg';
import AvatarImg7 from '../../../assets/images/temp-content/img/160x160/img7.jpg';
import AvatarImg9 from '../../../assets/images/temp-content/img/160x160/img9.jpg';

import ExcelIcon from '../../../assets/images/svg/brands/excel-icon.svg';
import WordIcon from '../../../assets/images/svg/brands/word-icon.svg';

import Card1 from '../../../assets/images/temp-content/svg/components/card-1.svg';
import Card2 from '../../../assets/images/temp-content/svg/components/card-2.svg';
import Card3 from '../../../assets/images/temp-content/svg/components/card-3.svg';

function OffcanvasActivity({ show, onHide }) {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title as="h4" className="mb-0">
          Activity stream
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <!-- Step --> */}
        <ul className="step step-icon-sm step-avatar-sm">
          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <div className="step-avatar">
                <Image className="step-avatar-img" src={AvatarImg9} />
              </div>

              <div className="step-content">
                <h5 className="mb-1">Iana Robinson</h5>

                <p className="fs-5 mb-1">
                  Added 2 files to task{' '}
                  <a className="text-uppercase" href="#!">
                    <i className="bi-journal-bookmark-fill" /> Fd-7
                  </a>
                </p>

                <ul className="list-group list-group-sm">
                  {/* <!-- List Item --> */}
                  <li className="list-group-item list-group-item-light">
                    <div className="row gx-1">
                      <div className="col-6">
                        {/* <!-- Media --> */}
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <Image
                              className="avatar avatar-xs"
                              src={ExcelIcon}
                            />
                          </div>
                          <div className="flex-grow-1 text-truncate ms-2">
                            <span
                              className="d-block fs-6 text-dark text-truncate"
                              title="weekly-reports.xls"
                            >
                              weekly-reports.xls
                            </span>
                            <span className="d-block small text-muted">
                              12kb
                            </span>
                          </div>
                        </div>
                        {/* <!-- End Media --> */}
                      </div>
                      {/* <!-- End Col --> */}

                      <div className="col-6">
                        {/* <!-- Media --> */}
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <Image
                              className="avatar avatar-xs"
                              src={WordIcon}
                            />
                          </div>
                          <div className="flex-grow-1 text-truncate ms-2">
                            <span
                              className="d-block fs-6 text-dark text-truncate"
                              title="weekly-reports.xls"
                            >
                              weekly-reports.xls
                            </span>
                            <span className="d-block small text-muted">
                              4kb
                            </span>
                          </div>
                        </div>
                        {/* <!-- End Media --> */}
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Row --> */}
                  </li>
                  {/* <!-- End List Item --> */}
                </ul>

                <span className="small text-muted text-uppercase">Now</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <span className="step-icon step-icon-soft-dark">B</span>

              <div className="step-content">
                <h5 className="mb-1">Bob Dean</h5>

                <p className="fs-5 mb-1">
                  Marked{' '}
                  <a className="text-uppercase" href="#!">
                    <i className="bi-journal-bookmark-fill" /> Fr-6
                  </a>{' '}
                  as{' '}
                  <span className="badge bg-soft-success text-success rounded-pill">
                    <span className="legend-indicator bg-success" />
                    &quot;Completed&quot;
                  </span>
                </p>

                <span className="small text-muted text-uppercase">Today</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <div className="step-avatar">
                <Image className="step-avatar-img" src={AvatarImg3} />
              </div>

              <div className="step-content">
                <h5 className="h5 mb-1">Crane</h5>

                <p className="fs-5 mb-1">
                  Added 5 card to <a href="#!">Payments</a>
                </p>

                <ul className="list-group list-group-sm">
                  <li className="list-group-item list-group-item-light">
                    <div className="row gx-1">
                      <div className="col">
                        <Image fluid rounded src={Card1} />
                      </div>
                      <div className="col">
                        <Image fluid rounded src={Card2} />
                      </div>
                      <div className="col">
                        <Image fluid rounded src={Card3} />
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="text-center">
                          <a href="#!">+2</a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <span className="small text-muted text-uppercase">May 12</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <span className="step-icon step-icon-soft-info">D</span>

              <div className="step-content">
                <h5 className="mb-1">David Lidell</h5>

                <p className="fs-5 mb-1">
                  Added a new member to Front Dashboard
                </p>

                <span className="small text-muted text-uppercase">May 15</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <div className="step-avatar">
                <Image className="step-avatar-img" src={AvatarImg7} />
              </div>

              <div className="step-content">
                <h5 className="mb-1">Rachel King</h5>

                <p className="fs-5 mb-1">
                  Marked{' '}
                  <a className="text-uppercase" href="#!">
                    <i className="bi-journal-bookmark-fill" /> Fr-3
                  </a>{' '}
                  as{' '}
                  <span className="badge bg-soft-success text-success rounded-pill">
                    <span className="legend-indicator bg-success" />
                    &quot;Completed&quot;
                  </span>
                </p>

                <span className="small text-muted text-uppercase">Apr 29</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <div className="step-avatar">
                <Image className="step-avatar-img" src={AvatarImg5} />
              </div>

              <div className="step-content">
                <h5 className="mb-1">Finch Hoot</h5>

                <p className="fs-5 mb-1">
                  Earned a &quot;Top endorsed&quot;{' '}
                  <i className="bi-patch-check-fill text-primary" /> badge
                </p>

                <span className="small text-muted text-uppercase">Apr 06</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}

          {/* <!-- Step Item --> */}
          <li className="step-item">
            <div className="step-content-wrapper">
              <span className="step-icon step-icon-soft-primary">
                <i className="bi-person-fill" />
              </span>

              <div className="step-content">
                <h5 className="mb-1">Project status updated</h5>

                <p className="fs-5 mb-1">
                  Marked{' '}
                  <a className="text-uppercase" href="#!">
                    <i className="bi-journal-bookmark-fill" /> Fr-3
                  </a>{' '}
                  as{' '}
                  <span className="badge bg-soft-primary text-primary rounded-pill">
                    <span className="legend-indicator bg-primary" />
                    &quot;In progress&quot;
                  </span>
                </p>

                <span className="small text-muted text-uppercase">Feb 10</span>
              </div>
            </div>
          </li>
          {/* <!-- End Step Item --> */}
        </ul>
        {/* <!-- End Step --> */}

        <div className="d-grid">
          <Button variant="white">
            View all <i className="bi-chevron-right" />
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

OffcanvasActivity.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

OffcanvasActivity.defaultProps = {
  show: false,
  onHide: () => {},
};

export default OffcanvasActivity;
