import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

function TogglePassword({ hiddenValue, value, maxWidth }) {
  const [visibility, setVisibility] = useState(false);

  return (
    <div className="d-flex justify-content-between w-100" style={{ maxWidth }}>
      <span className="me-1">{visibility ? value : hiddenValue}</span>
      <Button
        variant="ghost"
        size="sm"
        className="py-0"
        onClick={() => {
          setVisibility(!visibility);
        }}
      >
        <i className={visibility ? 'bi bi-eye-slash' : 'bi bi-eye'} />
      </Button>
    </div>
  );
}

TogglePassword.propTypes = {
  hiddenValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.number,
};

TogglePassword.defaultProps = {
  hiddenValue: '*****',
  value: null,
  maxWidth: null,
};

export default TogglePassword;
