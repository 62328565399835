import React from 'react';
import { useParams } from 'react-router-dom';
import { SmartGallery } from '../../../components';

// const baseURL = process.env.REACT_APP_BASE_URL || '';

function Gallery() {
  const { id } = useParams();

  return (
    <SmartGallery
      paths={{
        list: '/images/list',
        listParams: { RefID: id },
        create: '/images/insert',
        createParams: { RefID: id },
        delete: '/images/delete',
      }}
      params={{ RefTable: 'Exhibitors' }}
    />
  );
}

export default Gallery;
