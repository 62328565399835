// import React from 'react';
import { Button } from 'react-bootstrap';
import { getRad } from '@daybrush/utils';
import { prefix } from '../../utils/utils';

const DIRECTIONS4 = ['n', 'w', 's', 'e'];
export const DIRECTION_INDEXES = {
  n: [0, 1],
  e: [1, 3],
  s: [3, 2],
  w: [2, 0],
  nw: [0],
  ne: [1],
  sw: [2],
  se: [3],
};

const directions = DIRECTIONS4;

const ableName = 'custom';

function getDistSize(vec) {
  return Math.sqrt(vec[0] * vec[0] + vec[1] * vec[1]);
}

function getDiagonalSize(pos1, pos2) {
  return getDistSize([pos2[0] - pos1[0], pos2[1] - pos1[1]]);
}

function getLineStyle(pos1, pos2, zoom = 1, rad = getRad(pos1, pos2)) {
  const width = getDiagonalSize(pos1, pos2);
  return {
    transform: `translate(${pos1[0]}px, ${pos1[1]}px) rotate(${rad}rad) scaleY(${zoom})`,
    width: `${width}px`,
    position: 'absolute',
    left: 0,
    top: 0,
    transformOrigin: 'top left',
    willChange: 'transform',
  };
}

function renderLine(
  React,
  onMouseDown,
  direction,
  pos1,
  pos2,
  zoom,
  shortSize,
  key,
  ...classNames
) {
  const rad = getRad(pos1, pos2);
  // const rotation = direction ? throttle((rad / Math.PI) * 180, 15) % 180 : -1;
  // const realRotation = throttle((rad / Math.PI) * 180, 15);

  /* const getActionType = () => {
    if (realRotation >= 0 && realRotation < 90) {
      console.log('up');
    } else if (realRotation >= 90 && realRotation < 180) {
      console.log('right');
    } else if (realRotation >= 180 && realRotation < 270) {
      console.log('down');
    } else if (realRotation >= 270) {
      console.log('left');
    }
  }; */

  // const width = getDiagonalSize(pos1, pos2);

  let fixScale = 0.005 * (shortSize / zoom);
  if (fixScale > 1) {
    fixScale = 1;
  }

  return (
    <div
      key={`line-${key}`}
      className={`${prefix(
        'line',
        'direction',
        direction ? 'edge' : '',
        direction,
        ...classNames
      )} d-flex align-items-center justify-content-center`}
      style={getLineStyle(pos1, pos2, zoom, rad)}
    >
      <Button
        onMouseDown={() => {
          onMouseDown(direction);
        }}
        style={{
          pointerEvents: 'auto',
          transformOrigin: 'top center',
          transform: `scaleY(${fixScale}) scaleX(${(zoom / 1) * fixScale})`,
        }}
      >
        <i className="bi-chevron-up" />
      </Button>
    </div>
  );
}

export const ControlViewable = {
  name: 'controlViewable',
  props: {
    controlViewable: Boolean,
  },
  events: { onDuplicate: 'duplicate' },
  render(moveable, React) {
    const { zoom } = moveable.props;
    const poses = moveable.getState().renderPoses;
    const { offsetWidth, offsetHeight, rotation, pos1, origin } =
      moveable.getRect();

    const angle = (Math.PI / 180) * rotation;

    const unrotatedX =
      origin[0] +
      Math.cos(-angle) * (pos1[0] - origin[0]) -
      Math.sin(-angle) * (pos1[1] - origin[1]);
    const unrotatedY =
      origin[1] +
      Math.sin(-angle) * (pos1[0] - origin[0]) +
      Math.cos(-angle) * (pos1[1] - origin[1]);

    const onMouseDown = (direction) => {
      const centerX = unrotatedX + offsetWidth / 2;
      const centerY = unrotatedY + offsetHeight / 2;
      let left;
      let top;

      switch (direction) {
        case 'e':
          left = centerX + offsetWidth * Math.cos(angle) - offsetWidth / 2;
          top = centerY + offsetWidth * Math.sin(angle) - offsetHeight / 2;
          break;

        case 'w':
          left = centerX - offsetWidth * Math.cos(angle) - offsetWidth / 2;
          top = centerY - offsetWidth * Math.sin(angle) - offsetHeight / 2;
          break;

        case 'n':
          left = centerX + offsetHeight * Math.sin(angle) - offsetWidth / 2;
          top = centerY - offsetHeight * Math.cos(angle) - offsetHeight / 2;
          break;

        case 's':
          left = centerX - offsetHeight * Math.sin(angle) - offsetWidth / 2;
          top = centerY + offsetHeight * Math.cos(angle) - offsetHeight / 2;
          break;

        default:
          break;
      }

      const style = {
        width: offsetWidth,
        height: offsetHeight,
        transform: `translate(${left}px, ${top}px) rotate(${rotation}deg)`,
      };

      moveable.triggerEvent('onDuplicate', {
        moveable,
        style,
        type: moveable.getTargets()[0].dataset.type,
      });
    };

    const shortSize = Math.min(offsetWidth, offsetHeight);

    return (
      <div
        key="control-viewer"
        className="position-absolute start-0 top-0"
        style={{ pointerEvents: 'none' }}
      >
        {directions
          .map((direction, i) => {
            const [index1, index2] = DIRECTION_INDEXES[direction];

            if (index2 == null) {
              return null;
            }

            return renderLine(
              React,
              onMouseDown,
              direction,
              poses[index1],
              poses[index2],
              zoom,
              shortSize,
              `${ableName}Duplicate${i}`,
              ableName
            );
          })
          .filter(Boolean)}
      </div>
    );
  },
};
