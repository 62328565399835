import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const SmartWizard = forwardRef(({ steps, withStepInfo }, ref) => {
  const [activeStep, setActiveStep] = useState(0);
  const [unlockedSteps, setUnlockedSteps] = useState([0]);

  const handleStepChange = (key) => {
    setActiveStep(Number(key));
    const unlockItems = [];
    steps.forEach((item, i) => {
      if (i < Number(key)) {
        unlockItems.push(i);
      }
    });
    setUnlockedSteps(unlockItems);
    // setError(null);
  };

  const getUnlockedStatus = (key) => unlockedSteps.indexOf(key) > -1;

  const getStepIcon = (step) =>
    getUnlockedStatus(step) ? (
      <i className="bi-check-circle text-primary me-2" />
    ) : null;

  const getStepInfo = (step) => (withStepInfo ? step.titleKey : null);

  const goToPrevNextStep = (dir) => {
    let nextIndex = activeStep + 1;
    if (dir === 'prev') {
      nextIndex = activeStep - 1;
    }

    const nextStep = steps[nextIndex];
    if (nextStep) {
      handleStepChange(nextIndex);
    }
  };

  useImperativeHandle(ref, () => ({
    next: () => {
      goToPrevNextStep('next');
    },
    prev: () => {
      goToPrevNextStep('prev');
    },
  }));

  return (
    <div>
      <Tab.Container activeKey={activeStep} onSelect={handleStepChange}>
        <ul className="step step-md step-icon-xs step-centered">
          {steps.map((step, index) => (
            <li
              key={`${index.toString()}`}
              className={`step-item ${
                activeStep.toString() === index.toString() ? 'active focus' : ''
              }`}
            >
              <Nav.Link
                eventKey={index.toString()}
                bsPrefix="step-content-wrapper"
                disabled={!getUnlockedStatus(index)}
              >
                <span className="step-icon step-icon-soft-primary">
                  {index + 1}
                </span>
                <div className="step-content">
                  <h6 className="step-title">
                    {getStepIcon(index)}
                    <FormattedMessage id={`app.common.${step.titleKey}`} />
                  </h6>
                  <div>{getStepInfo(index)}</div>
                </div>
              </Nav.Link>
            </li>
          ))}
        </ul>

        <Row>
          <Col lg={steps[activeStep].size}>
            <Tab.Content>
              {steps.map(({ Component }, index) => (
                <Tab.Pane
                  key={`${index.toString()}`}
                  eventKey={index.toString()}
                >
                  {Component}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
});

SmartWizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  withStepInfo: PropTypes.bool,
};

SmartWizard.defaultProps = {
  withStepInfo: false,
};

export default SmartWizard;
