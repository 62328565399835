import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Nav, Button } from 'react-bootstrap';
import { useStoreStateValue } from '@scena/react-store';
import { FormattedMessage } from 'react-intl';
import { $layerManager, $selectedFlattenLayers } from '../../stores/stores';
import { useAction } from '../../hooks/useAction';
import {
  BOOTH_STATUS_TYPES,
  DEFAULT_BOOTH_COLORS,
  LAYER_TYPES,
} from '../../consts';
import Utils from '../../../../../../utils';

function DataSection({ onChange }) {
  useAction('render.end');

  const layerManager = useStoreStateValue($layerManager);
  const selectedLayers = useStoreStateValue($selectedFlattenLayers);

  const changeBoothStatus = (status) => {
    const backgroundColor = DEFAULT_BOOTH_COLORS[status].background;
    onChange({
      properties: [{ property: 'backgroundColor', value: backgroundColor }],
      datas: [{ property: 'status', value: status }],
    });
  };

  const changeLayerColor = (type, value) => {
    onChange({ properties: [{ property: type, value }] });
  };

  const changeLayerLock = (value) => {
    onChange({ others: [{ property: 'lock', value }] });
  };

  const changeLayerTitle = (e) => {
    onChange({ others: [{ property: 'title', value: e.target.value }] });
  };

  /* const onIdChange = (id, value) => {
    selectedLayers[0].clientId = value;
    const selectedLayer = selectedLayers[0];
    selectedLayer.clientId = value;

    const nextLayers = [...layers].map((layer) => {
      const nLayer = layer;
      if (nLayer.id === id) {
        nLayer.clientId = value;
        // nLayer.jsx.props.clientId = value;

        const idCont = nLayer.ref.current.querySelector('.id-container');
        console.log(idCont);
        idCont.textContent = value;
        return nLayer;
      }

      return nLayer;
    });

    editorRef.current.setLayers(nextLayers);

    actionManager.requestAct('render.end');
  }; */

  if (selectedLayers.length === 0 || selectedLayers.length > 1) {
    return null;
  }

  const selectedLayer = selectedLayers[0];
  const layerCSS = layerManager.getFrame(selectedLayer).toCSSObject();

  return (
    <Row>
      <Col xs="12">
        <Row>
          <Col xs="12" className="mb-3">
            <div className="d-grid">
              <Button
                size="sm"
                variant={selectedLayer.lock ? 'danger' : 'primary'}
                className=" d-block"
                onClick={() => {
                  changeLayerLock(!selectedLayer.lock);
                }}
              >
                <i
                  className={`${
                    selectedLayer.lock ? 'bi-lock' : 'bi-unlock'
                  } me-1`}
                />
                <FormattedMessage
                  id={`app.common.${
                    selectedLayer.lock ? 'unlockLayer' : 'lockLayer'
                  }`}
                />
              </Button>
            </div>
          </Col>
          {selectedLayer.title !== undefined && (
            <Col xs="12" className="mb-3">
              <Form.Label>
                <FormattedMessage id="app.common.title" />
              </Form.Label>
              <Form.Control
                size="sm"
                value={selectedLayer.title}
                onChange={changeLayerTitle}
                disabled={selectedLayer.lock}
              />
            </Col>
          )}
          {selectedLayer.fpeData?.status && (
            <Col xs="12" className="mb-3">
              <Form.Label className="d-block">
                <FormattedMessage id="app.common.status" />
              </Form.Label>
              <Nav
                variant="segment"
                className="nav-sm"
                activeKey={selectedLayer.fpeData?.status}
                onSelect={changeBoothStatus}
              >
                {Object.keys(BOOTH_STATUS_TYPES).map((key) => (
                  <Nav.Item key={key}>
                    <Nav.Link eventKey={key} disabled={selectedLayer.lock}>
                      <FormattedMessage id={`app.common.${key}`} />
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
          )}
          {!selectedLayer.fpeData?.status &&
            selectedLayer.type !== LAYER_TYPES.text && (
              <Col xs="12" className="mb-3">
                <Form.Label>
                  <FormattedMessage id="app.common.backgroundColor" />
                </Form.Label>
                <Form.Control
                  type="color"
                  value={
                    layerCSS.backgroundColor
                      ? Utils.Color.getHexColorFromRGBA(
                          layerCSS.backgroundColor
                        )
                      : Utils.Color.getHexColorFromRGBA(layerCSS.fill)
                  }
                  onChange={(e) => {
                    const type = layerCSS.backgroundColor
                      ? 'backgroundColor'
                      : 'fill';
                    const value =
                      selectedLayer.type === LAYER_TYPES.blockSpace
                        ? Utils.Color.getRGBAColorFromHex(e.target.value, 0.75)
                        : e.target.value;

                    changeLayerColor(type, value);
                  }}
                  disabled={selectedLayer.lock}
                />
              </Col>
            )}
        </Row>
      </Col>
    </Row>
  );
}

DataSection.propTypes = {
  onChange: PropTypes.func,
};

DataSection.defaultProps = {
  onChange: () => {},
};

export default DataSection;
