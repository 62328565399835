import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteViewer from 'react-infinite-viewer';
import { useStoreStateValue, useStoreValue } from '@scena/react-store';
import { $space } from '../stores/keys';
import {
  $actionManager,
  $horizontalGuides,
  $layerManager,
  $moveable,
  $selectedLayers,
  $selecto,
  $verticalGuides,
  $zoom,
} from '../stores/stores';
import { prefix } from '../utils/utils';

export const InfiniteViewerManager = forwardRef(
  ({ children, style, zoomMin, zoomMax, onZoom }, ref) => {
    const selectoRef = useStoreStateValue($selecto);
    const moveableRef = useStoreStateValue($moveable);
    const horizontalGuidesRef = useStoreStateValue($horizontalGuides);
    const verticalGuidesRef = useStoreStateValue($verticalGuides);
    const actionManager = useStoreStateValue($actionManager);
    const layerManager = useStoreStateValue($layerManager);
    const selectedLayersStore = useStoreValue($selectedLayers);

    const isSpace = useStoreStateValue($space);

    const zoom = useStoreStateValue($zoom);

    return (
      <div className="position-relative" style={style}>
        <InfiniteViewer
          ref={ref}
          className={`${prefix(
            'viewer',
            isSpace ? 'viewer-move' : ''
          )} position-absolute w-100 h-100`}
          usePinch
          // useAutoZoom
          zoom={zoom}
          wheelScale={0.1}
          zoomRange={[zoomMin, zoomMax]}
          useWheelScroll
          useMouseDrag={isSpace}
          pinchThreshold={50}
          maxPinchWheel={3}
          onDragStart={(e) => {
            const { target } = e.inputEvent;
            const flatted = layerManager.toFlattenElement(
              selectedLayersStore.value
            );

            actionManager.act('blur');

            if (
              target.nodeName === 'A' ||
              moveableRef.current?.isMoveableElement(target) ||
              moveableRef.current?.isDragging() ||
              flatted.some((t) => t === target || t.contains(target))
            ) {
              e.stop();
            }
          }}
          onDragEnd={(e) => {
            if (!e.isDrag) {
              selectoRef.current?.clickTarget(e.inputEvent);
            }
          }}
          onAbortPinch={(e) => {
            selectoRef.current?.triggerDragStart(e.inputEvent);
          }}
          onScroll={(e) => {
            const horizontalGuides = horizontalGuidesRef.current;
            const verticalGuides = verticalGuidesRef.current;

            horizontalGuides?.scroll(e.scrollLeft, e.zoomX);
            horizontalGuides?.scrollGuides(e.scrollTop, e.zoomY);

            verticalGuides?.scroll(e.scrollTop, e.zoomX);
            verticalGuides?.scrollGuides(e.scrollLeft, e.zoomY);
          }}
          onPinch={(e) => {
            if (moveableRef.current?.isDragging()) {
              return;
            }
            onZoom(e.zoom);
          }}
        >
          {children}
        </InfiniteViewer>
      </div>
    );
  }
);

InfiniteViewerManager.propTypes = {
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any),
  zoomMin: PropTypes.number,
  zoomMax: PropTypes.number,
  onZoom: PropTypes.func,
};

InfiniteViewerManager.defaultProps = {
  children: null,
  style: {},
  zoomMin: 0.1,
  zoomMax: 5,
  onZoom: () => {},
};

InfiniteViewerManager.displayName = 'InfiniteViewerManager';
