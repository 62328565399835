import printValue from 'yup/lib/util/printValue';

const YupLocale = {
  mixed: {
    default: ({ path }) => ({
      key: 'app.yup.mixed.default',
      values: { path },
    }),
    required: ({ path }) => ({
      key: 'app.yup.mixed.required',
      values: { path },
    }),
    oneOf: ({ path, values }) => ({
      key: 'app.yup.mixed.oneOf',
      values: { path, values },
    }),
    notOneOf: ({ path, values }) => ({
      key: 'app.yup.mixed.notOneOf',
      values: { path, values },
    }),
    notType: ({ path, type, value, originalValue }) => {
      const isCast = originalValue != null && originalValue !== value;

      let key = 'app.yup.mixed.notType';
      const values = {
        path,
        type,
        value: printValue(value, true),
        originalValue,
      };

      if (isCast) {
        key += 'Cast';
        values.originalValue = printValue(originalValue, true);
      }

      if (value === null) {
        key += 'Null';
      }

      return {
        key,
        values,
      };
    },
    defined: ({ path }) => ({
      key: 'app.yup.mixed.defined',
      values: { path },
    }),
  },
  string: {
    length: ({ path, length }) => ({
      key: 'app.yup.string.length',
      values: { path, length },
    }),
    min: ({ path, min }) => ({
      key: 'app.yup.string.min',
      values: { path, min },
    }),
    max: ({ path, max }) => ({
      key: 'app.yup.string.max',
      values: { path, max },
    }),
    matches: ({ path, regex }) => ({
      key: 'app.yup.string.matches',
      values: { path, regex },
    }),
    email: ({ path }) => ({
      key: 'app.yup.string.email',
      values: { path },
    }),
    url: ({ path }) => ({
      key: 'app.yup.string.url',
      values: { path },
    }),
    uuid: ({ path }) => ({
      key: 'app.yup.string.uuid',
      values: { path },
    }),
    trim: ({ path }) => ({
      key: 'app.yup.string.trim',
      values: { path },
    }),
    lowercase: ({ path }) => ({
      key: 'app.yup.string.lowercase',
      values: { path },
    }),
    uppercase: ({ path }) => ({
      key: 'app.yup.string.uppercase',
      values: { path },
    }),
  },
  number: {
    min: ({ path, min }) => ({
      key: 'app.yup.number.min',
      values: { path, min },
    }),
    max: ({ path, max }) => ({
      key: 'app.yup.number.max',
      values: { path, max },
    }),
    lessThan: ({ path, less }) => ({
      key: 'app.yup.number.lessThan',
      values: { path, less },
    }),
    moreThan: ({ path, more }) => ({
      key: 'app.yup.number.moreThan',
      values: { path, more },
    }),
    positive: ({ path }) => ({
      key: 'app.yup.number.positive',
      values: { path },
    }),
    negative: ({ path }) => ({
      key: 'app.yup.number.negative',
      values: { path },
    }),
    integer: ({ path }) => ({
      key: 'app.yup.number.integer',
      values: { path },
    }),
  },
  date: {
    min: ({ path, min }) => ({
      key: 'app.yup.date.min',
      values: { path, min },
    }),
    max: ({ path, max }) => ({
      key: 'app.yup.date.max',
      values: { path, max },
    }),
  },
  boolean: {
    isValue: ({ path, value }) => ({
      key: 'app.yup.boolean.isValue',
      values: { path, value },
    }),
  },
  object: {
    noUnknown: ({ path, unknown }) => ({
      key: 'app.yup.object.noUnknown',
      values: { path, unknown },
    }),
  },
  array: {
    min: ({ path, min }) => ({
      key: 'app.yup.array.min',
      values: { path, min },
    }),
    max: ({ path, max }) => ({
      key: 'app.yup.array.max',
      values: { path, max },
    }),
    length: ({ path, length }) => ({
      key: 'app.yup.array.length',
      values: { path, length },
    }),
  },
};

export default YupLocale;
