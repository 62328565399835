import Brands from './brands';

export const Sites = Object.keys(Brands)
  .filter((key) => !key.includes('yahoo'))
  .reduce(
    (obj, key) =>
      Object.assign(obj, {
        [key]: Brands[key],
      }),
    {}
  );

export const Status = {
  0: { id: 0, labelKey: 'unread', color: 'primary' },
  1: { id: 1, labelKey: 'read', color: 'success' },
};

export const Types = {
  Positive: {
    id: 'Positive',
    apiId: 5,
    labelKey: 'positive',
    color: 'success',
    hexColor: '#00c9a7',
    icon: 'bi-emoji-smile',
  },
  Neutral: {
    id: 'Neutral',
    apiId: 3,
    labelKey: 'neutral',
    color: 'primary',
    hexColor: '#377dff',
    icon: 'bi-emoji-neutral',
  },
  Negative: {
    id: 'Negative',
    apiId: 1,
    labelKey: 'negative',
    color: 'danger',
    hexColor: '#ed4c78',
    icon: 'bi-emoji-frown',
  },
};

export const Priority = {
  High: {
    id: 'High',
    apiId: 5,
    labelKey: 'highPriority',
    color: 'danger',
    hexColor: '#ed4c78',
    icon: 'bi-triangle',
  },
  Medium: {
    id: 'Medium',
    apiId: 3,
    labelKey: 'mediumPriority',
    color: 'info',
    hexColor: '#f5ca99',
    icon: 'bi-circle',
  },
  Low: {
    id: 'Low',
    apiId: 1,
    labelKey: 'lowPriority',
    color: 'primary',
    hexColor: '#377dff',
    icon: 'bi-triangle d-inline-block rotate-180',
  },
};
