import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Nav, Breadcrumb, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { protectedAreaBasePath } from '../router/routeMapping';

function MenuItem({ title, href }) {
  const match = useMatch({ path: href });

  return (
    <Nav.Item>
      <Nav.Link as={Link} active={!!match} to={href}>
        <FormattedMessage id={`app.common.${title}`} defaultMessage={title} />
      </Nav.Link>
    </Nav.Item>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

function PageHeader({
  title,
  menuData,
  renderMenuItem,
  breadcrumbData,
  children,
}) {
  return (
    <div className="page-header">
      <Row className="justify-content-between align-items-center flex-grow-1 mb-3">
        <Col lg className="mb-3 mb-lg-0">
          {breadcrumbData && (
            <Breadcrumb listProps={{ className: 'breadcrumb-no-gutter' }}>
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{
                  className: 'breadcrumb-link',
                  to: protectedAreaBasePath,
                }}
              >
                <FormattedMessage id="app.common.dashboard" />
              </Breadcrumb.Item>

              {breadcrumbData.parents?.map(({ name, href }, index) => (
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{
                    className: 'breadcrumb-link',
                    to: href,
                  }}
                  key={`${index.toString()}`}
                >
                  <FormattedMessage
                    id={`app.common.${name}`}
                    defaultMessage={name}
                  />
                </Breadcrumb.Item>
              ))}

              {breadcrumbData.current && (
                <Breadcrumb.Item
                  linkProps={{ className: 'breadcrumb-link' }}
                  active
                >
                  <FormattedMessage
                    id={`app.common.${breadcrumbData.current}`}
                    defaultMessage={breadcrumbData.current}
                  />
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          )}

          {title && (
            <h1 className="page-header-title">
              <FormattedMessage
                id={`app.common.${title}`}
                defaultMessage={title}
              />
            </h1>
          )}
        </Col>

        <Col xs="auto">{children}</Col>
      </Row>

      {menuData && (
        <div className="js-nav-scroller hs-nav-scroller-horizontal">
          <span
            className="hs-nav-scroller-arrow-prev"
            style={{ display: 'none' }}
          >
            <a className="hs-nav-scroller-arrow-link" href="#!">
              <i className="bi-chevron-left" />
            </a>
          </span>

          <span
            className="hs-nav-scroller-arrow-next"
            style={{ display: 'none' }}
          >
            <a className="hs-nav-scroller-arrow-link" href="#!">
              <i className="bi-chevron-right" />
            </a>
          </span>

          <Nav variant="tabs" className="page-header-tabs">
            {menuData.map((item, index) => {
              if (renderMenuItem) {
                return renderMenuItem(item, index);
              }
              return (
                <MenuItem
                  title={item.title}
                  href={item.href}
                  key={`${index.toString()}`}
                />
              );
            })}
          </Nav>
        </div>
      )}
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  renderMenuItem: PropTypes.func,
  breadcrumbData: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    parents: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, href: PropTypes.string })
    ),
  }),
  children: PropTypes.node,
};

PageHeader.defaultProps = {
  title: '',
  menuData: null,
  renderMenuItem: null,
  breadcrumbData: null,
  children: null,
};

export default PageHeader;
