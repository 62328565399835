import React, { useEffect, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Dropzone from 'dropzone';
import { FormattedMessage } from 'react-intl';
import SmartImage from './SmartImage';
import Illustration from '../assets/images/svg/illustrations/oc-browse.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-browse.svg';

const defaults = {
  // Default variables
  url: 'dummyUploadURL',
  autoProcessQueue: false,
  thumbnailWidth: 300,
  thumbnailHeight: 300,
  previewTemplate:
    '<div class="col-4 mt-3">' +
    '    <div class="dz-preview dz-file-preview h-100 p-2">' +
    '      <div class="d-flex justify-content-end dz-close-icon">' +
    '        <small class="bi-x" data-dz-remove></small>' +
    '      </div>' +
    '      <div class="dz-details d-flex">' +
    '        <div class="dz-img flex-shrink-0">' +
    '         <img class="img-fluid dz-img-inner" data-dz-thumbnail>' +
    '        </div>' +
    '        <div class="dz-file-wrapper flex-grow-1">' +
    '         <h6 class="dz-filename text-wrap">' +
    '          <span class="dz-title text-primary" data-dz-name></span>' +
    '         </h6>' +
    '         <div class="dz-size" data-dz-size></div>' +
    '        </div>' +
    '      </div>' +
    '      <div class="dz-progress progress d-none">' +
    '        <div class="dz-upload progress-bar bg-success" role="progressbar" style="width: 0" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" data-dz-uploadprogress></div>' +
    '      </div>' +
    '      <div class="d-flex align-items-center d-none">' +
    '        <div class="dz-success-mark">' +
    '          <span class="bi-check-lg"></span>' +
    '        </div>' +
    '        <div class="dz-error-mark">' +
    '          <span class="bi-x-lg"></span>' +
    '        </div>' +
    '        <div class="dz-error-message">' +
    '          <small data-dz-errormessage></small>' +
    '        </div>' +
    '      </div>' +
    '    </div>' +
    '</div>',
};

function FormDropzoneInputAlt({
  disabled,
  onChange,
  options,
  isInvalid,
  isValid,
}) {
  const dropzoneDivRef = useRef();
  const dropzoneRef = useRef();

  const onFileListChanged = useCallback(() => {
    onChange(dropzoneRef.current.hiddenFileInput.files);
  }, [onChange]);

  useEffect(() => {
    if (dropzoneDivRef.current && !dropzoneRef.current) {
      dropzoneRef.current = new Dropzone(dropzoneDivRef.current, {
        ...defaults,
        ...options,
      });
      dropzoneRef.current.on('addedfile', onFileListChanged);
      dropzoneRef.current.on('removedfile', onFileListChanged);
    }

    return () => {
      if (dropzoneRef.current) {
        dropzoneRef.current.destroy();
        dropzoneRef.current = null;
      }
    };
  }, [options, onFileListChanged]);

  useEffect(() => {
    if (dropzoneRef.current) {
      if (disabled) {
        dropzoneRef.current.disable();
      } else {
        dropzoneRef.current.enable();
      }
    }
  }, [disabled]);

  return (
    <div
      ref={dropzoneDivRef}
      className={`form-control js-dropzone dz-dropzone dz-dropzone-card row mx-0 ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${disabled ? 'pe-none' : ''}`}
    >
      <div className="dz-message col-12">
        <SmartImage
          className="avatar avatar-xl avatar-4x3 mb-3"
          src={Illustration}
          darkSrc={IllustrationLight}
        />

        <h5>
          <FormattedMessage id="app.common.dragAndDropYourFilesHere" />
        </h5>

        <p className="mb-2">
          <FormattedMessage id="app.common.or" />
        </p>

        <Button
          as="span"
          variant="white"
          size="sm"
          className={disabled ? 'disabled' : ''}
        >
          <FormattedMessage id="app.common.browseFiles" />
        </Button>
      </div>
    </div>
  );
}

FormDropzoneInputAlt.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.object,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
};

FormDropzoneInputAlt.defaultProps = {
  disabled: false,
  onChange: () => {},
  options: {},
  isInvalid: null,
  isValid: null,
};

export default FormDropzoneInputAlt;
