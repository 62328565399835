import { useContext } from 'react';
import { ToastContext } from '../context/ToastContext';

const useToast = ({
  type = 'primary',
  title = '',
  message = '',
  autohide = false,
} = {}) => {
  const context = useContext(ToastContext);

  const showToast = (opts) => {
    const props = {
      type: opts?.type || type,
      title: opts?.title || title,
      message: opts?.message || message,
      autohide: opts?.autohide || autohide,
    };

    context.addToast({
      ...props,
    });
  };

  return { showToast };
};

export default useToast;
