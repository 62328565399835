import React, {
  useState,
  useReducer,
  useEffect,
  createContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  role: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', JSON.stringify(action.payload.token));
      localStorage.setItem('role', JSON.stringify(action.payload.role));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        role: action.payload.role,
      };
    case 'UPDATE':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    case 'UPDATE_ROLE':
      localStorage.setItem('role', JSON.stringify(action.payload.role));
      return {
        ...state,
        role: action.payload.role,
      };
    case 'LOGOUT':
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const login = (payload) => {
    dispatch({
      type: 'LOGIN',
      payload,
    });
  };

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  const update = (payload) => {
    dispatch({
      type: 'UPDATE',
      payload,
    });
  };

  const updateRole = (payload) => {
    dispatch({
      type: 'UPDATE_ROLE',
      payload,
    });
  };

  useEffect(() => {
    let user;
    let token;
    let role;
    try {
      user = JSON.parse(localStorage.getItem('user') || null);
      token = JSON.parse(localStorage.getItem('token') || null);
      role = JSON.parse(localStorage.getItem('role') || null);
    } catch (e) {
      dispatch({
        type: 'LOGOUT',
      });
    }

    if (user && token && role) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
          role,
        },
      });
    }
    setLoading(false);
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      token: state.token,
      role: state.role,
      login,
      logout,
      update,
      updateRole,
    }),
    [state]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
