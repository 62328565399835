export function findCommentNode(comment) {
  const { head } = document;
  for (let i = 0; i < head.childNodes.length; i += 1) {
    const node = head.childNodes[i];
    if (node.nodeType === 8 && node?.nodeValue?.trim() === comment) {
      return node;
    }
  }
  return null;
}

export function isElement(o) {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement // DOM2
    : o &&
        typeof o === 'object' &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === 'string';
}

export function arrayToObject(array) {
  const obj = {};
  array.forEach((el) => {
    obj[el] = el;
  });
  return obj;
}

export function createLinkElement(attributes) {
  const linkElement = document.createElement('link');

  Object.keys(attributes).forEach((attribute) => {
    if (attribute === 'onload') {
      linkElement.onload = attributes.onload;
    } else {
      linkElement[attribute] = attributes[attribute];
    }
  });

  return linkElement;
}

export function fileDownloadFromUrl(url) {
  const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0];
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = filename; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  xhr.open('GET', url);
  xhr.send();
}

export function fileDownloadFromData(data, filename, mime, bom) {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    /* setTimeout(() => {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200) */
  }
}
