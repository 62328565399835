import React from 'react';
import { Tooltip as RBTooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Tooltip({ placement, content, children }) {
  const renderTooltip = (props) => <RBTooltip {...props}>{content}</RBTooltip>;
  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      <div className="d-inline-block">{children}</div>
    </OverlayTrigger>
  );
}

Tooltip.propTypes = {
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  content: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  placement: 'auto',
};

export default Tooltip;
