import React, { useCallback, useContext } from 'react';
import { Row, Col, Card, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { SmartTable } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import { DEFAULT_BOOTH_COLORS } from '../FloorPlanEditor/Editor/consts';
import { AuthContext } from '../../../context/AuthContext';
import Constants from '../../../constants';

function FloorPlans() {
  const { role } = useContext(AuthContext);
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.floorPlan" />,
      accessor: 'MapTitle',
    },
    {
      Header: <FormattedMessage id="app.common.event" />,
      accessor: 'CalendarTitle',
    },
    {
      Header: <FormattedMessage id="app.common.booth" />,
      accessor: 'booth_id',
    },
    {
      Header: <FormattedMessage id="app.common.status" />,
      accessor: 'status',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <>
            <span
              className="legend-indicator"
              style={{
                backgroundColor: DEFAULT_BOOTH_COLORS[value].background,
              }}
            />
            <FormattedMessage id={`app.common.${value}`} />
          </>
        ),
        []
      ),
    },
    {
      Header: '',
      accessor: 'map_id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) =>
          role === Constants.User.Types[15].id ||
          role === Constants.User.Types[17].id ? (
            <Dropdown align="end">
              <ButtonGroup>
                <Button
                  variant="white"
                  size="sm"
                  as={Link}
                  to={`${AppRoutes.protected.FLOOR_PLAN_EDITOR.path.replace(
                    ':id',
                    value
                  )}`}
                >
                  <i className="bi-eye me-1" />
                  <FormattedMessage id="app.common.view" />
                </Button>
                <ButtonGroup>
                  <Dropdown.Toggle
                    variant="white"
                    size="sm"
                    className="btn-icon dropdown-toggle-empty"
                  />
                </ButtonGroup>
              </ButtonGroup>
              <Dropdown.Menu className="m-0 zi-9999" renderOnMount>
                <Dropdown.Header>
                  <FormattedMessage id="app.common.actions" />
                </Dropdown.Header>
                <Dropdown.Item
                  as={Link}
                  to={`${AppRoutes.public.FLOOR_PLAN.path.replace(
                    ':guid',
                    original.guid
                  )}`}
                  target="_blank"
                >
                  <i className="bi-box-arrow-up-right dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.preview" />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              variant="white"
              size="sm"
              as={Link}
              to={`${AppRoutes.public.FLOOR_PLAN.path.replace(
                ':guid',
                original.guid
              )}`}
              target="_blank"
            >
              <i className="bi-box-arrow-up-right me-1" />
              <FormattedMessage id="app.common.preview" />
            </Button>
          ),
        [role]
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.floorPlans" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/Rel_Exhibitors_Maps/list"
              requestParams={{ exhibitor_id: id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default FloorPlans;
