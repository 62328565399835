import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useEffectOnce } from 'react-use';

const propsMap = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },

  numeric: {
    type: 'number',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
};

const FormCodeInput = forwardRef(
  (
    {
      defaultValue,
      allowedCharacters,
      onChange,
      length,
      rowClass,
      colClass,
      inputClass,
      ariaLabel,
      disabled,
      placeholder,
      isPassword,
      autoFocus,
      validated,
    },
    ref
  ) => {
    const inputsRef = useRef([]);
    const inputProps = propsMap[allowedCharacters];

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join('');
      if (onChange) {
        onChange(res);
      }
    };

    const handleOnChange = (e) => {
      const {
        target: {
          value: val,
          parentNode: { nextElementSibling },
        },
      } = e;
      if (val.length > 1) {
        e.target.value = val.charAt(0);
        if (nextElementSibling !== null) {
          nextElementSibling.firstChild.focus();
        }
      } else if (val.match(inputProps.pattern)) {
        if (nextElementSibling !== null) {
          nextElementSibling.firstChild.focus();
        }
      } else {
        e.target.value = '';
      }
      sendResult();
    };

    const handleOnKeyDown = (e) => {
      const { key, target } = e;
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.parentNode.previousElementSibling !== null) {
            const t = target.parentNode.previousElementSibling.firstChild;
            t.value = '';
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = '';
        }
        sendResult();
      }
    };

    const handleOnFocus = (e) => {
      e.target.select();
    };

    const handleOnPaste = (e) => {
      const pastedValue = e.clipboardData.getData('Text');

      let currentInput = 0;
      for (let i = 0; i < pastedValue.length; i += 1) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput].value;
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter;
            if (
              inputsRef.current[currentInput].parentNode.nextElementSibling !==
              null
            ) {
              inputsRef.current[
                currentInput
              ].parentNode.nextElementSibling.firstChild.focus();
              currentInput += 1;
            }
          }
        }
      }
      sendResult();

      e.preventDefault();
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i += 1) {
            inputsRef.current[i].value = '';
          }
          inputsRef.current[0].focus();
        }
        sendResult();
      },
    }));

    useEffectOnce(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
      if (defaultValue) {
        for (let i = 0; i < defaultValue.length; i += 1) {
          const pastedCharacter = defaultValue.charAt(i);
          inputsRef.current[i].value = pastedCharacter;
        }
      }
    });

    const inputs = [];
    for (let i = 0; i < length; i += 1) {
      inputs.push(
        <Col key={i} className={colClass}>
          <Form.Control
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleOnFocus}
            onPaste={handleOnPaste}
            {...inputProps}
            type={isPassword ? 'password' : inputProps.type}
            ref={(r) => {
              inputsRef.current[i] = r;
            }}
            maxLength={1}
            className={inputClass}
            autoComplete={i === 0 ? 'one-time-code' : 'off'}
            aria-label={
              ariaLabel
                ? `${ariaLabel}. Character ${i + 1}.`
                : `Character ${i + 1}.`
            }
            disabled={disabled}
            placeholder={placeholder}
            isValid={validated && inputsRef.current[i].value}
            isInvalid={validated && !inputsRef.current[i].value}
          />
        </Col>
      );
    }

    return <Row className={rowClass}>{inputs}</Row>;
  }
);

FormCodeInput.propTypes = {
  allowedCharacters: PropTypes.oneOf(['alpha', 'alphanumeric', 'numeric']),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  length: PropTypes.number,
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  inputClass: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isPassword: PropTypes.bool,
  autoFocus: PropTypes.bool,
  validated: PropTypes.bool,
};

FormCodeInput.defaultProps = {
  allowedCharacters: 'alphanumeric',
  defaultValue: undefined,
  onChange: () => {},
  length: 4,
  rowClass: '',
  colClass: '',
  inputClass: '',
  ariaLabel: null,
  disabled: false,
  placeholder: '',
  isPassword: false,
  autoFocus: true,
  validated: false,
};

export default FormCodeInput;
