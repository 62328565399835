import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce, lowerCase } from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import PhoneInput from 'react-phone-number-input/input';
import {
  PageHeader,
  ShowMoreText,
  HighlightedText,
  SmartForm,
  SmartTable,
  getHighlightedText,
} from '../../../components';
import { yup } from '../../../lib';
import Constants from '../../../constants';
import {
  useAxiosQuery,
  useConfirmModal,
  useFormModal,
  useToast,
} from '../../../hooks';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import PlaceHolderImg from '../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const countryOptionLabel = ({ name, iso }) => (
  <div className="d-flex align-items-center">
    <ReactCountryFlag countryCode={iso['alpha-2']} svg className="me-1" />
    {name}
  </div>
);

function Exhibitors() {
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { showToast } = useToast();

  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/categories/list',
      params: { quantity: 999999 },
    });

  const fields = [
    {
      cols: [
        {
          key: 'logo',
          apiKey: 'FirmLogo',
          type: 'image',
          schema: yup.mixed(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'title',
          apiKey: 'FirmTitle',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'about',
          apiKey: 'About',
          type: 'textarea',
          options: { controller: { props: { rows: 5 } } },
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'country',
          apiKey: 'Iso',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: Object.values(Constants.Countries),
                getOptionValue: (option) => `${option.iso['alpha-2']}`,
                getOptionLabel: (option) => `${option.name}`,
                formatOptionLabel: countryOptionLabel,
                isClearable: true,
              },
            },
          },
          schema: yup.string().required(),
        },
        {
          key: 'categories',
          apiKey: 'Categories',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: categoriesData,
                getOptionValue: (option) => `${option.id}`,
                getOptionLabel: (option) => option.Title,
                isLoading: categoriesDataIsLoading,
                isClearable: true,
                isMulti: true,
                isSearchable: true,
              },
            },
          },
          schema: yup.array().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'address',
          apiKey: 'FirmAddress',
          schema: yup.string(),
        },
        {
          key: 'web',
          apiKey: 'Web',
          schema: yup.string().customUrl(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'phone',
          apiKey: 'Phone',
          type: 'phone',
          schema: yup.string(),
        },
        {
          key: 'fax',
          apiKey: 'Fax',
          type: 'phone',
          schema: yup.string(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Facebook',
          apiKey: 'Facebook',
          schema: yup.string(),
        },
        {
          key: 'Instagram',
          apiKey: 'Instagram',
          schema: yup.string(),
        },
        {
          key: 'Linkedin',
          apiKey: 'Linkedin',
          schema: yup.string(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Twitter',
          apiKey: 'Twitter',
          schema: yup.string(),
        },
        {
          key: 'Youtube',
          apiKey: 'Youtube',
          schema: yup.string(),
        },
        { key: 'dummy', type: 'hidden' },
      ],
    },
  ];

  const onAddNew = async () => {
    const isSuccess = await form({
      title: 'newExhibitor',
      confirmLabel: 'create',
      requestUrl: '/exhibitors/insert',
      size: 'xl',
      fields,
    });

    if (isSuccess) {
      tableRef.current.reload();

      showToast({
        type: 'success',
        autohide: true,
        title: <FormattedMessage id="app.common.success" />,
        message: (
          <FormattedMessage
            id="app.common.theXWasCreatedSuccessfully"
            values={{
              x: lowerCase(formatMessage({ id: 'app.common.exhibitor' })),
            }}
          />
        ),
      });
    }
  };

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureToDeleteTheXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.exhibitor' })),
            }}
          />
        ),
        requestUrl: '/exhibitors/delete',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.exhibitor' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.logo" />,
      accessor: 'FirmLogo',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Image
            src={value || PlaceHolderImg}
            onError={onImageError}
            style={{ height: 50, width: 75, objectFit: 'contain' }}
          />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.title" />,
      accessor: 'FirmTitle',
      wrap: true,
      minWidth: '30%',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.country" />,
      accessor: 'Iso',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="d-flex align-items-center">
            {value && (
              <>
                <ReactCountryFlag countryCode={value} svg className="me-1" />
                {Constants.Countries[value]?.name}
              </>
            )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.phone" />,
      accessor: 'Phone',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <PhoneInput
            value={value}
            readOnly
            onChange={() => {}}
            className="bg-transparent border-0 text-body"
          />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.web" />,
      accessor: 'Web',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.about" />,
      accessor: 'About',
      wrap: true,
      minWidth: '30%',
      Cell: useCallback(
        ({ cell: { value } }) =>
          value ? (
            <ShowMoreText
              text={getHighlightedText(value, filters?.search)}
              maxLine={2}
              basedOn="words"
            />
          ) : (
            <Badge bg="secondary">
              <FormattedMessage id="app.common.n/a" />
            </Badge>
          ),
        [filters?.search]
      ),
    },
    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.EXHIBITORS_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  onDeleteRow(value, original.FirmTitle);
                }}
              >
                <i className="bi-trash dropdown-item-icon text-danger" />
                <span className="text-danger">
                  <FormattedMessage id="app.common.delete" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onDeleteRow]
      ),
    },
  ];

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'country',
            apiKey: 'Iso',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.Countries),
                  getOptionValue: (option) => `${option.iso['alpha-2']}`,
                  getOptionLabel: (option) => `${option.name}`,
                  formatOptionLabel: countryOptionLabel,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
          {
            key: 'categories',
            apiKey: 'Categories',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: categoriesData,
                  getOptionValue: (option) => `${option.id}`,
                  getOptionLabel: (option) => option.Title,
                  isLoading: categoriesDataIsLoading,
                  isClearable: true,
                  isMulti: true,
                  isSearchable: true,
                },
              },
            },
            schema: yup.array().nullable(),
          },
        ],
      },
    ],
    [categoriesData, categoriesDataIsLoading]
  );

  const parseSearchParams = useCallback(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      let isArray = false;
      filterFields.forEach((row) => {
        row.cols.forEach((col) => {
          if (
            col.key === key &&
            (col.options?.controller?.props?.isMulti || col.key === 'dateRange')
          ) {
            isArray = true;
          }
        });
      });

      if (!params[key]) {
        params[key] = !isArray ? value : [value];
      } else {
        params[key] = [
          ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
          value,
        ];
      }
    });
    return params;
  }, [searchParams, filterFields]);

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          nParams[col.apiKey || col.key] = params[col.key];
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="exhibitors"
        breadcrumbData={{
          current: 'exhibitors',
        }}
      >
        <Button onClick={onAddNew}>
          <i className="bi-plus me-1" />
          <FormattedMessage id="app.common.newExhibitor" />
        </Button>
      </PageHeader>
      <Row>
        <Col>
          <Card>
            <Card.Header className="card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <div className="input-group input-group-merge input-group-borderless">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search" />
                  </div>
                  <Form.Control
                    type="search"
                    placeholder={formatMessage({
                      id: 'app.common.searchExhibitors',
                    })}
                    defaultValue={parseSearchParams().search || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        onSearch(e.target.value);
                      } else {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                  <div className="d-flex align-items-center">
                    <span className="fs-5 me-3">
                      <span id="datatableCounter">0</span> Selected
                    </span>

                    <a className="btn btn-outline-danger btn-sm" href="#!">
                      <i className="bi-trash" /> Delete
                    </a>
                  </div>
                </div>

                <Button
                  variant="white"
                  size="sm"
                  className="dropdown-toggle"
                  onClick={() => {
                    setFiltersIsVisible(!filtersIsVisible);
                  }}
                >
                  <i className="bi-filter me-1" />
                  <FormattedMessage id="app.common.filters" />
                  {Object.keys(filters).filter((key) => key !== 'search')
                    .length > 0 && (
                    <Badge
                      bg="soft-dark"
                      className="text-dark rounded-circle ms-1"
                    >
                      {
                        Object.keys(filters).filter((key) => key !== 'search')
                          .length
                      }
                    </Badge>
                  )}
                </Button>
              </div>
            </Card.Header>

            <Collapse in={filtersIsVisible}>
              <div id="filter-collapse">
                <Card.Body>
                  <SmartForm
                    ref={filterFormRef}
                    fields={filterFields}
                    submitButtonText="applyFilters"
                    clearButtonText="clearFilters"
                    isFilterForm
                    disableApiKey
                    onSubmit={(formData) => {
                      const params = {};
                      if (formData) {
                        Object.keys(formData).forEach((key) => {
                          if (formData[key]) {
                            params[key] = formData[key];
                          }
                        });
                      }

                      if (parseSearchParams().search) {
                        params.search = parseSearchParams().search;
                      }

                      setSearchParams(params);
                    }}
                    defaultValues={parseSearchParams()}
                  />
                </Card.Body>
              </div>
            </Collapse>

            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl="/exhibitors/list"
              filters={filters}
              hoverable
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Exhibitors;
