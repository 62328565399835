import React, { useState, useEffect } from 'react';
import { Table, Badge, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { RequestLoading, RequestResult } from '../../../../components';
import Constants from '../../../../constants';
import { useAxiosQuery } from '../../../../hooks';

const columns = [
  {
    titleKey: 'browser',
    id: 'browser',
  },
  {
    titleKey: 'ip',
    id: 'ip',
  },
  {
    titleKey: 'date',
    id: 'date',
  },
];

function SettingsRecentDevices() {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/users/listLogs',
  });

  const [ip, setIp] = useState();

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIp(data.ip);
  };

  const columnParser = (id, row) => {
    const value = row[id];

    let result;
    switch (id) {
      case 'date':
        result = moment(value).format(
          Constants.DateFormats.APP.Moment.CommonWithTime
        );
        break;

      case 'browser':
        result = row.os ? (
          <FormattedMessage
            id="app.common.xOnY"
            values={{ x: value, y: row.os }}
          />
        ) : (
          value
        );
        break;

      case 'ip':
        result =
          ip === value ? (
            <>
              {value}
              <Badge bg="success" className="ms-2">
                <FormattedMessage id="app.common.current" />
              </Badge>
            </>
          ) : (
            value
          );
        break;

      default:
        result = value;
        break;
    }

    return result;
  };

  useEffect(() => {
    getIp();
  }, []);

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      {!apiLoading && apiError && (
        <Card.Body>
          <RequestResult type="error" message={apiError} />
        </Card.Body>
      )}

      {!apiLoading && !apiError && apiData?.length === 0 && (
        <Card.Body>
          <RequestResult type="secondary" message="app.common.noData" />
        </Card.Body>
      )}

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Table
          responsive
          className="table-thead-bordered table-nowrap table-align-middle card-table"
        >
          <thead className="thead-light">
            <tr>
              {columns.map(({ id, titleKey }) => (
                <th key={id}>
                  <FormattedMessage id={`app.common.${titleKey}`} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {apiData.map((row, rowIndex) => (
              <tr key={`row_${rowIndex.toString()}`}>
                {columns.map(({ id }, index) => (
                  <td
                    key={`row_${rowIndex.toString()}_col_${id}_${index.toString()}`}
                  >
                    <div className="d-flex align-items-center">
                      {columnParser(id, row)}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default SettingsRecentDevices;
