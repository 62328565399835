import React from 'react';
import PropTypes from 'prop-types';
import { StoreRoot } from '@scena/react-store';
import EditorManager from './EditorManager';

export default function Editor({
  width,
  height,
  background,
  data,
  onSave,
  onUnlock,
  isPreview,
  isRestricted,
}) {
  return (
    <StoreRoot>
      <EditorManager
        width={width}
        height={height}
        background={background}
        data={data}
        onSave={onSave}
        onUnlock={onUnlock}
        isPreview={isPreview}
        isRestricted={isRestricted}
      />
    </StoreRoot>
  );
}

Editor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func,
  onUnlock: PropTypes.func,
  isPreview: PropTypes.bool,
  isRestricted: PropTypes.bool,
};

Editor.defaultProps = {
  width: 800,
  height: 600,
  background: null,
  data: null,
  onSave: () => {},
  onUnlock: () => {},
  isPreview: false,
  isRestricted: false,
};
