import React from 'react';
import { Row, Col } from 'react-bootstrap';

function BillingOverview() {
  return (
    <Row>
      <Col>asd</Col>
    </Row>
  );
}

export default BillingOverview;
