import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  // Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  Tooltip,
  TimeScale
);

const Charts = { Doughnut, Line };

export { ChartJS, Charts };
